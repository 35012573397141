import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';

import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';

import TextField from '@mui/material/TextField';

import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DeleteIcon from '@mui/icons-material/Delete';


import { Handle } from 'react-flow-renderer'

import {
  updateChecklistStep,
  getChecklistStepInput,
  createChecklistStepInput,
  deleteChecklistStepInput,
  uploadStepFile,
  deleteStepFile
} from "../../util/api/checklist";

import ChecklistStepInput from "../../components/ChecklistEditor/ChecklistStepInput";
import { Button, Link, List, ListItem, MenuItem, Tooltip, LinearProgress } from '@mui/material';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


export default function ChecklistEditorStep(data, isConnectable) {

  const [description, setDescription] = useState(data.data.description);
  const [action, setAction] = useState(data.data.action);
  const [externalAction, setExternalAction] = useState(data.data.external_action);
  const [title, setTitle] = useState(data.data.title);
  const [inputs, setInputs] = useState([])
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [progress, setProgress] = useState(100);


  const fetchStep = () => {
    getChecklistStepInput(data.data.checklistID, data.id)
      .then((result) => {
        setInputs(result.data.inputs);
        setFiles(result.data.files)
      })
  }



  useEffect(() => {
    if (loading) {
      fetchStep();
      setLoading(false);
    }
  }, [data, loading])

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  }

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  }

  const updateTitle = () => {
    const payload = {
      title: title
    }

    updateChecklistStep(data.data.checklistID, data.id, payload);
  }

  const updateDescription = () => {
    const payload = {
      description: description
    }
    updateChecklistStep(data.data.checklistID, data.id, payload)
  }

  const updateAction = (event) => {
    const payload = {
      action: event.target.value
    }

    updateChecklistStep(data.data.checklistID, data.id, payload).then(() => {
      setAction(event.target.value)
    })
  }

  const updateExternalAction = (event) => {
    const payload = {
      external_action: event.target.value
    }

    updateChecklistStep(data.data.checklistID, data.id, payload).then(() => {
      setExternalAction(event.target.value)
    })
  }


  const addInput = () => {
    createChecklistStepInput(data.data.checklistID, data.id)
      .then((result) => {
        setInputs([...inputs, result.data])
      })
    setExpanded(true);
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleInputDelete = (id) => {
    deleteChecklistStepInput(data?.data?.checklistID, data.id, id);
    let newInputs = inputs.filter((input) => {
      return input.id !== id;
    });
    setInputs(newInputs);
  }

  const handleFileUpload = (event) => {
    uploadStepFile(data.data.checklistID, data.id, event.target.files, (progressEvent) => {
      setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
    }).then(() => {
      setLoading(true)
    })
  }

  const handleFileDelete = (stepID) => {
    deleteStepFile(data.data.checklistID, data.id, stepID).then(() => {
      setLoading(true)
    })
  }

  const cardContent = () => {
    return (
      <Card sx={{ width: 345, borderRadius: 0.5, boxShadow: "none" }}>
        <CardContent>
          <TextField
            id="title_input"
            label="Title"
            multiline
            value={title}
            onChange={handleTitleChange}
            onLostPointerCapture={updateTitle}
            onBlur={updateTitle}
            sx={{ width: "100%" }}
          />
          <TextField
            id="description_input"
            label="Description"
            multiline
            value={description}
            onChange={handleDescriptionChange}
            onBlur={updateDescription}
            sx={{ width: "100%", marginTop: 3 }}
          />
          <TextField
            id="action_input"
            label="Internal Action"
            value={action}
            sx={{ width: "100%", marginTop: 3 }}
            onChange={updateAction}
            select
          >
            <MenuItem value={"R"}>Review</MenuItem>
            <MenuItem value={"H"}>Hold</MenuItem>
            <MenuItem value={"W"}>Witness</MenuItem>
          </TextField>
          <TextField
            id="external_action_input"
            label="External Action"
            value={externalAction}
            sx={{ width: "100%", marginTop: 3 }}
            onChange={updateExternalAction}
            select
          >
            <MenuItem value={"R"}>Review</MenuItem>
            <MenuItem value={"H"}>Hold</MenuItem>
            <MenuItem value={"W"}>Witness</MenuItem>
          </TextField>
        </CardContent>
        <CardActions>
          <Tooltip title="Add file to the current step" >
            <Button color='secondary' component="label">
              <NoteAddIcon style={{ marginRight: 10 }} />
              Attach File
              <input
                type="file"
                hidden
                multiple
                accept=".pdf"
                onChange={handleFileUpload}
              />
            </Button>
          </Tooltip>

        </CardActions>
        {progress !== 100 ? <LinearProgress variant="determinate" value={progress} /> : ""}
        <CardActions>
          {files.length !== 0 && <List>
            {files.map((file) => {
              {
                return (
                  <ListItem secondaryAction={
                    <IconButton onClick={() => handleFileDelete(file.id)} edge="end" aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  }>
                    <Link href={"/data/" + file.file_location}>{file.title}</Link>
                  </ListItem>
                )
              }
            })
            }
          </List>}
        </CardActions>
        <CardActions disableSpacing>
          <IconButton color="secondary" aria-label="favorite" onClick={addInput}>
            <AddIcon />
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon color="secondary" />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {inputs.map((input) => {
              return (
                <div key={input.id}>
                  <Divider />
                  <ChecklistStepInput
                    checklistID={data.data.checklistID}
                    stepID={data.id}
                    inputID={input.id}
                    inputType={input.input_type}
                    inputTitle={input.title}
                    inputRequired={input.required}
                    onDelete={handleInputDelete}
                    deleteDisabled={inputs.length <= 1}
                  />
                </div>
              )
            })}
          </CardContent>
        </Collapse>
      </Card>
    )
  }
  return (
    <>
      <Handle
        type={data.data.mirror ? "source" : "target"}
        position="left"
        style={{
          background: '#83D100',
          zIndex: 1000,
          width: 15,
          height: 15
        }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      {cardContent()}
      <Handle
        type={data.data.mirror ? "target" : "source"}
        position="right"
        id="a"
        style={{
          background: '#83D100',
          width: 15,
          height: 15
        }}
        isConnectable={isConnectable}

      />
    </>
  )
}
