import { SET_TOKEN } from '../types/actions';
import { logInMFA as loginAPI, externalLogIn as externalLoginAPI } from '../util/api';
import store from '../Store';
import { setError } from './notificationActions';

export const setToken = (token: any) => ({
  type: SET_TOKEN,
  payload: token
})

export const logIn = (username: string, password: string, mfa_session_id: string, mfa_code: string) => (dispatch: any) => {
  loginAPI(username, password, mfa_session_id, mfa_code).then((res) => {
    const token = res.data;
    store().persistor.purge();
    dispatch(setToken(token))
    window.location.reload();
  })
    .catch((e) => {
      dispatch(setError(e.response?.data?.detail))
      console.log(e);
      console.error("Error in login");
    });
}

export const externalLogIn = (session_id: string, passcode: string) => (dispatch: any) => {
  externalLoginAPI(session_id, passcode).then((res) => {
    const token = res.data;
    store().persistor.purge();
    dispatch(setToken(token))
    window.location.reload();

  })
    .catch((e) => {
      dispatch(setError(e.response?.data?.detail))
    });
}

export const logOut = () => (dispatch: any) => {
  store().persistor.purge();
  window.location.href = "/"
  dispatch(setToken(null));
}
