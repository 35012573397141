import { useEffect, useState } from 'react';

import Avatar from '@mui/material/Avatar';
import CloseIcon from '@mui/icons-material/Close';
import PrintDisabledIcon from '@mui/icons-material/PrintDisabled';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import HttpsIcon from '@mui/icons-material/Https';

import {
  Grid,
  IconButton,
  Box,
  DialogContent,
  Dialog,
  DialogTitle,
  Typography,
  TextField,
  FormControl,
  Button,
  List,
  ListItem,
  Checkbox,
  Link,
  ListItemText,
  CircularProgress
} from '@mui/material';

import { createChecklistApprovalSession } from '../../util/api/checklistApproval';
import PDFViewer from './PDFViewer';
import { getSessionFiles } from '../../util/api';
import { setError } from '../../actions/notificationActions';
import { useDispatch } from 'react-redux';
import { startHealthCheckSession } from '../../util/api/session';

const ChecklistApprovalPopup = ({ onClose, open, projectID, isHealthCheck }) => {

  const [email, setEmail] = useState("")
  const [showPDF, setShowPDF] = useState(true);
  const [activeStep, setActiveStep] = useState(-1)
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([])

  const dispatch = useDispatch();

  const handleClose = () => {
    onClose(undefined);
    setActiveStep(-1)
    setSelectedFiles([])
    setFiles([])
    setEmail("")
    setLoading(true);
    setShowPDF(true);
  };

  useEffect(() => {
    if (activeStep === 0) {
      setLoading(true);
      getSessionFiles(projectID).then((res) => {
        const filtered = res.data.filter((a) => {
          return a.approval_session_id === null && a.approved_at === null
        })
        setFiles(filtered)
        setLoading(false)
      })
    }
  }, [activeStep])

  const handleSubmit = () => {
    setLoading(true)
    createChecklistApprovalSession(projectID, email, selectedFiles).then(() => {
      setLoading(false);
      handleClose();
    }).catch((e) => {
      setLoading(false)
      dispatch(setError(e.response.data.detail))
    })
  }

  const handleStartHealthCheck = () => {
    setLoading(true)
    startHealthCheckSession(projectID).then(() => {
      setLoading(false);
      handleClose();
    }).catch((e) => {
      setLoading(false)
      dispatch(setError(e.response.data.detail))
    })
  }

  const handleNext = () => {
    if (activeStep + 1 <= 1) {
      setActiveStep(activeStep + 1)
    }

    if (activeStep === 1 && completed()) {
      handleSubmit();
    }
  }

  const handleBack = () => {
    if (activeStep - 1 >= 0) {
      setActiveStep(activeStep - 1)
    }
  }

  const handleContinue = () => {

    if (!isHealthCheck) {
      setShowPDF(false);
      setActiveStep(0);
    }

    if (isHealthCheck) {
      handleStartHealthCheck();
    }
  }

  const handleToggle = (value) => {
    const currentIndex = selectedFiles.indexOf(value);
    const newChecked = [...selectedFiles];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedFiles(newChecked);
  };

  const completed = () => {
    if (activeStep == 0)
      return selectedFiles.length !== 0;

    const emailRegex = /^[a-z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-z0-9]@[a-z0-9][-\.]{0,1}([a-z][-\.]{0,1})*[a-z0-9]\.[a-z0-9]{1,}([\.\-]{0,1}[a-z]){0,}[a-z0-9]{0,}$/
    return email.toLowerCase().match(emailRegex) !== null

  }

  const LoadingIcon = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  const FileEntry = () => {
    return (
      <Box>
        <DialogTitle textAlign="center">Please select the files for approval </DialogTitle>
        <List>
          {loading && <LoadingIcon />}
          {!loading && files.map((file) => {
            return (
              <ListItem key={file.id}>
                <Checkbox
                  checked={selectedFiles.indexOf(file.id) !== -1}
                  onClick={() => {
                    handleToggle(file.id)
                  }} />
                <Link
                  href={"/data/" + file.file_location}
                  underline="hover"
                  target="_blank"
                >
                  <ListItemText
                    primary={file.title}
                    secondary={file.description}
                  />
                </Link >
                <ListItemText
                  primary={""}
                />
                {file.approved ? "Approved" : ""}
              </ListItem>
            )
          })}
        </List>
      </Box>
    )
  }

  const CloseButton = () => {
    return (
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8
        }}
      >
        <CloseIcon />
      </IconButton>)
  }

  const EmailEntry = () => {
    return (
      <Box>
        <DialogTitle textAlign="center">Who would you like to notify? </DialogTitle>



        <FormControl sx={{ m: 1, minWidth: 400 }} autocomplete="on">
          <TextField disabled={loading} autoFocus label="Email Address" variant="outlined" size="small" value={email} name="email" onChange={(e) => setEmail(e.target.value)} />
        </FormControl>

        {loading && <Box sx={{ mt: 2 }} >
          <LoadingIcon />
        </Box>}
      </Box >
    )
  }

  const InfoContainer = ({ icon, text }) => {
    return (
      <Grid container
        sx={{
          mt: "30px"
        }}>
        <Grid item xs={3} sx={{ display: "flex", alignitems: "center", justifyContent: "center" }}>
          <Avatar sx={{ background: "none", padding: "30px" }} >
            {icon}
          </Avatar>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1">
            {text}
          </Typography>
        </Grid>
      </Grid >
    )
  }

  const steps = [
    <FileEntry />,
    <EmailEntry />
  ]

  return (
    <Dialog
      onClose={handleClose}
      sx={{ zIndex: 1 }}
      fullWidth
      maxWidth={"md"}
      open={open}
      PaperProps={{
        elevation: 0,
        variant: "outlined",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
        p: 0
      }}>
      <CloseButton />
      {showPDF && <DialogContent sx={{
        p: 0,
        m: 0,
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center"
      }}>
        <Grid container spacing={0}>
          <Typography variant="h5" sx={{ mt: 3, mr: 3, ml: 3, mb: 0, }}>Testplan Approval</Typography>
          <Button float="right" onClick={() => { handleContinue(); }} sx={{ mt: 3, mr: 3, ml: 3, mb: 0, }} variant="outlined">Continue</Button>
          <PDFViewer
            pdfBoxSx={{ mt: 0, mb: 0, p: 0 }}
            title={"Testplan Approval"}
            hideControls
            filename={`/api/session/${projectID}/pdfitp`}
          />
        </Grid>
      </DialogContent>}

      {
        !showPDF && < DialogContent >
          <Grid container spacing={5}>
            <Grid item xs={6} sx={{ mt: "50px", pb: "50px" }}>
              <InfoContainer
                icon={<PrintDisabledIcon fontSize="large" />}
                text={"Approvers can approve a document in minutes, without needing to print or scan documents."}
              />
              <InfoContainer
                icon={<AttachEmailIcon fontSize="large" />}
                text={"An email will be sent containing a link that directly leads to the files to approve, without the need to log in or create an account."}
              />
              <InfoContainer
                icon={<HttpsIcon fontSize="large" />}
                text={"Documents receive a digital signature once approved, ensuring they cannot be tampered with."}
              />
            </Grid>

            <Grid item xs={6} sx={{ mt: "50px", pb: "50px", minHeight: "400px", display: "flex", flexDirection: "column" }}>
              {steps[activeStep]}
              <Box sx={{ flexGrow: 1 }}></Box>
              <Box sx={{}}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Button onClick={handleNext} sx={{ float: "right" }} disabled={!completed() || loading}>
                  {activeStep === 1 ? 'Finish' : 'Next'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      }
    </Dialog >
  )
}

export default ChecklistApprovalPopup;