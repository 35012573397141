import { useEffect, useState } from 'react';

import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';
import PrintDisabledIcon from '@mui/icons-material/PrintDisabled';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import HttpsIcon from '@mui/icons-material/Https';

import {
  Grid,
  IconButton,
  Box,
  DialogContent,
  Dialog,
  DialogTitle,
  Typography,
  TextField,
  FormControl,
  Button,
} from '@mui/material';

import { createProjectApprovalSession } from '../../util/api/projectApproval';
import Loading from '../Loading';

const ProjectApprovalPopup = ({ onClose, open, projectID }) => {

  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    onClose(undefined);
    setEmail("")
  };

  const handleSubmit = () => {
    setLoading(true)
    createProjectApprovalSession(projectID, email).then(() => {
      handleClose();
      setLoading(false)
    })
  }

  const completed = () => {
    const emailRegex = /^[a-z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-z0-9]@[a-z0-9][-\.]{0,1}([a-z][-\.]{0,1})*[a-z0-9]\.[a-z0-9]{1,}([\.\-]{0,1}[a-z]){0,}[a-z0-9]{0,}$/
    return email.toLowerCase().match(emailRegex) !== null
  }

  const CloseButton = () => {
    return (
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8
        }}
      >
        <CloseIcon />
      </IconButton>)
  }

  const EmailEntry = () => {
    return (
      <Box>
        <DialogTitle textAlign="center">Who would you like to notify? </DialogTitle>
        <FormControl sx={{ m: 1, minWidth: 400 }}>
          <TextField autoComplete="email" autoFocus type="email" label="Email Address" variant="outlined" size="small" value={email} onChange={(e) => setEmail(e.target.value)} />
        </FormControl>
      </Box>
    )
  }

  const InfoContainer = ({ icon, text }) => {
    return (
      <Grid container
        sx={{
          mt: "30px"
        }}>
        <Grid item xs={3} sx={{ display: "flex", alignitems: "center", justifyContent: "center" }}>
          <Avatar sx={{ background: "none", padding: "30px" }} >
            {icon}
          </Avatar>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1">
            {text}
          </Typography>
        </Grid>
      </Grid >
    )
  }

  return (
    <Dialog
      onClose={handleClose}
      sx={{ zIndex: 199999999 }}
      fullWidth
      maxWidth={"md"}
      open={open}
      PaperProps={{
        elevation: 0,
        variant: "outlined",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
      }}>
      <CloseButton />

      <DialogContent>
        <Grid container spacing={5}>
          <Grid item xs={6} sx={{ mt: "50px", pb: "50px" }}>
            <InfoContainer
              icon={<PrintDisabledIcon fontSize="large" />}
              text={"Approvers can approve a project in minutes, without needing to print or scan documents."}
            />
            <InfoContainer
              icon={<AttachEmailIcon fontSize="large" />}
              text={"An email will be sent containing a link that directly leads to the project to approve, without the need to log in or create an account."}
            />
            <InfoContainer
              icon={<HttpsIcon fontSize="large" />}
              text={"Projects receive a digital signature once approved, ensuring they cannot be tampered with."}
            />
          </Grid>

          <Grid item xs={6} sx={{ mt: "50px", pb: "50px", minHeight: "400px", display: "flex", flexDirection: "column" }}>
            <EmailEntry />
            <Box sx={{ flexGrow: 1 }}>{loading && <Loading sx={{ height: "100%" }} />}</Box>
            <Box sx={{}}>
              <Button onClick={handleSubmit} sx={{ float: "right" }} disabled={!completed() || loading}>
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ProjectApprovalPopup;