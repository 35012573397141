import { useState, useEffect } from "react";
import { Grid, Box, Typography, Select, MenuItem, FormControl, InputLabel } from "@mui/material";

import IssueTile from "../../components/Issues/IssueTile";
import IssueResolve from "../../components/Issues/IssueResolve";
import Loading from "../../components/Loading";
import SearchBar from "../../components/SearchBar";

import { getAllUnresolvedIssues } from "../../util/api/issue";

const IssueOverview = () => {
  const [loading, setLoading] = useState(true)
  const [issues, setIssues] = useState([])
  const [filteredIssues, setFilteredIssues] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [resolveWindowOpen, setResolveWindowOpen] = useState(false)
  const [resolveIssue, setResolveIssue] = useState(undefined);
  const [filter, setFilter] = useState("")

  useEffect(() => {
    handleSearch(searchValue)
  }, [searchValue])

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  }

  const handleSearch = (rawVal) => {
    const value = rawVal.toLowerCase()
    const newIssues = issues.filter((issue) => {
      return ((value === ""
        || issue?.file?.title?.toLowerCase().includes(value)
        || issue?.file?.category?.title?.toLowerCase().includes(value)
        || issue?.file_approval_session?.approver_email?.toLowerCase().includes(value)
        || issue?.checklist_approval_session?.approver_email?.toLowerCase().includes(value)
        || issue?.session?.title?.toLowerCase().includes(value))
      )
    })

    setFilteredIssues(newIssues);
  }

  const cancelSearch = () => {
    setSearchValue('')
  }

  const fetchIssues = () => {
    getAllUnresolvedIssues().then((response) => {
      setIssues(response.data)
      setFilteredIssues(response.data)
      setLoading(false)
    })
  }

  const handleResolve = (issue) => {
    setResolveIssue(issue)
    setResolveWindowOpen(true)
  }

  const handleUpdate = () => {
    fetchIssues();
  }

  useEffect(() => {
    if (loading)
      fetchIssues();
  }, [loading])

  return (
    loading ? <Loading /> :
      <Box sx={{ p: 1 }}>
        <IssueResolve
          open={resolveWindowOpen}
          onClose={(update) => { setResolveWindowOpen(false); update && handleUpdate() }}
          issue={resolveIssue}
        />
        <Box sx={{
          width: "100%",
          pt: 0,
          pb: 1,
          pl: 0,
          pr: 0,
          display: "flex"
        }}>
          <SearchBar
            value={searchValue}
            handleSearchChange={handleSearchChange}
            cancelSearch={cancelSearch}
            placeholder="Find an issue..."
          />
        </Box>
        <Grid container justifyContent="center" sx={{
          ml: 0,
          mt: 0,
          width: "100%",
          border: "1px solid",
          background: 'None',
          borderColor: '#212529',
          borderRadius: 1,
          overflow: "scroll",
          maxHeight: "calc(100vh -260px)"
        }}>
          {filteredIssues?.map((issue) => {
            return (<IssueTile
              issue={issue}
              key={issue?.id}
              updateSearchFilter={(value) => { setSearchValue(value); handleSearch(value) }}
              onClickResolve={() => handleResolve(issue)}
            />
            )
          })}

          {filteredIssues.length === 0 && <Box sx={{ pt: 10, pb: 10 }}>
            <Typography variant="h4">
              No issues found
            </Typography>
          </Box>}
        </Grid>
      </Box >
  )
}

export default IssueOverview;