import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { Grid, Box, Button, IconButton, SpeedDialAction, SpeedDial, Tooltip } from "@mui/material";
import {
  useParams
} from "react-router-dom";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import CastIcon from '@mui/icons-material/Cast';

import Loading from "../../components/Loading";
import Subheader from "../../components/Subheader";

import {
  getSessionChecklistOverview,
} from "../../util/api";
import { setError } from "../../actions/notificationActions";
import TitleBar from "../../components/TitleBar";
import FileBrowserPopup from '../../components/Session/FileBrowserPopup';
import ChecklistTile from "../../components/Session/ChecklistTile";
import SessionChecklist from "../../components/Session/SessionCheckist";
import ChecklistPopup from "../../components/Session/ChecklistPopup";
import HealthCheckChecklistTile from "../../components/Session/HealthCheckSession/HealthCheckSessionChecklist";


const Session = () => {
  const dispatch = useDispatch();
  const { sessionID } = useParams();
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(undefined);
  const [openFileBrowser, setOpenFileBrowser] = useState(false);
  const [activeChecklist, setActiveChecklist] = useState(undefined);
  const [checklistPopupOpen, setChecklistPopupOpen] = useState(false);
  const [openSpeedDial, setOpenSpeedDial] = useState(false);

  const handleOpen = () => setOpenSpeedDial(true);
  const handleClose = () => setOpenSpeedDial(false);

  const fetchOverview = () => {
    getSessionChecklistOverview(sessionID)
      .then((response) => {
        setProject(response.data);
        setLoading(false);
      })
      .catch((e) => {
        dispatch(setError(e.response?.data?.detail))
      })
  }

  useEffect(() => {
    if (loading) {
      fetchOverview();
    }
  }, [loading])

  const handleOpenfilebrowser = () => {
    setOpenFileBrowser(true);
  }

  const handleChecklistSelect = (checklist) => {
    setActiveChecklist(checklist)
    setChecklistPopupOpen(true)
  }

  const handleChecklistPopupClose = () => {
    setLoading(true);
    setChecklistPopupOpen(false)
  }

  const handleLeaveProject = () => {
    window.location.href = "/projects"
  }

  const LeftSubheaderAction = () => {
    return (
      <div>
        <Button
          onClick={handleLeaveProject}
          startIcon={<ArrowBackIcon />}
          sx={{
            display: { xs: "none", md: "flex" },
            color: "white"
          }}
        >
          Exit Project
        </Button>
        <IconButton
          size="large"
          sx={{
            display: {
              xs: "flex",
              md: "none",
            },
            color: "White"
          }}>
          <ArrowBackIcon />
        </IconButton>
      </div>
    )
  }

  const RightSubheaderAction = () => {
    return (
      <div>
        <Button
          onClick={handleOpenfilebrowser}
          endIcon={<OpenInBrowserIcon />}
          sx={{
            display: { xs: "none", md: "flex" },
            color: "white"
          }}
        >
          Open File Browser
        </Button>
        <IconButton
          onClick={handleOpenfilebrowser}
          size="large"
          sx={{
            display: { xs: "flex", md: "none", },
            color: "White"
          }}>
          <OpenInBrowserIcon />
        </IconButton>
      </div>
    )
  }

  const SpeedDialMenu = () => {

    const actions = [
      {
        icon: <MeetingRoomIcon />, name: 'EQUANS West | Room VRG HMT-1', action: () => {
          window.open("https://go.myroom.hpe.com/?key=MRPY34TV2CLQN", "_blank").focus()
        }
      },
      {
        icon: <MeetingRoomIcon />, name: 'EQUANS West | Room RCC-1', action: () => {
          window.open("https://go.myroom.hpe.com/?key=MRPHR79ENDUAX", "_blank").focus()
        }
      },
    ];

    return (
      <Tooltip title="Video Meeting" placement="left-end">
        <SpeedDial
          ariaLabel="Video Meeting"
          sx={{ position: 'fixed', bottom: 16, right: 16 }}
          icon={<CastIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={openSpeedDial}
          FabProps={{
            sx: {
              bgcolor: 'text.secondary',
              '&:hover': {
                bgcolor: 'text.secondary',
              }
            }
          }}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.action}
              FabProps={{
                sx: {
                  color: 'text.primary',
                }
              }}
            />
          ))}
        </SpeedDial>
      </Tooltip>
    )
  }

  return (
    <Box >
      <TitleBar sx={{
        display: {
          xs: "none",
          md: "flex"
        }
      }} />
      <Subheader leftAction={<LeftSubheaderAction />} rightAction={<RightSubheaderAction />} title={project?.title} />
      <FileBrowserPopup projectID={sessionID} open={openFileBrowser} onClose={() => { setOpenFileBrowser(false) }} />
      <ChecklistPopup open={checklistPopupOpen} checklist={activeChecklist} onClose={handleChecklistPopupClose} />
      <SpeedDialMenu />
      <Grid container sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <Grid xs={12} md={10} lg={10} container justifyContent="center" sx={{
          // maxHeight: "calc(100vh)",
          overflow: "scroll",
          p: 0,
          pt: 0
        }}>
          {loading && <Loading />}
          {loading || project?.checklists?.map((checklist) => {
            if (project?.is_health_check_project === true) {
              return <HealthCheckChecklistTile defaultClosed={checklist?.completed} checklistID={checklist?.id} onClick={() => handleChecklistSelect(checklist)} />
            } else {
              return <ChecklistTile defaultClosed={checklist?.completed} checklistID={checklist?.id} onClick={() => handleChecklistSelect(checklist)} />
            }

          })}
        </Grid>
      </Grid>
    </Box>
  )

}

export default Session