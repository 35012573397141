import {
  Grid,
  Tab,
  Tabs,
  Box,
  Chip
} from "@mui/material"
import { styled } from '@mui/material/styles';



const PageSelectorTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#83D100',
    borderRadius: "10px"
  },
});

const PageSelectorTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightMedium,
  marginRight: theme.spacing(1),
  color: '#ffffff',
  '&:hover': {
    color: '#83D100',
    opacity: 1,
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-selected': {
    color: '#83D100',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));

const PageSelectorLabel = ({ title, chipValue, icon }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {icon && <Box sx={{ marginRight: "10px" }}>{icon}</Box>}
      <Box>{title}</Box>
      {chipValue && <Box sx={{ marginLeft: "10px" }}><Chip label={chipValue} /></Box>}
    </Box>
  )
}


const PageSelector = ({ pages, page, onSelect }) => {

  const handleChange = (event, newValue) => {
    onSelect(newValue);
  }

  return (
    <Grid container sx={{ background: "#283036", width: "100vw", borderBottom: 1, borderColor: '#212529', }}>
      <Grid item xs={0} lg={3}>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box sx={{ justifyContent: "center", display: "flex", color: "red" }}>
          <PageSelectorTabs value={page} onChange={handleChange} aria-label="Page Select">

            {pages.map((p) => {
              return (<PageSelectorTab
                label={<PageSelectorLabel icon={p.icon} title={p.title} />}
                value={p.value}
              />)
            })}

          </PageSelectorTabs>
        </Box>
      </Grid>
    </Grid>
  )
}

export default PageSelector;