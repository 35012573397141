import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Chip,
  Link,
  Typography,
  Button,
  Tooltip
} from "@mui/material"


const ChecklistIssueTile = ({ issue, updateSearchFilter, onClickResolve }) => {
  return (
    <Card sx={{
      marginBottom: 0,
      width: "100%",
      borderRadius: 0,
      boxShadow: "none",
      display: "flex",
      flexDirection: "column",
      background: "none",
      borderBottom: "1px solid",
      borderColor: "#212529",
      ":last-child": {
        border: "none"
      }

    }}>
      <CardHeader
        title={
          <div>
            <Link
              href={`/projectSettings/${issue?.session?.id}/testplans`}
              underline="none"
              target="_blank"
            >
              Checklist
            </Link>
          </div>
        }
        action={
          issue?.resolver?.display_name !== undefined && <Tooltip title={`Resolved on ${new Date(Date.parse(issue?.resolved_at)).toDateString()}`} >
            <Chip sx={{ ml: 2, }} label={`Resolved by ${issue?.resolver?.display_name}`} />
          </Tooltip>
        }
        sx={{ pl: 3, pt: 2 }}
        subheader={`Created on ${new Date(Date.parse(issue?.created_at)).toDateString()}`}
        subheaderTypographyProps={{
          color: "#dddddd",
          variant: "body2",
          fontSize: "14px",
          flexGrow: "1",
          pb: 1
        }}
      />
      < CardContent sx={{ pl: 3, pt: 0, pb: 0, ":last-child": { pb: 1 } }}>

        <Typography variant="paragraph">

          <Link href={`mailto:${issue?.checklist_approval_session?.approver_email}`}>
            {issue?.checklist_approval_session?.approver_email + " "}
          </Link>

          had the following to say:

        </Typography>
        <Typography sx={{ mt: 1, mb: 1 }} variant="subtitle1">
          <i>“{issue?.content}”</i>
        </Typography>

      </CardContent >
      <CardActions sx={{ pl: 2, pt: 0, pb: 2 }} >
        {issue?.resolver_id}
        <Chip
          clickable
          onClick={() => updateSearchFilter(issue?.session?.title)}
          sx={{ mt: 2, cursor: "pointer" }}
          label={issue?.session?.title}
        />
        <Chip
          clickable
          onClick={() => updateSearchFilter(issue?.checklist_approval_session?.approver_email)}
          sx={{ mt: 2, cursor: "pointer" }}
          label={issue?.checklist_approval_session?.approver_email}
        />

        {issue.resolver === null && <Button onClick={onClickResolve} sx={{ ml: "auto", mr: 0, mt: 2, }}>Resolve</Button>}
      </CardActions >
    </Card >
  )
}

export default ChecklistIssueTile