import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Typography,
  Link,
  Divider,
  DialogActions,
  Button,
} from "@mui/material"
import { resolveChecklistIssue } from '../../util/api/issue';
import ProjectChecklistSelect from '../ProjectSettings/ProjectChecklistSelect';

const ChecklistIssueResolve = ({ open, onClose, issue }) => {

  // moves checked checklists to right
  const handleClose = (toUpdate) => {
    onClose(toUpdate);
  }

  const handleResubmit = () => {
    resolveChecklistIssue(issue?.id).then(() => {
      onClose(true)
    })
  }

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={() => { handleClose(false) }}>
      <DialogTitle>{issue?.file?.title}</DialogTitle>
      <Divider sx={{ ml: 2, mr: 2 }} />
      <DialogContent>
        <DialogContentText sx={{ color: "#dddddd" }}>
          <Typography variant="paragraph">

            <Link href={`mailto:${issue?.checklist_approval_session?.approver_email}`}>
              {issue?.checklist_approval_session?.approver_email + " "}
            </Link>

            had the following to say:

          </Typography>
          <Typography sx={{ mt: 1, mb: 0 }} variant="subtitle1">
            <i>“{issue?.content}”</i>
          </Typography>

        </DialogContentText>
      </DialogContent >
      <Divider sx={{ ml: 2, mr: 2 }} />

      <DialogContent >
        <ProjectChecklistSelect projectID={issue?.session?.id} embedded />
      </DialogContent >

      <DialogActions>
        <Button onClick={() => handleClose(false)} >Cancel</Button>
        <Button onClick={handleResubmit}>Resubmit</Button>
      </DialogActions>
    </Dialog >
  )
}

export default ChecklistIssueResolve;
