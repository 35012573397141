import {
  Box,
  List,
  ListItem,
  Link,
  Typography,
  Skeleton,
  Card,
  CardHeader,
  CardContent,
  Chip,
  Button,
  Tooltip,
  CardActions,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { getChecklistStep, setChecklistStepConclusion, updateChecklistStepHealth } from "../../../util/api/checklist";
import { useEffect, useState } from "react";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import PendingActionsIcon from '@mui/icons-material/PendingActions';

import { AntTabs, AntTab } from "../../ChecklistStep/AntTab";
import ChecklistFiles from "../../ChecklistStep/ChecklistFiles";
import ChecklistComments from "../../ChecklistStep/ChecklistComments";
import StepApprovalPopup from "../../Approval/StepApprovalPopup";
import StepRejectionPanel from "../../ChecklistStep/StepRejectionPanel";
import ChecklistInput from "../../ChecklistStep/ChecklistInput/ChecklistInput";
import { useDispatch } from "react-redux";
import { setError } from "../../../actions/notificationActions";
import CloseIcon from '@mui/icons-material/Close';

const ACTION_TABLE = {
  "A": "Approve",
  "M": "Monitor",
  "R": "Review",
  "H": "Hold",
  "W": "Witness",
  None: ""
}

const ConclusionPopup = ({ open, onClose, content, setContent, onSubmit, onCancel }) => {
  const CloseButton = () => {
    return (
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8
        }}
      >
        <CloseIcon />
      </IconButton>)
  }

  return <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={"md"}
    PaperProps={{
      elevation: 0,
      variant: "outlined",
      justifyContent: "center",
      textAlign: "center",
      alignItems: "center",
    }}
  >
    <DialogContent>
      <CloseButton />
      <CardHeader
        title="Add a conclusion to this step"
      />
      <CardContent>
        Please provide extra information about the status of this step. More detail will help writing a better overall summary for the project.

        <FormControl sx={{ width: '100%', mt: 5 }} variant="outlined">
          <TextField
            value={content}
            label="Provide a description of the issue"
            minRows={5}
            onChange={(e) => setContent(e.target.value)}
            multiline
            width="100%"
          />
        </FormControl>
      </CardContent>
      <CardActions>
        <Button

          onClick={onSubmit}
          sx={{
            ml: "auto",
            mr: 0
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={content?.length === 0}
          onClick={onSubmit}
          sx={{
            ml: "auto",
            mr: 0
          }}
        >
          Submit
        </Button>
      </CardActions>
    </DialogContent>
  </Dialog>
}

const HealthCheckChecklistStep = ({ checklistID, stepID, onUpdate, disabled, onSignLocally }) => {

  const [step, setStep] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [rejectionPanelOpen, setRejectionPanelopen] = useState(false)
  const [stepApprovalPopupOpen, setStepApprovalPopupOpen] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  const [conclusionPanelOpen, setConclusionPanelOpen] = useState(false);
  const [conclusionContent, setConclusionContent] = useState("");

  const dispach = useDispatch();


  const fetchStep = () => {
    return getChecklistStep(checklistID, stepID).then((response) => {
      setStep(response.data)
      setConclusionContent(response?.data?.healthcheck_conclusion)
      setLoading(false)
      setStepApprovalPopupOpen(false);
      return response
    })
  }

  useEffect(() => {
    if (loading) {
      fetchStep()
    }
  }, [loading])


  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleHealthStatusChange = (e) => {

    updateChecklistStepHealth(checklistID, stepID, e.target.value).then((response) => {
      if ((response.data.health_status == 1 || response.data.health_status == 2) && response.data.health_status !== step?.health_status) {
        setConclusionPanelOpen(true);
      }
      setStep(response.data)
      onUpdate();
    }).catch((e) => {
      dispach(setError(e.response.data.detail));
    })
  }

  const handleConclusionSubmit = (e) => {
    setChecklistStepConclusion(checklistID, stepID, conclusionContent).then(
      (response) => {
        setStep(response?.data)
        setConclusionContent(response?.data?.healthcheck_conclusion)
        setConclusionPanelOpen(false);
      }
    ).catch((e) => {
      dispach(setError(e.response.data.detail));
    })
  }

  const handleConclusionCancel = (e) => {
    setConclusionPanelOpen(false);
    setConclusionContent(step?.healthcheck_conclusion)
  }

  const ActionLabel = () => {

    if (step?.punchlist_step?.completed) {
      return <Chip
        color="success"
        label={
          <Box sx={{
            padding: 1,
            justifyContent: "center",
            alignItems: "center",
            display: "flex"
          }}>
            <Typography>Revision Done</Typography>
            <PublishedWithChangesIcon sx={{ ml: 1 }} />
          </Box>
        }
      />
    }

    if (step?.punchlist_step) {
      return <Chip
        color="warning"
        label={
          <Box sx={{
            padding: 1,
            justifyContent: "center",
            alignItems: "center",
            display: "flex"
          }}>
            <Typography>Revision Requested</Typography>
            <SyncProblemIcon sx={{ ml: 1 }} />
          </Box>
        }
      />
    }

    if (step?.completed && (step?.external_action === "H" || step?.external_action === "W") && step?.approval_session && (step?.approved_at === null && step.waived_at === null)) {
      return (
        <Tooltip title={`Awaiting approval from ${step?.approval_session?.approver_email}`} >
          <Chip
            color="warning"
            label={
              <Box sx={{
                padding: 1,
                justifyContent: "center",
                alignItems: "center",
                display: "flex"
              }}>
                <Typography>Approval Pending</Typography>
                <PendingActionsIcon sx={{ ml: 1 }} />
              </Box>
            }
          />
        </Tooltip>
      )
    }

    if (
      step?.completed
      && (step?.external_action === "H" || step?.external_action === "W")
      && step?.approval_session === null
      && step?.approved_at === null
    ) {
      return (
        <Tooltip title="Click here to invite an approver">
          <Chip
            color="error"
            clickable
            onClick={() => setStepApprovalPopupOpen(true)}
            label={
              <Box sx={{
                padding: 1,
                justifyContent: "center",
                alignItems: "center",
                display: "flex"
              }}>
                <Typography>Approval Needed</Typography>
                <ErrorOutlineIcon sx={{ ml: 1 }} />
              </Box>
            }
          />
        </Tooltip>
      )
    }


    if (step?.completed && step?.approval_session && step?.approved_at !== null) {
      return <Chip
        color="success"
        label={
          <Box sx={{
            padding: 1,
            justifyContent: "center",
            alignItems: "center",
            display: "flex"
          }}>
            <Typography>Approved</Typography>
            <CheckIcon sx={{ ml: 1 }} />
          </Box>
        }
      />
    }

    if (step?.completed && step?.approval_session && step?.waived_at !== null) {
      return <Chip
        color="success"
        label={
          <Box sx={{
            padding: 1,
            justifyContent: "center",
            alignItems: "center",
            display: "flex"
          }}>
            <Typography>Waived</Typography>
            <CheckIcon sx={{ ml: 1 }} />
          </Box>
        }
      />
    }


    if (step?.completed) {
      return <Chip
        color="success"
        label={
          <Box sx={{
            padding: 1,
            justifyContent: "center",
            alignItems: "center",
            display: "flex"
          }}>
            <Typography>Done</Typography>
            <CheckIcon sx={{ ml: 1 }} />
          </Box>
        }
      />
    }


    return <div></div>
  }

  const HealthStatusLabel = ({ name, color }) => {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 1 }}>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <Box sx={{ borderRadius: "50%", width: "20px", height: "20px", background: color }}></Box> {name}
        </Box>
      </Box>
    )
  }

  const HeaderText = () => {
    if (step?.completed && step?.approval_session && step?.approved_at !== null) {
      return <i>Approved by {step?.approval_session?.approver_email} at {new Date(Date.parse(step?.approved_at)).toDateString()}, {new Date(Date.parse(step?.approved_at)).toLocaleTimeString()} </i>
    }

    if (step?.completed && step?.approval_session && step?.waived_at !== null) {
      return <i>Waived by {step?.approval_session?.approver_email} at {new Date(Date.parse(step?.waived_at)).toDateString()}, {new Date(Date.parse(step?.waived_at)).toLocaleTimeString()} </i>
    }

    if (step?.approval_session) {
      return <i>Awaiting approval from {step?.approval_session?.approver_email}</i>
    }

    return <div></div>
  }

  return (
    <Card sx={{
      mt: 0,
      width: "100%",
      borderRadius: 0,
      boxShadow: "none",
      display: "flex",
      flexDirection: "column",
      borderBottom: "1px solid",
      borderColor: "#212529",
      borderRadius: 1,
      p: 1,
      overflow: "scroll"
    }}>
      <ConclusionPopup open={conclusionPanelOpen} onClose={() => { setConclusionPanelOpen(false); setLoading(true) }} checklistID={checklistID} stepID={stepID}
        content={conclusionContent} setContent={setConclusionContent} onSubmit={handleConclusionSubmit} onCancel={handleConclusionCancel} />
      <StepRejectionPanel open={rejectionPanelOpen} onClose={() => { setRejectionPanelopen(false); setLoading(true) }} checklistID={checklistID} stepID={stepID} />
      <StepApprovalPopup onSignLocally={onSignLocally} open={stepApprovalPopupOpen} onClose={() => { fetchStep(); }} stepID={stepID} />
      {loading ? <Skeleton variant="rounded" width="100%" height={120} /> : <div>
        <CardHeader
          title={step?.title}
          subheader={
            <Box sx={{
              display: "flex",
              flexDirection: "column",

            }}>
              <Box display={"flex"} sx={{ color: "rgba(255, 255, 255, 0.6)" }}>

              </Box>
              < HeaderText />
            </Box>
          }

          action={<ActionLabel />}
        />

        <CardContent sx={{
          mt: 0,
          pt: 0,
          pb: 0
        }}>
          <Divider sx={{ mb: 1, mt: 1 }} />
          <Typography variant="body1" sx={{ whiteSpace: "normal" }}>
            {step?.description}
          </Typography>

        </CardContent>


        <CardContent sx={{
          pb: { xs: 0, md: 0, },
          pt: { xs: 0, md: 0 },
          pl: 3,
        }}>
          <List >
            {step?.inputs?.map((input) => {
              return (
                <ChecklistInput
                  input={input}
                  checklistID={step?.parent_id}
                  stepID={step?.id}
                  onUpdate={() => {
                    fetchStep().then((response) => {
                      if (response?.data?.completed && (response?.data?.external_action == "H" || response?.data?.external_action == "W")) {
                        setStepApprovalPopupOpen(true)
                      }
                    })
                    onUpdate();
                  }}
                  disabled={disabled || step?.disabled}
                />
              )
            })}
          </List>
          <CardContent sx={{ mb: 0 }}>
            <FormControl variant="standard" fullWidth sx={{ pb: 0 }}>
              <InputLabel id="health-status-label">Health Status</InputLabel>
              <Select
                disabled={step?.disabled}
                labelId="health-status-label"
                id="health-status-select"
                value={step?.health_status}
                label="Health Status"
                onChange={handleHealthStatusChange}
                sx={{
                  pb: 0
                }}
              >
                <MenuItem value={0}>
                  <HealthStatusLabel name="None" color="#000" />
                </MenuItem>

                <MenuItem value={1}>
                  <HealthStatusLabel name="Safety Critical" color="#DD1D21" />
                </MenuItem>
                <MenuItem value={2}>
                  <HealthStatusLabel name="Advisory" color="#FFC000" />
                </MenuItem>
                <MenuItem value={3}>
                  <HealthStatusLabel name="Satisfactory" color="#94D500" />
                </MenuItem>
              </Select>
            </FormControl>

            {(step?.health_status === 1 || step?.health_status === 2) && <Box sx={{ mt: 5 }}>
              <Typography sx={{ mb: 1 }} variant="h5">Conclusion:

                {!step.disabled && <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    ml: "10px",
                    mr: 0,
                  }}
                  onClick={() => { setConclusionPanelOpen(true) }}
                >
                  Edit
                </Button>}

              </Typography>

              <Typography>
                {step.healthcheck_conclusion}
              </Typography>
            </Box>}


          </CardContent>
          <CardActions>


            {/* {disabled || step?.disabled || step?.completed ||
              <Box sx={{ ml: "auto", mr: 0 }}>
                <Tooltip title={"Add the step to the punchlist"}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => setRejectionPanelopen(true)}
                  >
                    Reject Step
                  </Button>
                </Tooltip>
              </Box>
            } */}
          </CardActions>

        </CardContent>
        <CardContent sx={{
          background: "#283036",
          borderRadius: 1,
          p: { xs: 0, md: 0 }
        }}>
          <AntTabs value={tabValue} onChange={handleTabChange} aria-label="View Select">
            <AntTab label="Files" count={step?.files?.length} />
            <AntTab label="Comments" count={step?.comments?.length} />
          </AntTabs>
          {tabValue == 0 && <ChecklistFiles step={step} />}
          {tabValue == 1 && <ChecklistComments step={step} disabled={step?.disabled} />}

        </CardContent>

      </div>}
    </Card >
  )
}

export default HealthCheckChecklistStep;