import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import {
  CardHeader,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
  Box,
  IconButton,
  LinearProgress,
  CircularProgress
} from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FolderIcon from '@mui/icons-material/Folder'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';


import {
  getFileCategories,
  getCategoryFiles,
  uploadFile,
  deleteFile,
  getSessionChecklistOverview
} from "../../util/api";
import Delete from '@mui/icons-material/Delete';

const ProjectFileOverview = ({ projectID, embedded, onClose, readOnly, external }) => {

  const state = useSelector((state) => state)
  const approver = state.token?.data?.rights === "approve"

  const [value, setValue] = useState(undefined);
  const [categories, setCategories] = useState([]);
  const [progress, setProgress] = useState(100);
  const [categoryFiles, setCategoryFiles] = useState([]);
  const [projectOpen, setProjectOpen] = useState(false);
  const [loading, setLoading] = useState(true)

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();


  const fetchFileCategories = (id) => {
    getFileCategories(id)
      .then((result) => {
        setCategories(result?.data);
        if (value === undefined)
          setValue(result?.data[0]?.id)
      }
      )
  }

  const fetchProjectStatus = (sessionID) => {
    if (external)
      return
    getSessionChecklistOverview(sessionID)
      .then((response) => {
        const status = response.data?.status
        setProjectOpen(status !== "DONE" && status !== "AWAITING_FINAL_APPROVAL")
      })
  }

  const fetchCategoryFiles = (id) => {
    setLoading(true)
    setCategoryFiles([])
    getCategoryFiles(projectID, id)
      .then((result) => {
        setCategoryFiles(result.data)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (projectID !== undefined) {
      fetchFileCategories(projectID);
      fetchProjectStatus(projectID)
    }
  }, [projectID])


  useEffect(() => {
    if (value !== undefined) {
      fetchCategoryFiles(value)
    }
  }, [value])

  const handleChange = (event, newValue) => {
    if (newValue !== "addingbutton") {
      setValue(newValue);
    }
  };

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      uploadFile(projectID, value, acceptedFiles, (progressEvent) => {
        setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
      })
        .then(() => fetchCategoryFiles(value))
    }
  }, [acceptedFiles])

  const OpenWindowButton = () => {
    if (external)
      return <></>
    return (
      <IconButton
        onClick={() => {
          window.open(`/projectFiles/${projectID}`, "_blank", "width=800,height=600")
        }}
      >
        <OpenInNewIcon color='primary' />
      </IconButton >
    )
  }

  const CloseButton = () => {
    return (
      <IconButton
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    )
  }

  const ProgressMeter = () => {
    return (
      <div style={{ marginTop: 20 }}>
        <Typography sx={{ marginBottom: 1 }} variant="body2">Loading Files...</Typography>
        <LinearProgress variant="determinate" value={progress} />
      </div>
    )
  }

  const Loading = () => {
    return <Box sx={{
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      display: "flex"
    }}>
      <CircularProgress />
    </Box>
  }

  //TODO: DIT IS ECHTE JUNK CODE
  return (
    <Card sx={{ borderRadius: 0.5, height: "100%" }}>
      <CardHeader
        title="File Browser"
        subheaderTypographyProps={{
          color: "#dddddd",
          variant: "body2",
          fontSize: "12px"
        }}
        avatar={embedded && <CloseButton />}
        action={embedded && <OpenWindowButton />}
      />
      <Divider sx={{ margin: 2 }} />
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="File Categories"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}
      >
        {categories.map((category) => {
          return (<Tab value={category.id} label={category.title} />)
        })}
      </Tabs>
      <CardContent justifyContent="center">
        {!approver && projectOpen && !readOnly && <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} accept="application/pdf" />
          <UploadFileIcon sx={{ fontSize: 60, marginBottom: 2 }} />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div>}

        {progress !== 100 ? <ProgressMeter /> : ""}
        <Divider sx={{ margin: 2 }} />
        <Typography sx={{ marginBottom: 1, marginTop: 2 }} variant="h5">Files</Typography>
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            maxHeight: "500px",
            border: "1px solid #525252",
            borderRadius: 0.5,
            overflow: "hidden",
            overflowY: "scroll" // added scroll
          }}
        >
          <List >
            {loading && <Loading />}
            {categoryFiles.length === 0 && !loading && <ListItem>No files in this category</ListItem>}
            {categoryFiles.map((file) => {
              return (
                <div>
                  <ListItem secondaryAction={
                    file?.approved_at === null && projectOpen && <IconButton edge="end" aria-label="delete">
                      <IconButton
                        onClick={() => {
                          deleteFile(projectID, value, file.id).then(() => {
                            fetchCategoryFiles(value)
                          })
                        }}

                      >
                        <Delete />
                      </IconButton>
                    </IconButton>
                  }>
                    <ListItemIcon>
                      <FolderIcon />
                    </ListItemIcon>
                    <Link
                      href={"/data/" + file.file_location}
                      underline="hover"
                      target="_blank"
                    >
                      <ListItemText
                        primary={file.title}
                        secondary={file?.approved_at && `Approved at ${new Date(file.approved_at).toDateString()}`}
                      />
                    </Link>

                  </ListItem>

                </div>

              )
            })}
          </List>
        </Box>
      </CardContent >
    </Card >
  )
}

export default ProjectFileOverview;
