import axios from "axios";

const BASE_URL = '/api/approval/file/'

export const createFileApprovalSession = (sessionID: string, email: string, files: any) => {
    const payload = {
        file_ids: files,
        email: email,
        session_id: sessionID
    }
    return axios.post(BASE_URL, payload)
}


export const getFileApprovalSession = (id: string, key: string) => {
    const uninterceptedAxiosInstance = axios.create();
    let config = {
        headers: {
            'key': key
        }
    }

    return uninterceptedAxiosInstance.get(BASE_URL + `${id}`, config)
}

export const approveFile = (approvalSessionID: string, key: string, fileID: number) => {
    const uninterceptedAxiosInstance = axios.create();
    let config = {
        headers: {
            'key': key
        }
    }

    return uninterceptedAxiosInstance.put(BASE_URL + `${approvalSessionID}/approve/file/${fileID}`, {}, config)
}

export const getPendingFileApprovalSessions = (sessionID: string) => {
    return axios.get(BASE_URL + `/pending/${sessionID}`);
}

export const deleteFileApprovalSession = (approvalSessionID: string) => {
    return axios.delete(BASE_URL + `/${approvalSessionID}`);
}

export const sendFileApprovalReminder = (approvalSessionID: string) => {
    return axios.post(BASE_URL + `/${approvalSessionID}/reminder`)
}