import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import {
  Badge,
  Grid,
} from "@mui/material"

import { useDispatch } from 'react-redux';


import HomeIcon from '@mui/icons-material/Home';
import ListAltIcon from '@mui/icons-material/ListAlt';
import GridViewIcon from '@mui/icons-material/GridView';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';

import PageSelector from '../../components/PageSelector';
import TitleBar from "../../components/TitleBar";

import ProjectOverview from "../ProjectOverview";
import ChecklistOverview from "../ChecklistOverview"
import IssueOverview from "../IssueOverview"
import { getUnresolvedIssueCount } from '../../util/api/issue';
import Scrollbar from '../../components/Scrollbar';

export default function Portal() {

  const [title, setTitle] = useState("")
  const [active, setActive] = useState("projects")
  const [unresolvedIssueCount, setUnresolvedIssueCount] = useState(0)

  const { path } = useParams();

  const updateIssueCounter = () => {
    getUnresolvedIssueCount().then((response) => {
      setUnresolvedIssueCount(response.data)
    })
  }

  useEffect(() => {
    if (path !== undefined) {
      setActive(path)
    }
  }, [path])

  useEffect(() => {
    window.history.pushState({}, null, "/" + active)
    updateIssueCounter();

  }, [active])

  const paths = {
    "": "",
    "projects": <ProjectOverview />,
    "testplans": <ChecklistOverview />,
    "punchlist": <IssueOverview />
  }

  const pages = [
    {
      title: "Projects",
      value: "projects",
      icon: <GridViewIcon />
    },
    {
      title: "Test Plans",
      value: "testplans",
      icon: <ListAltIcon />
    },
    // {
    //   title: "Clients",
    //   value: "clients",
    //   icon: <BusinessCenterIcon />
    // },
    {
      title: "Punchlist",
      value: "punchlist",
      icon: <Badge color="error" badgeContent={unresolvedIssueCount} ><AssignmentLateIcon /></Badge>
    },

  ]

  return (
    <div>
      <TitleBar title={title} />
      <PageSelector pages={pages} page={active} onSelect={(value) => { setActive(value) }} />

      <Grid container sx={{
        paddingTop: 0,
        justifyContent: "center"
      }}>
        <Grid xs={12} lg={6} style={{
          maxHeight: "calc(100vh - 160px)",
          overflow: "scroll",
          marginLeft: 0,
          marginTop: 0,
          width: "100%"
        }}>
          {paths[active] ? paths[active] : <div>Not found</div>}
        </Grid>
      </Grid>
    </div >
  )
}
