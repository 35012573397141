import { SET_NOTIFICATION } from '../types/actions';


export const setError = (message: any) => ({
    type: SET_NOTIFICATION,
    payload: { message: "Error: " + message, severity: "error" }
})

export const setInfo = (message: string) => ({
    type: SET_NOTIFICATION,
    payload: { message: message, severity: "info" }
})

export const setSuccess = (message: string) => ({
    type: SET_NOTIFICATION,
    payload: { message: message, severity: "success" }
})