import { useState } from 'react';

import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  TextField,
  DialogActions
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const RejectionPopup = ({ open, onClose, onSubmit }) => {
  const [rejectMessage, setRejectMessage] = useState("")

  const handleClose = () => {
    onClose()
    setRejectMessage("")
  }

  return (
    <Dialog open={open} onClose={handleClose} sx={{ padding: 2 }} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center' }}>
        Please provide reason for rejection
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          value={rejectMessage}
          onChange={(e) => { setRejectMessage(e.target.value) }}
          variant="outlined"
          multiline
          minRows={6}
          sx={{
            width: "100%"
          }}
        >

        </TextField>
      </DialogContent>
      <DialogActions>
        <Button disabled={rejectMessage === ""} onClick={() => { onSubmit(rejectMessage); handleClose(); }} autoFocus>
          Submit
        </Button>
      </DialogActions>
    </Dialog >
  )
}

export default RejectionPopup;