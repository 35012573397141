import "./ProjectFileSettings.scss"
import { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import {
  Button,
  CardHeader,
  Divider,
  IconButton,
  LinearProgress,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
  CircularProgress,
  Box,
  Tooltip,
  Checkbox
} from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InputBase from '@mui/material/InputBase';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import FolderIcon from '@mui/icons-material/Folder'
import Delete from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';

import {
  getFileCategories,
  newFileCategory,
  deleteFileCategory,
  updateFileCategory,
  getCategoryFiles,
  uploadFile,
  getSessionChecklistOverview,
  toggleFileIncludeMRB,
  deleteFile
} from "../../util/api";
import { setError } from '../../actions/notificationActions';

import FileApprovalPopup from "../Approval/FileApprovalPopup";

const ProjectFileSettings = ({ projectID }) => {
  const dispatch = useDispatch()

  const [project, setProject] = useState(undefined);
  const [value, setValue] = useState(undefined);
  const [categories, setCategories] = useState([]);
  const [editTitleValue, setEditTitleValue] = useState("");
  const [progress, setProgress] = useState(100);
  const [categoryFiles, setCategoryFiles] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const [projectEditable, setProjctEditable] = useState(false)
  const [loading, setLoading] = useState(true)
  const [editMode, setEditMode] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const handleClosePopup = (value) => {
    setPopupOpen(false);
    fetchProject();
  }

  const fetchProject = () => {
    getSessionChecklistOverview(projectID)
      .then((response) => {
        setProject(response.data)
        const status = response.data?.status
        setProjctEditable(status !== "DONE" && status !== "AWAITING_FINAL_APPROVAL")
      })
  }

  const fetchFileCategories = (id) => {
    getFileCategories(id)
      .then((result) => {
        setCategories(result.data);
        if (value === undefined)
          setValue(result.data[0]?.id)
        setEditTitleValue(result.data[0]?.title)
      }
      )
  }

  const fetchCategoryFiles = (id) => {
    setLoading(true)
    setCategoryFiles([])
    getCategoryFiles(projectID, id)
      .then((result) => {
        setCategoryFiles(result.data);
        setLoading(false)
      })
  }

  useEffect(() => {
    if (projectID !== undefined) {
      fetchFileCategories(projectID);
      fetchProject();
    }
  }, [projectID])

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      uploadFile(projectID, value, acceptedFiles, (progressEvent) => {
        setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
      })
        .then(() => fetchCategoryFiles(value))
        .catch((e) => {
          dispatch(setError(e.response?.data?.detail))
        })
    }
  }, [acceptedFiles])

  useEffect(() => {
    if (value !== undefined) {
      fetchCategoryFiles(value)
    }
  }, [value])

  const updateCategoryTitle = (id) => {
    if (projectEditable) {
      const newCategories = categories.map((cat) => {
        if (cat.id === value)
          if (cat.title !== editTitleValue) {
            cat.title = editTitleValue
            updateFileCategory(projectID, id, editTitleValue)
          }
        return cat
      })
      setCategories(newCategories);
    }
  }

  const handleChange = (event, newValue) => {
    if (newValue !== "addingbutton") {
      updateCategoryTitle(value)
      setValue(newValue);
    }
  };

  const handleNewTab = (e) => {
    if (projectEditable) {
      newFileCategory(projectID, "New Category")
        .then(() => fetchFileCategories(projectID))
    }
  }

  const ProgressMeter = () => {
    return (
      <div style={{ marginTop: 20 }}>
        <Typography sx={{ marginBottom: 1 }} variant="body2">Loading Files...</Typography>
        <LinearProgress variant="determinate" value={progress} />
      </div>
    )
  }

  const Loading = () => {
    return <Box sx={{
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      display: "flex"
    }}>
      <CircularProgress />
    </Box>
  }

  //TODO: DIT IS ECHTE JUNK CODE
  return (
    <Box style={{ position: "relative" }} sx={{
      pl: {
        xs: 1,
        lg: 0
      },
      pr: {
        xs: 1,
        lg: 0
      }
    }}>
      <Card sx={{ borderRadius: 0.5, }}>
        <FileApprovalPopup open={popupOpen} projectID={projectID} onClose={handleClosePopup} />
        <CardHeader
          title="File Browser"
          subheader={"Here you can add Files and File Categories to your project"}
          subheaderTypographyProps={{
            color: "#dddddd",
            variant: "body2",
            fontSize: "12px"
          }}
        // action={
        //   <Button disabled={!projectEditable} variant="outlined" onClick={() => setPopupOpen(true)}>
        //     Request approval
        //   </Button>
        // }

        />
        <Divider sx={{ margin: 2 }} />

        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="File Categories"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
        >
          {projectEditable &&
            <Tooltip title={editMode ? "Disable Edit Mode" : "Enable Edit Mode"}>
              {<IconButton color="primary" value="editmodeButton" onClick={() => setEditMode(!editMode)}> {editMode ? <EditOffIcon /> : <EditIcon />}</IconButton>}
            </Tooltip>
          }

          {projectEditable &&
            <Tooltip title="Add new category">
              <Tab value="addingbutton" label={<AddRoundedIcon />} onClick={handleNewTab} />
            </Tooltip>
          }

          {categories.map((category) => {
            return (value === category.id && projectEditable && editMode ?
              <Tab value={category.id} label={
                <div>
                  <InputBase
                    value={editTitleValue}
                    onBlur={() => {
                      updateCategoryTitle(category.id)
                    }}
                    onChange={(e) => {
                      setEditTitleValue(e.target.value)
                    }}
                  />
                  <IconButton onClick={(e) => {
                    let newCategories = categories.filter((a) => { return a.id !== category.id })
                    setCategories(newCategories);
                    setValue(newCategories[0]?.id)
                    setEditTitleValue(newCategories[0]?.title)
                    deleteFileCategory(projectID, category.id)
                  }} >
                    <DeleteForeverRoundedIcon />
                  </IconButton>
                </div>}
              />
              : <Tab value={category.id} label={category.title} onClick={() => setEditTitleValue(category.title)} />)
          })}
        </Tabs>
        <CardContent justifyContent="center">
          {projectEditable && <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} accept="application/pdf" />
            <UploadFileIcon sx={{ fontSize: 60, marginBottom: 2 }} />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </div>}

          {progress !== 100 ? <ProgressMeter /> : ""}
          <Divider sx={{ margin: 2 }} />
          <Typography sx={{ marginBottom: 1, marginTop: 2 }} variant="h5">Files</Typography>
          <List>
            {loading && <Loading />}
            {categoryFiles.length === 0 && !loading && <ListItem>No files in this category</ListItem>}
            {categoryFiles.map((file) => {
              let secondaryText = ""
              secondaryText = file?.approved_at ? `Approved at ${new Date(file.approved_at).toDateString()}` : secondaryText;
              // secondaryText = file?.approval_session_id !== null ? `Awaiting Approval` : secondaryText;

              return (
                <ListItem secondaryAction={
                  <div>
                    {file?.approved_at === null && file?.approval_session_id === null && projectEditable &&

                      <IconButton
                        onClick={() => {
                          deleteFile(projectID, value, file.id).then(() => {
                            fetchCategoryFiles(value)
                          })
                        }}

                      >
                        <Delete />
                      </IconButton>}
                    <Tooltip title="Include in MRB">
                      <Checkbox
                        edge="end"
                        onChange={() => toggleFileIncludeMRB(projectID, value, file.id).then((resp) => {
                          const newFiles = categoryFiles.map((f) => {
                            if (f.id === file.id)
                              f.include_in_mrb = resp.data.include_in_mrb
                            return f
                          })
                          setCategoryFiles(newFiles)
                        })}
                        checked={file.include_in_mrb}
                        disabled={!projectEditable}
                      />
                    </Tooltip>
                  </div>
                }>
                  <ListItemIcon>
                    <FolderIcon />
                  </ListItemIcon>
                  <Link
                    href={"/data/" + file.file_location}
                    underline="hover"
                    target="_blank"
                  >
                    <ListItemText
                      primary={file.title}
                      secondary={secondaryText}
                    />
                  </Link >
                  <ListItemText
                    primary={""}
                  />
                  {file.approvals.length > 0 ? `Approved by ${file.approvals[0].approver_email}` : ""}


                </ListItem>
              )
            })}
          </List>
        </CardContent>
      </Card >
    </Box >
  )
}

export default ProjectFileSettings;
