import { Dialog, IconButton } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';

const ImageView = ({ open, onClose, alt, imageURL }) => {

  const CloseButton = () => {
    return (
      <IconButton
        aria-label="close image"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8
        }}
      >
        <CloseIcon />
      </IconButton>)
  }

  return (<Dialog
    open={open}
    onClose={onClose}
    fullWidth
    maxWidth={"md"}
  >
    <CloseButton />
    <img src={imageURL} alt={alt ? alt : imageURL} />
  </Dialog>)
}

export default ImageView