import React, { useState, useEffect } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';

import DeleteIcon from '@mui/icons-material/Delete';

import { updateChecklistStepInput } from "../../util/api/checklist";

export default function ChecklistStepInput({ checklistID, stepID, inputID, inputType, inputTitle, inputRequired, onDelete, deleteDisabled }) {
  const [type, setType] = useState(inputType);
  const [title, setTitle] = useState(inputTitle);
  const [required, setRequired] = useState(inputRequired);

  const handleInputTypeChange = (event) => {
    setType(event.target.value);

    const payload = {
      input_type: event.target.value
    };
    updateChecklistStepInput(checklistID, stepID, inputID, payload);
  }

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  }

  const handleRequiredChange = (event) => {
    setRequired(!required);

    const payload = {
      required: !required
    }

    updateChecklistStepInput(checklistID, stepID, inputID, payload);
  }

  const updateTitle = () => {
    const payload = {
      title: title
    }

    updateChecklistStepInput(checklistID, stepID, inputID, payload);
  }

  return (
    <div style={{ marginTop: 30 }}>
      <TextField
        id="outlined-multiline-flexible"
        label="Title"
        multiline
        value={title}
        onChange={handleTitleChange}
        onLostPointerCapture={updateTitle}
        onBlur={updateTitle}
        sx={{ width: "100%" }}
      />
      <TextField
        id={"Input Type " + inputID}
        value={type}
        label="Input Type"
        onChange={handleInputTypeChange}
        sx={{ width: "100%", marginTop: "10px" }}
        select
      >
        <MenuItem value={"0"}>Checkbox</MenuItem>
        <MenuItem value={"1"}>Textfield</MenuItem>
        <MenuItem value={"2"}>Photo Upload</MenuItem>
        <MenuItem value={"3"}>Date</MenuItem>
      </TextField>

      <FormControlLabel control={<Checkbox checked={required} onChange={handleRequiredChange} color="primary" />} label="Required" />


      {deleteDisabled || <IconButton
        aria-label="delete input"

        onClick={() => { onDelete(inputID) }} sx={{ float: "right" }}>
        <DeleteIcon />
      </IconButton>}
    </div>
  )
}
