import { Box, Divider, Grid, IconButton, Input, Paper, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Document, Page, Outline, pdfjs } from 'react-pdf';



import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
// import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';

const ZOOM_DELTA = 0.25
const MIN_ZOOM = 0.25;
const MAX_ZOOM = 5;

const PDFViewer = ({ filename, title, hideControls, pdfBoxSx = {} }) => {

  pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.js"

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1.5)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }


  const handlePrevPage = () => {
    setPageNumber(pageNumber - 1)
  }

  const handleNextPage = () => {
    setPageNumber(pageNumber + 1)
  }

  const handlePageChange = (e) => {
    const val = parseInt(e.target.value)

    if (val > 0 && val <= numPages) {
      setPageNumber(val)
    }
  }

  const increaseZoom = () => {
    if (zoom + ZOOM_DELTA <= MAX_ZOOM) {
      setZoom(zoom + ZOOM_DELTA)
    } else {
      setZoom(MAX_ZOOM)
    }
  }

  const decreaseZoom = () => {
    if (zoom - ZOOM_DELTA >= MIN_ZOOM) {
      setZoom(zoom - ZOOM_DELTA)
    } else {
      setZoom(MIN_ZOOM)
    }
  }

  const handleRotateCW = () => {
    setRotation((rotation + 90) % 360)
  }

  const handleRotateCCW = () => {
    setRotation((rotation - 90 + 360) % 360)
  }

  const PageControls = () => {
    return (<Grid>
      <IconButton color="secondary" onClick={handlePrevPage} disabled={pageNumber === 1}>
        <NavigateBeforeIcon />
      </IconButton>
      <Input sx={{ width: "40px", padding: 0, textAlign: "right", display: "inline-block" }}
        value={pageNumber}
        onChange={handlePageChange}
        variant="outlined"
        inputProps={{ min: 0, style: { textAlign: 'center', padding: 0 } }}
      />
      of {numPages}
      <IconButton color="secondary" onClick={handleNextPage} disabled={pageNumber === numPages}>
        <NavigateNextIcon />
      </IconButton>
    </Grid>)
  }

  const ZoomControls = () => {
    return (
      <Grid>
        <IconButton color="secondary" disabled={zoom <= MIN_ZOOM} onClick={decreaseZoom}>
          <ZoomOutIcon />
        </IconButton>
        {(zoom * 100).toFixed(0)}%
        <IconButton color="secondary" disabled={zoom >= MAX_ZOOM} onClick={increaseZoom}>
          <ZoomInIcon />
        </IconButton>
      </Grid>
    )
  }

  const RotateControls = () => {
    return (
      <Grid>
        <IconButton color="secondary" onClick={handleRotateCCW}>
          <Rotate90DegreesCcwIcon />
        </IconButton>

        {/* <IconButton color="secondary" onClick={handleRotateCW}>
          <Rotate90DegreesCwIcon />
        </IconButton> */}
      </Grid>
    )
  }

  const PDFControls = () => {
    return (
      <Paper sx={{
        padding: 1,
        height: { lg: 60, xs: 120 },
        borderRadius: 0,
        width: "100vw",
        justifyContent: "center",
        display: "flex",
        position: "absolute",
        top: 0,
        zIndex: 100000
      }}>

        <Grid container>
          <Grid item xs={12} lg={4} paddingLeft={3} alignItems="center" display="flex">
            <Typography
              textAlign="center"
              variant="h6"
              sx={{ fontSize: { md: 16, xl: 18, xs: 14 }, width: "100%" }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ flexDirection: "row", display: "flex", alignitems: "center", justifyContent: "center" }}>
            {/* <PageControls /> */}
            {/* <Divider orientation="vertical" variant="middle" flexItem sx={{ ml: 1, mr: 1 }} /> */}

            <ZoomControls />
            <Divider orientation="vertical" variant="middle" flexItem sx={{ ml: 1, mr: 1 }} />
            <RotateControls />
          </Grid>
        </Grid>

      </Paper >
    )
  }

  return (
    <Grid sx={{ height: "100vh", maxWidth: "100vw", overflow: "scroll" }}>
      {!hideControls && <PDFControls />}
      <Document
        file={filename}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(e) => console.log(e)}

        renderMode="svg"
      >
        <Box sx={{ justifyContent: "center", alignItems: "center", display: "flex", mt: { lg: "60px", xs: "120px" }, maxWidth: "100%", flexDirection: "column", pb: "15vh", ...pdfBoxSx }}>
          {Array(numPages).fill(0).map((_, i) => {
            return (
              <Box sx={{ mt: 1 }}>
                <Page pageNumber={i + 1} renderMode="canvas" rotate={rotation} scale={zoom} />
              </Box>
            )

          })}

        </Box>
      </Document>

    </Grid >

  )
}

export default PDFViewer;