import axios from "axios";

export const createMFASession = async (username: string, password: string) => {

  const payload = new FormData();
  payload.append("username", username);
  payload.append("password", password);

  return await axios.post(
    `/api/user/login`,
    payload
  )
}

export const logInMFA = async (username: string, password: string, mfa_session_id: string, mfa_code: string) => {

  const payload = {
    username: username,
    password: password,
    mfa_session_id: mfa_session_id,
    code: mfa_code
  }

  return await axios.post(
    `/api/user/login/mfa`,
    payload
  )
}


export const externalLogIn = async (session_id: string, passcode: string) => {
  const uninterceptedAxiosInstance = axios.create();
  const payload = {
    passcode: passcode
  }

  return await uninterceptedAxiosInstance.post(
    `/api/session/${session_id}/login`, payload
  )
}

export const getChecklistOverview = async () => {
  const url = "/api/checklist/";

  return axios.get(url);
}

export const refreshToken = () => {
  return axios.get('/api/user/token/refresh');
}

export const postData = async (url: string, data: any) => {
  return axios.post(url, data)
}

/* SESSION */
export const getSessionChecklistOverview = (sessionID: string) => {
  return axios.get(`/api/session/${sessionID}/`);
}

/* PROJECT */

export const getProjectOverview = () => {
  return axios.get("/api/session/");
}

export const createInspectionProject = (data: any) => {
  return axios.post("/api/session/", data)
}

export const createHealthCheckProject = (data: any) => {
  return axios.post("/api/session/healthcheck", data)
}

export const deleteProject = (projectID: string) => {
  return axios.delete(`/api/session/${projectID}/`)
}

export const getFileCategories = (sessionID: string) => {
  return axios.get(`/api/session/${sessionID}/files/category/`)
}

export const newFileCategory = (sessionID: string, title: string) => {

  const data = { title: title }
  return axios.post(`/api/session/${sessionID}/files/category/`, data);
}

export const deleteFileCategory = (sessionID: string, id: string) => {

  return axios.delete(`/api/session/${sessionID}/files/category/${id}`);
}

export const updateFileCategory = (sessionID: string, id: string, title: string) => {

  const data = { title: title }
  return axios.put(`/api/session/${sessionID}/files/category/${id}`, data);
}

export const uploadFile = (sessionID: string, id: string, files: any, onProgress: () => void) => {
  let formData = new FormData();
  for (var i = 0; i < files.length; i++) {
    formData.append('files', files[i])
  }

  const config = {
    onUploadProgress: onProgress
  }

  return axios.post(`/api/session/${sessionID}/files/category/${id}/file`, formData, config);
}

export const getCategoryFiles = (sessionID: string, id: string) => {
  return axios.get(`/api/session/${sessionID}/files/category/${id}/file`);
}

export const addExternalAccess = (sessionID: string, email: string, rights: string) => {
  const payload = {
    email: email,
    rights: rights
  }
  return axios.post(
    `/api/session/${sessionID}/external`,
    payload
  )
}

export const getExternalAccess = (sessionID: string) => {
  return axios.get(`/api/session/${sessionID}/external`);
}

export const deleteExternalAccess = (sessionID: string, accessID: string) => {
  return axios.delete(`/api/session/${sessionID}/external/${accessID}`)
}

export const fetchSessionUnapprovedFiles = (sessionID: string) => {
  return axios.get(`/api/session/${sessionID}/unapproved/files`);
}

export const fetchSessionUnapprovedChecklists = (sessionID: string) => {
  return axios.get(`/api/session/${sessionID}/unapproved/checklist`);
}

export const fetchSessionUnapprovedChecklistSteps = (sessionID: string) => {
  return axios.get(`/api/session/${sessionID}/unapproved/steps`);
}

export const getSessionFiles = (sessionID: string) => {
  return axios.get(`/api/session/${sessionID}/files`)
}

export const deleteFile = (sessionID: string, categoryID: string, fileID: string) => {
  return axios.delete(`/api/session/${sessionID}/files/category/${categoryID}/file/${fileID}`);
}

export const toggleFileIncludeMRB = (sessionID: string, categoryID: string, fileID: string) => {
  return axios.post(`/api/session/${sessionID}/files/category/${categoryID}/file/${fileID}/toggleinclude`);
}

export const approveFile = (sessionID: string, categoryID: string, fileID: string) => {
  return axios.get(`/api/session/${sessionID}/files/category/${categoryID}/file/${fileID}/approve`);
}

export const approveChecklist = (checklistID: string) => {
  return axios.get(`/api/checklist/${checklistID}/approve`);
}

export const approveChecklistStep = (checklistID: string, stepID: string) => {
  return axios.get(`/api/checklist/${checklistID}/steps/${stepID}/approve`);
}

export const lockFilesForSession = (sessionID: string, approverID: string) => {
  const payload = {
    approver_id: approverID
  }
  return axios.post(`/api/session/${sessionID}/files/lock`, payload);
}

export const lockChecklistsForSession = (sessionID: string, approverID: string) => {
  const payload = {
    approver_id: approverID
  }
  return axios.post(`/api/session/${sessionID}/checklist/lock`, payload);
}

export const lockSession = (sessionID: string, approverID: string) => {
  const payload = {
    approver_id: approverID
  }
  return axios.post(`/api/session/${sessionID}/lock`, payload);
}

export const unLockSession = (sessionID: string) => {
  return axios.post(`/api/session/${sessionID}/unlock`);
}

export const unlockChecklistsForSession = (sessionID: string) => {

  return axios.post(`/api/session/${sessionID}/checklist/unlock`);
}

export const unlockFilesForSession = (sessionID: string) => {

  return axios.post(`/api/session/${sessionID}/files/unlock`);
}

export const getSessionPDF = (sessionID: string) => {

  return axios({
    url: `/api/session/${sessionID}/pdf`,
    responseType: 'arraybuffer',
    method: "GET",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', response.headers?.["x-file-name"]); //or any other extension
    document.body.appendChild(link);
    link.click();
  })
}

export const getSessionItpPDF = (sessionID: string) => {
  return axios({
    url: `/api/session/${sessionID}/pdfitp`,
    responseType: 'arraybuffer',
    method: "GET",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', response.headers?.["x-file-name"]); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
}

export const createProjectFromFile = (files: any, onProgress: () => void) => {
  let formData = new FormData();
  for (var i = 0; i < files.length; i++) {
    formData.append('file', files[i])
  }

  const config = {
    onUploadProgress: onProgress
  }

  return axios.post(`/api/session/create/fromfile`, formData, config);
}
