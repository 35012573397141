import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import SaveIcon from '@mui/icons-material/Save';
import { changePassword, updateUser } from "../../util/api/userApi";
import { setError, setSuccess } from "../../actions/notificationActions";
import { setLoading, setToken } from "../../actions/tokenActions";

import { StyledTextfield } from "../../components/StyledTextField";
import TitleBar from "../../components/TitleBar";
import { withStyles } from "@mui/styles";



export const EditTextField = withStyles({
  input: {
    color: "#fff"
  },
  root: {
    '& label.Mui-focused': {
      color: '#212529',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#212529',
        color: "White"
      },
      '&:hover fieldset': {
        borderColor: '#83d100',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#212529',
      },
    },
    width: "300px",
  },
})(TextField);

const PasswordDialog = ({ open, onClose }) => {

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const [passwordError, setPasswordError] = useState(false)
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [newPasswordConfirmError, setNewPasswordConfirmError] = useState(false)

  const dispatch = useDispatch()

  const handleConfirm = () => {
    setPasswordError(currentPassword === "");
    setNewPasswordError(newPassword === "");
    setNewPasswordConfirmError(newPasswordConfirm === "" || newPasswordConfirm !== newPassword);

    if (currentPassword === ""
      || newPassword === ""
      || newPasswordConfirm === "" || newPasswordConfirm !== newPassword
    ) {
      return;
    }

    changePassword(currentPassword, newPassword, newPasswordConfirm).then(() => {
      dispatch(setSuccess("Password changed!"))
      onClose()
    }).catch((e) => {
      dispatch(setError(e?.response?.data?.detail))
    })
  }

  return <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
    <DialogTitle>Change Password</DialogTitle>
    <DialogContent>
      <Box sx={{ spacing: 3, display: "flex", flexDirection: "column" }}>
        <StyledTextfield
          sx={{ mt: 2 }}
          label="Current Password"
          size="small"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          type="password"
          placeholder="Provide your current password"
          required
          error={passwordError}
        />
        <StyledTextfield
          sx={{ mt: 2 }}
          label="New Password"
          size="small"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          type="password"
          placeholder="Provide your current password"
          required
          error={newPasswordError}
        />
        <StyledTextfield
          sx={{ mt: 2 }}
          label="Confirm New Password"
          size="small"
          value={newPasswordConfirm}
          onChange={(e) => setNewPasswordConfirm(e.target.value)}
          type="password"
          placeholder="Provide your current password"
          required
          error={newPasswordConfirmError}
        />
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={handleConfirm}>Confirm</Button>
    </DialogActions>
  </Dialog>
}

const SettingDisplay = ({ title, value, editMode, onChange, passwordField, imageField }) => {

  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false)
  const [stampDialogOpen, setStampDialogOpen] = useState(false);


  return <Grid container sx={{ mb: 5 }}>
    <PasswordDialog open={passwordDialogOpen} onClose={() => { setPasswordDialogOpen(false) }} />
    <Grid item xs={4} md={2}><Typography variant="body1"><b>{title}</b></Typography></Grid>
    <Grid item xs={8} md={10}>
      {editMode || <Typography sx={{ paddingLeft: "10px" }}>{value}</Typography>}
      {editMode && !passwordField && <Typography sx={{ color: "black" }}>
        <EditTextField
          inputProps={{ style: { color: "#fff", padding: 2, paddingLeft: 10, pt: 4 } }}
          sx={{ color: "black" }}
          value={value}
          size="small"
          onChange={onChange}
        />
      </Typography>}
      {editMode && passwordField &&
        <Button
          disabled
          onClick={() => { setPasswordDialogOpen(true) }}
          startIcon={<LockIcon />}
          size="small"
          variant="outlined"
          sx={{ color: "black", borderColor: "black", background: "white" }}>
          Change Password
        </Button>
      }
      {editMode && imageField &&

        <Button
          onClick={() => { setPasswordDialogOpen(true) }}
          startIcon={<LockIcon />}
          size="small"
          variant="outlined"
          sx={{ color: "black", borderColor: "black", background: "white" }}>
          Change Password
        </Button>}
    </Grid>
  </Grid>
}

const AccountPage = () => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [stampLocation, setStampLocation] = useState("")
  const [editMode, setEditMode] = useState(false)

  const state = useSelector((state) => state)
  const dispatch = useDispatch();

  const canSave = () => {
    return (email !== state?.token?.data?.username
      || name !== state?.token?.data?.display_name
    )
  }

  const handleSave = () => {
    if (!canSave())
      return;

    updateUser(email, name).then((resp) => {
      dispatch(setToken(resp.data))
      dispatch(setSuccess("Information updated!"))
      setEditMode(false)
    }).catch((e) => {
      dispatch(setError(e.response?.data?.detail))
    })
  }


  useEffect(() => {
    setEmail(state?.token?.data?.username)
    setName(state?.token?.data?.display_name)
    setStampLocation(state?.token.data?.stamp_file_path)
  }, [state?.token])

  console.log(stampLocation)

  return <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
    <TitleBar title={"Account Settings"} />
    <Grid container justifyContent={"center"} sx={{ padding: { md: 4, xs: 1 }, flex: 500, }}>
      <Grid item xs={12} md={8} sx={{ mb: 4 }}>
        <Box sx={{}}>
          <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Typography variant="h4">Profile</Typography>
            {editMode || <Button disabled sx={{ ml: "auto", mr: 0, height: "30px" }} size="small" onClick={() => setEditMode(true)} variant="outlined" startIcon={<EditIcon />}> Edit</Button>}
            {editMode && <Box sx={{ ml: "auto", mr: 0, }}>
              <Button sx={{ height: "30px", mr: 3 }} size="small" onClick={() => setEditMode(false)} variant="outlined">
                Cancel
              </Button>

              <Button disabled={!canSave()} sx={{ height: "30px" }} size="small" onClick={handleSave} variant="outlined" startIcon={<SaveIcon />}>
                Save
              </Button>
            </Box>}
          </Box>
          <Divider sx={{ mt: 1, mb: 3 }} />
          <SettingDisplay title="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} editMode={editMode} />
          <SettingDisplay title="Name" value={name} onChange={(e) => setName(e.target.value)} editMode={editMode} />
          <SettingDisplay title="Password" value={"••••••••"} editMode={editMode} passwordField />
          <SettingDisplay imageField title="Stamp" value={<img src={stampLocation}></img>} />
        </Box>
      </Grid>
    </Grid>

  </div>
}

export default AccountPage;