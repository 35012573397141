import { useState, useEffect } from 'react'
import { useSearchParams, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';

import {
  Box,
  Grid,
  Button,
  Typography,
  Paper,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Avatar,
  IconButton,
  Tooltip
} from '@mui/material';

import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CloseIcon from '@mui/icons-material/Close';
import FastForwardIcon from '@mui/icons-material/FastForward';

import { setError } from '../../../actions/notificationActions';

import Done from '../../../components/Approval/Done';
import Loading from '../../../components/Loading';
import PDFViewer from "../../../components/Approval/PDFViewer";
import RejectionPopup from '../../../components/Approval/RejectionPopup';

import { getStepApprovalSession, approveStep, waiveStep, rejectStep } from '../../../util/api/stepApproval';
import StepSignDialog from './StepApprovalSignDialog';


const StepApproval = () => {
  const dispatch = useDispatch()
  const { sessionID } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [stepIndex, setStepIndex] = useState(0);
  const [session, setSession] = useState(null)
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true)
  const [steps, setSteps] = useState([])
  const [infoDialogOpen, setInfoDialogOpen] = useState(true)
  const [signatureOpen, setSignatureOpen] = useState(false);

  const fetchSession = () => {
    getStepApprovalSession(sessionID, searchParams.get("key")).then((res) => {
      setSession(res.data)
      setSteps(res.data?.steps.filter((step) => { return (step.approved_at === null && step.waived_at === null && step.punchlist_step_id === null) }))
      setLoading(false)
    }).catch((e) => {
      dispatch(setError(e.response?.data?.detail))
    })
  }

  useEffect(() => {
    if (sessionID !== null) {
      fetchSession();
    }
  }, [sessionID])

  const handleApprove = () => {
    setSignatureOpen(true);
    // approveStep(sessionID, searchParams.get("key"), steps[stepIndex]?.id).then(() => {
    //   fetchSession();
    // })
  }

  const handleReject = () => {
    setRejectDialogOpen(true)
  }

  const handleWaive = () => {
    waiveStep(sessionID, searchParams.get("key"), steps[stepIndex]?.id).then(() => {
      fetchSession();
    })
  }

  const onRejectSubmit = (value) => {
    rejectStep(sessionID, searchParams.get("key"), steps[stepIndex]?.id, value).then(() => {
      fetchSession();
    })
  }

  const handleRejectDialogClose = () => {
    setRejectDialogOpen(false);
  }

  const handleCloseInfoPopup = () => {
    setInfoDialogOpen(false)
  }

  const CloseButton = () => {
    return (
      <IconButton
        aria-label="close"
        onClick={handleCloseInfoPopup}
        sx={{
          position: "absolute",
          right: 8,
          top: 8
        }}
      >
        <CloseIcon />
      </IconButton>)
  }

  const InfoContainer = ({ icon, text }) => {
    return (
      <Grid container
        sx={{
          mt: "30px"
        }}>
        <Grid item xs={3} sx={{ display: "flex", alignitems: "center", justifyContent: "center" }}>
          <Avatar sx={{ background: "none", padding: "30px" }} >
            {icon}
          </Avatar>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1">
            {text}
          </Typography>
        </Grid>
      </Grid >
    )
  }

  const InfoPopup = () => {
    let infoText = ""

    if (session) {
      // infoText = `${session?.creator?.display_name} requested your approval for an inspection point`
    }

    return (
      <Dialog
        onClose={handleCloseInfoPopup}
        sx={{ zIndex: 199999999 }}
        fullWidth
        maxWidth={"sm"}
        open={infoDialogOpen}
        PaperProps={{
          elevation: 0,
          variant: "outlined",
          justifyContent: "left",
          textAlign: "center",
          alignItems: "center",
        }}>
        <CloseButton />

        <DialogContent>
          <Grid container spacing={5}>
            <Grid item xs={11} sx={{ mt: "0px", pb: "50px" }}>
              <DialogTitle>
                Please Review the Following Inspection Results
              </DialogTitle>
              <InfoContainer
                icon={<ThumbUpIcon fontSize="large" />}
                text={"Results can be approved by clicking the 'Approve' button. This will sign off the results for the inspection step that is currently in view."}
              />
              <InfoContainer
                icon={<ThumbDownIcon fontSize="large" />}
                text={"Results can be rejected by clicking the 'Reject' button. This will allow you to add extra comments to help the other party resolve the issue."}
              />
              <InfoContainer
                icon={<FastForwardIcon fontSize="large" />}
                text={"Witness points can be waived by clicking the 'Waive' button. This will unblock the step and allows the inspection to continue."}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInfoPopup} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const ActionButtons = () => {
    return (
      <Box sx={{
        position: "absolute",
        bottom: 50,
        zIndex: 1,
        width: "100vw"
      }}>
        <Grid container justifyContent={"center"}>
          <Grid item xs={10} md={6} lg={4.5} xl={3.5}>
            <Paper sx={{ padding: "10px", display: "flex", justifyContent: "center", }}>
              <Box>
                <Button
                  onClick={handleReject}
                  startIcon={<ThumbDownIcon />}
                  variant="outlined"
                  sx={{ display: { xs: "none", md: "inline-flex" } }}
                >
                  Reject
                </Button>
                <Tooltip title="Reject">
                  <IconButton color="secondary" onClick={handleReject} sx={{ display: { md: "none" } }}>
                    <ThumbDownIcon />
                  </IconButton>
                </Tooltip>
              </Box>

              <Box>
                <Typography variant="h6" sx={{ width: "100px" }} textAlign="center" alignItems="center">
                  {stepIndex + 1} / {steps?.length}
                </Typography>
              </Box>

              {steps[stepIndex]?.external_action === "W" && <Box><Button
                sx={{
                  mr: 1,
                  ml: 1,
                  display: { xs: "none", md: "inline-flex" }
                }}
                onClick={handleWaive}
                endIcon={< FastForwardIcon />}
                variant="outlined"
              >
                Waive
              </Button>
                <Tooltip title="Waive">
                  <IconButton color="secondary" onClick={handleWaive} sx={{ mr: 1, display: { md: "none" } }}>
                    <FastForwardIcon />
                  </IconButton>
                </Tooltip>
              </Box>}

              <Box>
                <Button
                  sx={{ display: { xs: "none", md: "inline-flex" } }}
                  onClick={handleApprove}
                  endIcon={<ThumbUpIcon />}
                  variant="outlined"
                >
                  Approve
                </Button>
                <Tooltip title="Approve">
                  <IconButton color="secondary" onClick={handleApprove} sx={{ display: { md: "none" } }}>
                    <ThumbUpIcon />
                  </IconButton>
                </Tooltip>
              </Box>

            </Paper>
          </Grid>
        </Grid>

      </Box >
    )

  }


  const open = (steps.length > 0)

  return (
    loading ? <Loading /> :
      <div>

        <RejectionPopup
          open={rejectDialogOpen}
          onClose={handleRejectDialogClose}
          onSubmit={onRejectSubmit}
        />
        <StepSignDialog
          onClose={() => { setSignatureOpen(false) }}
          open={signatureOpen}
          title={session?.session?.title}
          onSignOff={() => { setSignatureOpen(false); fetchSession(); }}
          signKey={searchParams.get("key")}
          sessionID={sessionID}
          stepID={steps[stepIndex]?.id}
        />

        {open && !infoDialogOpen && <ActionButtons />}

        <Grid>
          {!open && <Done />}
          {open && <InfoPopup />}
          {open && <PDFViewer
            title={steps[stepIndex]?.title + " Results"}
            filename={`/api/approval/step/result/${steps[stepIndex]?.id}/${searchParams.get("key")}`} />}
        </Grid>
      </div>
  )
}

export default StepApproval;