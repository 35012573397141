import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import SignatureCanvas from 'react-signature-canvas'

import './signature.scss'
import { signChecklistStep } from "../../util/api/checklist";

const ACTION_TABLE = {
  "A": "Approve",
  "M": "Monitor",
  "R": "Review",
  "H": "Hold",
  "W": "Witness",
  None: ""
}

const ClientSignDialog = ({ selectedStep, onClose, onSignoff }) => {
  const [clientName, setClientName] = useState("")

  const sigCanvas = useRef({});

  const handleClose = () => {
    onClose();
  }

  const handleSign = () => {
    const trimmedSig = trimmed()

    trimmedSig.toBlob((blob) => {
      // console.log(blob)
      const temp = new File([blob], "signature.png")
      signChecklistStep(selectedStep?.parent_id, selectedStep?.id, clientName, temp).then((e) => {
        onSignoff();
      })
    }, "image/png");

    // console.log(trimmedSig)
  }

  const clear = () => sigCanvas.current.clear();

  const trimmed = () => sigCanvas.current.getTrimmedCanvas()

  return (
    <Dialog fullWidth maxWidth="md" open={selectedStep !== undefined} onClose={handleClose}>
      <DialogContent>
        <DialogTitle sx={{ p: 0 }}>{selectedStep?.title} -  Sign Off</DialogTitle>
        <Box display={"flex"} sx={{ color: "rgba(255, 255, 255, 0.6)" }}>
          <div style={{ marginRight: 100 }}><b>Internal:</b> <i>{ACTION_TABLE[selectedStep?.action]}</i></div>
          <div><b>External:</b> <i>{ACTION_TABLE[selectedStep?.external_action]}</i></div>
        </Box>
        <Box sx={{ background: "white", borderRadius: "10px", p: 0, border: "2px solid black", mt: 2 }}>
          {!selectedStep?.signature_path && <SignatureCanvas ref={sigCanvas}
            penColor="#000"
            // backgroundColor="#fff"
            canvasProps={{
              className: "signatureCanvas"
            }} />}
          {selectedStep?.signature_path && <img src={selectedStep?.signature_path} className="signatureCanvas" />}
        </Box>
      </DialogContent>
      <DialogActions sx={{ alignItems: "center", display: "flex" }}>
        {!selectedStep?.signature_path && <TextField
          id="standard-basic"
          label="Name"
          variant="standard"
          value={clientName}
          onChange={(e) => { setClientName(e.target.value) }}

          sx={{ float: "right", ml: 2, mr: "auto" }}
        />}
        {selectedStep?.signature_name && <Typography sx={{ float: "right", ml: 2, mr: "auto" }}>Signed by: {selectedStep?.signature_name}</Typography>}
        {!selectedStep?.signature_path && <Button onClick={clear}>Clear</Button>}
        <Button onClick={handleClose}>Close</Button>
        {!selectedStep?.signature_path && <Button disabled={clientName === ""} onClick={handleSign}>Sign</Button>}
      </DialogActions>
    </Dialog>
  )
}

export default ClientSignDialog;