import "./style.scss"

import { useState, useEffect } from "react";
import {
  Grid,
  Box
} from "@mui/material"


import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import Loading from "../../components/Loading"
import ChecklistOverviewCard from "../../components/ChecklistOverview/ChecklistOverviewCard";
import { getChecklistOverview, postData } from "../../util/api";

type ChecklistOverviewRow = {
  id: string;
  deleted_at: number;
  description: string;
  updated_at: string;
  created_at: number;
  title: string;
};

export default function ChecklistOverview() {
  const [loading, setLoading] = useState(true);
  const [Checklists, setChecklists] = useState([]);

  const [openSpeedDial, setOpenSpeedDial] = useState(false);
  const handleOpen = () => setOpenSpeedDial(true);
  const handleClose = () => setOpenSpeedDial(false);

  const fetchOverview = () => {
    getChecklistOverview()
      .then((result) => {
        setChecklists(result.data);
        setLoading(false);
      })
  }

  useEffect(() => {
    if (loading) {
      fetchOverview();
    }
  }, [Checklists, loading])

  const noChecklistAvailable = () => {
    return (
      <Grid container sx={{ paddingTop: 2, height: "100vh" }}>
        <Grid container justifyContent="center" spacing={3}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h3">
              No checklists available
            </Typography>
          </Box>
        </Grid>
      </Grid>
    )
  }

  const handleNewChecklistClick = () => {
    const payload = {
      title: "New Checklist",
      description: "Placeholder description"
    }
    postData("/api/checklist/", payload).then(() => {
      setLoading(true)
    })
    handleClose();
  }

  const handleSearchClick = () => {
    handleClose();
  }

  const handleSettingsClick = () => {
    handleClose();
  }

  const handleExit = () => {
    window.location.href = "/";
  }

  const actions = [
    { icon: <AddIcon />, name: 'New', action: handleNewChecklistClick },
    { icon: <SearchIcon />, name: 'Search', action: handleSearchClick },
    { icon: <SettingsIcon />, name: 'Settings', action: handleSettingsClick },
    { icon: <ExitToAppIcon />, name: 'Exit', action: handleExit },
  ];

  const main = () => {
    return (
      <Grid container sx={{ paddingTop: 2 }}>
        <Grid container justifyContent="center" spacing={3}>
          {Checklists?.map((row: ChecklistOverviewRow) => {
            return (
              <Grid item>
                <ChecklistOverviewCard
                  id={row.id}
                  title={row.title}
                  description={row.description}
                  lastUpdate={new Date(row.updated_at).toDateString()}
                  onDelete={fetchOverview}
                />
              </Grid>
            )
          })}
          {
            Checklists.length === 0 ? noChecklistAvailable() : ""
          }
        </Grid>
        <SpeedDial
          ariaLabel="SpeedDial controlled open example"
          sx={{ position: 'absolute', bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={openSpeedDial}
          FabProps={{
            sx: {
              bgcolor: 'text.secondary',
              '&:hover': {
                bgcolor: 'text.secondary',
              }
            }
          }}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.action}
              FabProps={{
                sx: {
                  color: 'text.primary',
                }
              }}
            />
          ))}
        </SpeedDial>
      </Grid>
    )
  }

  return (
    <div>
      {loading ? <Loading /> : main()}
    </div>
  )
}
