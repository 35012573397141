import { useDispatch } from 'react-redux';
import { logOut } from "../../actions/tokenActions"

const Logout = () => {
    const dispatch = useDispatch()

    dispatch(logOut())
    return (
        <div></div>
    )
}

export default Logout;