import FileIssueTile from "./FileIssueTile";
import ChecklistIssueTile from "./ChecklistIssueTile";
import ProjectIssueTile from "./ProjectIssueTile";

const IssueTile = ({ issue, updateSearchFilter, onClickResolve }) => {

  if (issue?.file_approval_session !== null) {
    return (
      <FileIssueTile
        issue={issue}
        updateSearchFilter={updateSearchFilter}
        onClickResolve={onClickResolve}
      />
    )

  } else if (issue?.checklist_approval_session !== null) {
    return (
      <ChecklistIssueTile
        issue={issue}
        updateSearchFilter={updateSearchFilter}
        onClickResolve={onClickResolve}
      />
    )
  }
  else if (issue?.project_approval_session !== null) {
    return (
      <ProjectIssueTile
        issue={issue}
        updateSearchFilter={updateSearchFilter}
        onClickResolve={onClickResolve}
      />
    )
  }
  else {
    return (<div></div>)
  }
}

export default IssueTile