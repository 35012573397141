import axios from "axios";

const BASE_URL = '/api/approval/step/'

export const getAvailableStepApprovers = (stepID: string) => {
  return axios.get(BASE_URL + `approvers/${stepID}`)
}

export const createNewStepApprover = (stepID: string, email: string) => {
  const payload = {
    step_id: stepID,
    email: email
  }
  return axios.post(BASE_URL, payload)
}

export const addStepToApprovalSession = (approvalSessionID: string, stepID: string) => {
  const payload = {
    step_id: stepID
  }

  return axios.post(BASE_URL + `session/${approvalSessionID}/add`, payload);
}

export const getStepApprovalSession = (approvalSessionID: string, key: string) => {
  const uninterceptedAxiosInstance = axios.create();
  let config = {
    headers: {
      'key': key
    }
  }

  return uninterceptedAxiosInstance.get(BASE_URL + "session/" + approvalSessionID, config)
}

export const approveStep = (approvalSessionID: string, key: string, stepID: string, signName: string, signatureFile: any) => {
  const uninterceptedAxiosInstance = axios.create();
  let config = {
    headers: {
      'key': key
    }
  }

  let formData = new FormData();
  formData.append("signature_file", signatureFile)
  formData.append('signame', signName)

  return uninterceptedAxiosInstance.post(BASE_URL + `session/${approvalSessionID}/${stepID}/approve`, formData, config);
}

export const waiveStep = (approvalSessionID: string, key: string, stepID: string) => {
  const uninterceptedAxiosInstance = axios.create();
  let config = {
    headers: {
      'key': key
    }
  }

  const payload = {
    step_id: stepID
  }

  return uninterceptedAxiosInstance.post(BASE_URL + `session/${approvalSessionID}/waive`, payload, config);
}

export const rejectStep = (approvalSessionID: string, key: string, stepID: string, content: string) => {
  const uninterceptedAxiosInstance = axios.create();
  let config = {
    headers: {
      'key': key
    }
  }

  const payload = {
    step_id: stepID,
    content: content
  }

  return uninterceptedAxiosInstance.post(BASE_URL + `session/${approvalSessionID}/reject`, payload, config);
}