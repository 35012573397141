import { Grid, Box, Typography } from "@mui/material";
const Subheader = ({ title, leftAction, rightAction }) => {

  return (
    <Grid container sx={{ background: "#283036", width: "100vw", borderBottom: 1, borderColor: '#212529', alignItems: "center", p: 1 }}>
      <Grid item xs={3} sx={{
        justifyContent: "left",
        display: "flex",
        pl: 1
      }}>
        {leftAction}
      </Grid>
      <Grid item xs={6} sx={{
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center"
      }}>
        <Typography sx={{
          fontSize: { sx: 12, md: 16 },
          fontWeight: "bold"
        }}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={3} sx={{
        justifyContent: "right",
        display: "flex",
        pr: 1
      }}>
        {rightAction}
      </Grid>
    </Grid>
  )
}

export default Subheader;