import axios from "axios";

const BASE_URL = "/api/issue/";

export const createIssueForFile = async (
  session_id: string,
  file_id: number,
  content: string
) => {
  const payload = {
    session_id: session_id,
    file_id: file_id,
    content: content,
  };

  return axios.post(BASE_URL, payload);
};

export const resolveFileIssue = async (
  issueID: string,
  files: any,
  onProgress: () => void
) => {
  let formData = new FormData();
  for (var i = 0; i < files.length; i++) {
    formData.append("file", files[i]);
  }

  const config = {
    onUploadProgress: onProgress,
  };

  return axios.post(BASE_URL + issueID + "/resolve", formData, config);
};

export const resolveChecklistIssue = async (issueID: string) => {
  return axios.post(BASE_URL + issueID + "/resolve");
};

export const resolveProjectIssue = async (issueID: string) => {
  return axios.post(BASE_URL + issueID + "/resolve");
};


export const getAllUnresolvedIssues = () => {
  return axios.get(BASE_URL + "/unresolved")
}

export const getAllResolvedIssues = () => {
  return axios.get(BASE_URL + "/resolved")
}

export const getUnresolvedIssueCount = () => {
  return axios.get(BASE_URL + "/unresolved/count")
}