import { useState, useEffect } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Typography,
  Link,
  Divider,
  DialogActions,
  Button,
  LinearProgress
} from "@mui/material"

import { useDropzone } from 'react-dropzone';


import UploadFileIcon from '@mui/icons-material/UploadFile';

import { resolveFileIssue } from '../../util/api/issue';


const FileIssueResolve = ({ open, onClose, issue }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ multiple: false });
  const [progress, setProgress] = useState(100);

  const handleClose = (toUpdate) => {

    acceptedFiles.length = 0;
    onClose(toUpdate);
  }

  const handleResubmit = () => {
    if (acceptedFiles.length > 0) {
      resolveFileIssue(issue.id, acceptedFiles, (progressEvent) => {
        setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
      })
        .then(() => handleClose(true))
    }
  }


  const ProgressMeter = () => {
    return (
      <div style={{ marginTop: 20 }}>
        <Typography sx={{ marginBottom: 1 }} variant="body2">Loading Files...</Typography>
        <LinearProgress variant="determinate" value={progress} />
      </div>
    )
  }

  console.log(issue)

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={() => { handleClose(false) }}>
      <DialogTitle>{issue?.file?.title}</DialogTitle>
      <Divider sx={{ ml: 2, mr: 2 }} />
      <DialogContent>
        <DialogContentText sx={{ color: "#dddddd" }}>
          <Typography variant="paragraph">

            <Link href={`mailto:${issue?.checklist_approval_session?.approver_email}`}>
              {issue?.checklist_approval_session?.approver_email + " "}
            </Link>

            had the following to say:

          </Typography>
          <Typography sx={{ mt: 1, mb: 0 }} variant="subtitle1">
            <i>“{issue?.content}”</i>
          </Typography>

        </DialogContentText>
      </DialogContent >
      <Divider sx={{ ml: 2, mr: 2 }} />

      <DialogContent >
        <div {...getRootProps({ className: 'dropzone' })} style={{ mt: 3 }}>
          <input {...getInputProps()} accept="application/pdf" />
          {acceptedFiles.length !== 0 && acceptedFiles.map((file) => {
            return (
              <div>
                <UploadFileIcon sx={{ fontSize: 60, marginBottom: 2 }} />
                <p>{file.path}</p>
              </div>
            )
          })}
          {acceptedFiles.length === 0 && <UploadFileIcon sx={{ fontSize: 60, marginBottom: 2 }} />}
          {acceptedFiles.length === 0 && <p>Drag 'n' drop some files here, or click to select files</p>}
        </div>
        {progress !== 100 ? <ProgressMeter /> : ""}
      </DialogContent >

      <DialogActions>
        <Button onClick={() => handleClose(false)} >Cancel</Button>
        <Button onClick={handleResubmit} disabled={acceptedFiles.length === 0}>Resubmit</Button>
      </DialogActions>
    </Dialog >
  )
}

export default FileIssueResolve;