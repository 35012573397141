import {
  Dialog,
  DialogTitle,
  DialogContentText,
  Button,
  DialogActions,
  DialogContent,
} from "@mui/material"

const WarningDialog = ({ title, content, open, onConfirm, onCancel }) => {
  return <Dialog
    open={open}
    onClose={onCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    PaperProps={{
      elevation: 0,
      variant: "outlined",
      justifyContent: "center",
      textAlign: "center",
      alignItems: "center",
    }}
  >
    <DialogTitle id="alert-dialog-title">
      {title}
    </DialogTitle>

    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {content}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} autoFocus>Cancel</Button>
      <Button onClick={onConfirm}>
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
}

export default WarningDialog;