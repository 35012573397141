const SET_TOKEN = 'setToken';
const SET_LOADING = 'setLoading';
const SET_NOTIFICATION = 'setNotification';
const CLEAR_NOTIFICATION = 'clearError';

export {
  SET_TOKEN,
  SET_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
}
