import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import ReactFlow, {
  removeElements,
  addEdge,
  MiniMap,
  Controls,
  useZoomPanHelper,
} from 'react-flow-renderer';

import {
  Grid,
  Box
} from "@mui/material"

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import CircularProgress from '@mui/material/CircularProgress';

import AddIcon from '@mui/icons-material/Add';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SaveIcon from '@mui/icons-material/Save';
import FitScreenIcon from '@mui/icons-material/FitScreen';

import ChecklistEditorStep from "../../components/ChecklistEditor/ChecklistEditorStep";

import {
  createChecklistStep,
  getChecklistSteps,
  deleteChecklistStep,
  updateChecklistStep
} from "../../util/api/checklist";

const nodeTypes = {
  stepNode: ChecklistEditorStep,
};

const DISTANCE_X = 450;
const NUM_PER_ROW = 6;

export default function ChecklistEditor() {
  const [loading, setLoading] = useState(true);
  const [elements, setElements] = useState([]);
  const [lastStep, setLastStep] = useState(undefined);
  const [openSpeedDial, setOpenSpeedDial] = useState(false);

  const { checklistID } = useParams();


  const fetchSteps = () => {
    getChecklistSteps(checklistID)
      .then((result) => {
        const data = result.data;

        let newElements = data.steps.map((element => {
          return {
            type: 'stepNode',
            sourcePosition: 'right',
            targetPosition: 'left',
            id: element.id,
            data: {
              ...element,
              checklistID,
            },

            position: { x: element.x, y: element.y },
          }
        }))
        newElements = calculateEdges(newElements);
        setElements(newElements);

        setLoading(false);
      })
  }

  useEffect(() => {
    if (elements.length === 0 && loading) {
      fetchSteps();
    }
  }, [checklistID])

  const { fitView } = useZoomPanHelper();

  const fixLayout = () => {
    let counterX = 0;
    let newElems = elements.map((elem) => {
      if (elem.type === "stepNode") {
        let newX = 0;
        let newY = 0;
        if (Math.floor(counterX / ((NUM_PER_ROW) * DISTANCE_X)) % 2 === 1) {
          newX = counterX % (NUM_PER_ROW * DISTANCE_X);
        } else {
          newX = counterX % (NUM_PER_ROW * DISTANCE_X);
        }

        newY = DISTANCE_X * Math.floor(counterX / (NUM_PER_ROW * DISTANCE_X));

        counterX += DISTANCE_X;

        elem.position = { x: newX, y: 0 };
        elem.position = { x: newX, y: newY };

        const payload = {
          x: elem.position.x,
          y: elem.position.y
        }
        updateChecklistStep(checklistID, elem.id, payload);
      }

      return elem;

    });
    setElements(newElems);
    fitView({ padding: 0.1, includeHiddenNodes: true })
  }
  const calculateEdges = (elementList) => {
    let last = undefined;

    let result = [];
    elementList.forEach((elem) => {
      if (elem.type === "stepNode") {
        setLastStep(elem.id);

        result.push(elem);
        if (last !== undefined) {
          const edge = {
            id: `${last.id}_${elem.id}`,
            source: last.id,
            target: elem.id,
            type: "smoothStep",
            arrowHeadType: 'arrowclosed',
          }
          result.push(edge);
        }
        last = elem;
      }
    })

    return result;
  }

  const onLoad = (reactFlowInstance) => reactFlowInstance.fitView();

  const onNodeDragStop = (event, node) => {
    const payload = {
      x: node.position.x,
      y: node.position.y
    }
    updateChecklistStep(checklistID, node.id, payload);

    let e = elements.map((elem) => {
      if (elem.id === node.id) {
        elem.position = node.position;
      }

      return elem;
    })

    setElements(e);
  }

  const onElementsRemove = (elementsToRemove) => {
    elementsToRemove.forEach(element => {
      if (element.type === "stepNode") {
        deleteChecklistStep(checklistID, element.id).then(() => {

        })
      }
    });

    let newElems = calculateEdges(removeElements(elementsToRemove, elements))
    setElements(newElems);
  }

  const onConnect = (params) => {

    const sourceID = params.source;
    const targetID = params.target;

    var startPos = 2 ** 32;

    // find start position
    elements.every((el) => {
      if (el.id === sourceID) {
        startPos = el.data.position;
        return false;
      }

      return true;
    })

    // set correct positions
    let newElements = elements.map((el) => {
      if (el.type !== 'stepNode')
        return el;
      if (el.id === sourceID) {
        return el;
      }
      else if (el.id === targetID) {
        el.data = { ...el.data, position: startPos + 1 }
      } else if (el.data.position >= startPos) {
        el.data = { ...el.data, position: el.data.position + 1 }
      }
      const payload = {
        position: el.data.position
      }
      updateChecklistStep(checklistID, el.id, payload);

      return el;
    })

    // resort based on position in the checklist
    newElements.sort((a, b) => {
      if (a.type === "stepNode" && b.type === "stepNode") {
        if (a.data.position > b.data.position) {
          return 1
        }

        if (a.data.position < b.data.position) {
          return -1
        }

        return 0
      }

      if (a.type === "stepNode" && b.type !== "stepNode") {
        return 1
      }

      if (a.type !== "stepNode" && b.type === "stepNode") {
        return -1
      }

      return 0;

    }
    );

    // recalculate the edges
    newElements = calculateEdges(newElements)

    setElements(newElements)
  };

  //speed dial functions
  const handleOpen = () => setOpenSpeedDial(true);
  const handleClose = () => setOpenSpeedDial(false);
  const onExit = () => {
    window.location.href = "/"
  }

  const onSave = () => {
    handleClose();
  }

  // gets called when user creates a new step
  const onNewStep = () => {
    createChecklistStep(checklistID)
      .then(result => {
        const data = result.data;
        const lastStepData = elements.find((elem) => {
          return elem.id === lastStep;
        })
        let x = lastStepData ? lastStepData.position.x + DISTANCE_X : 0;
        let y = lastStepData ? lastStepData.position.y : 0;

        y += DISTANCE_X * Math.floor(x / (NUM_PER_ROW * DISTANCE_X));
        x = x % (NUM_PER_ROW * DISTANCE_X);

        // add the new entry to the display
        const newEntry = {
          id: data.id,
          type: 'stepNode',
          sourcePosition: 'right',
          targetPosition: 'left',
          data: {
            ...data,
            checklistID,
          },
          position: { x: x, y: y },
        };

        const payload = {
          x: newEntry.position.x,
          y: newEntry.position.y
        }
        updateChecklistStep(checklistID, data.id, payload);

        const newEntries = calculateEdges([...elements, newEntry])
        setElements(newEntries)
        handleClose();
      })

  }

  const speedDialMenu = () => {

    const actions = [
      { icon: <AddIcon />, name: 'New', action: onNewStep },
      {
        icon: <FitScreenIcon />, name: 'Auto Layout', action: () => {
          fixLayout();
          handleClose();
        }
      },
      { icon: <SaveIcon />, name: 'Save', action: onSave },
      { icon: <ExitToAppIcon />, name: 'Exit', action: onExit },
    ];

    return (
      <SpeedDial
        ariaLabel="SpeedDial controlled open example"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={openSpeedDial}
        FabProps={{
          sx: {
            bgcolor: 'text.secondary',
            '&:hover': {
              bgcolor: 'text.secondary',
            }
          }
        }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.action}
            FabProps={{
              sx: {
                color: 'text.primary',
              }
            }}
          />
        ))}
      </SpeedDial>
    )
  }

  const main = () => {
    return (

      <ReactFlow
        elements={elements}
        onElementsRemove={onElementsRemove}
        onConnect={onConnect}
        onLoad={onLoad}
        selectNodesOnDrag={false}
        onNodeDragStop={onNodeDragStop}
        nodeTypes={nodeTypes}
      >
        <MiniMap
          style={{ top: 10 }}
          nodeStrokeWidth={1}
          nodeStrokeColor={(n) => {
            if (n.type === 'input') return '#0041d0';
            if (n.type === 'selectorNode') return "#525252";
            if (n.type === 'output') return '#ff0072';
          }}
          nodeColor={(n) => {
            if (n.type === 'selectorNode') return "#525252";
            return '#525252';
          }}
        />
        <Controls />
        {speedDialMenu()}
      </ReactFlow>
    )
  }

  const loadingIcon = () => {
    return (
      <Grid container sx={{ paddingTop: 2, height: "100vh" }}>
        <Grid container justifyContent="center" spacing={3}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        </Grid>
      </Grid>
    )
  }

  return (
    loading ? loadingIcon() : main()
  )
}
