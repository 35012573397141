import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  Typography,
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  Divider,
  Grid,
  Box
} from "@mui/material"

import BusinessIcon from '@mui/icons-material/Business';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

import { logOut } from '../actions/tokenActions';

import logo from "../static/images/company_logo.png"

const TitleBar = ({ title, sx }) => {
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const AccountMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <ListItemIcon>
            <BusinessIcon fontSize="small" />
          </ListItemIcon>
          Manage Organization
        </MenuItem>
        <MenuItem onClick={() => { window.location.href = "/account" }} href='/account'>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => dispatch(logOut())}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    )
  }

  const state = useSelector((state) => state)

  return (
    <Grid container paddingLeft={5} paddingRight={5} sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "60px",
      borderBottom: "1px solid #212529",
      background: "#212529",
      ...sx
    }}>
      <Grid item xs={4} md={2}>
        <a href="/">
          <img
            style={{
              marginLeft: "0",
              marginRight: "auto",
              maxHeight: "30px"
            }}
            src={logo}
            alt="Ventil Logo" />
        </a>
      </Grid>
      <Grid item xs={6} md={8}
        sx={{
          textAlign: "center"
        }}
      >
        <Typography variant="h4" fontWeight={200}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Box sx={{ display: "flex", justifyContent: "right", alignItems: "center", textAlign: "right", gap: 2 }}>
          <Typography variant="h6">{state?.token?.data?.display_name}</Typography>
          <IconButton
            onClick={handleClick}
            sx={{
              display: "flex",
              // marginLeft: "auto",
              marginRight: "0",
            }}>
            <Avatar
              alt="Unavailable"
              sx={{
                background: "#83D100"
              }}

            ></Avatar>
          </IconButton>
        </Box>

      </Grid>
      <AccountMenu />
    </Grid>
  )
}

export default TitleBar
