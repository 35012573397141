import React from "react";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { ReactFlowProvider } from 'react-flow-renderer';


import { initInterceptor } from './util/interceptor'


import "./App.scss";
import AppRouter from "./AppRouter";
import StorageProvider from "./Store"

// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import NotificationComponent from "./components/ErrorComponent";

const App: React.FC = () => {
    const { store, persistor } = StorageProvider();

    initInterceptor(store.dispatch, store);

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ReactFlowProvider>
                    <ThemeConfig>
                        <GlobalStyles />
                        <NotificationComponent />
                        <AppRouter />
                    </ThemeConfig>
                </ReactFlowProvider>
            </PersistGate>
        </Provider>
    );
};

export default App;
