import { SET_NOTIFICATION, CLEAR_NOTIFICATION } from "../types/actions"

const notificationReducer = (state: any = { message: undefined }, action: any) => {
    switch (action.type) {
        case SET_NOTIFICATION:
            return { ...state, message: action.payload?.message, severity: action.payload?.severity }

        case CLEAR_NOTIFICATION:
            return { ...state, message: undefined }
        default:
            return state;
    }
}

export default notificationReducer;
