
import {
  Box,
  Button,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import AddIcon from '@mui/icons-material/Add';
import SearchBar from "../SearchBar";
import { createProjectFromFile } from '../../util/api';
import { useDispatch } from 'react-redux';
import { setError } from '../../actions/notificationActions';


const Input = styled('input')({
  display: 'none',
});

const TopBar = ({ searchValue, handleSearchChange, cancelSearch, onCreate }) => {
  const dispatch = useDispatch();

  return (
    <Box sx={{
      width: "100%",
      pt: 1,
      pb: 1,
      pl: 0,
      pr: 0,
      display: "flex"
    }}>
      <SearchBar placeholder="Find a project..." value={searchValue} handleSearchChange={handleSearchChange} cancelSearch={cancelSearch} />


      <Box
        sx={{
          marginLeft: "auto",
          marginRight: "0",
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Tooltip title="New Project">
          {/* <label htmlFor={"create-new-project-button"}>
            <Input
              accept=".docx, .doc"

              id={"create-new-project-button"}
              type="file"
              onChange={
                (e) => {
                  createProjectFromFile(e.target.files, (_progressEvent) => { })
                    .then(() => {
                      onCreate();
                    }).catch((e) => {
                      dispatch(setError(e.response.data.detail))
                    })
                }
              }
            />
            <Button
              color="primary"
              component="span"
              aria-label="new project"
              startIcon={<AddIcon />}
              sx={{
                marginLeft: "auto",
                marginRight: "0",
                display: 'flex',
                alignItems: 'center',
                pl: 3,
                pr: 3,
                background: "#212529",
                color: "#83D100",
                ":hover": {
                  background: "#283036",
                }
              }}
            >
              New Project
            </Button>
          </label> */}
          <Button
            sx={{
              marginLeft: "auto",
              marginRight: "0",
              display: 'flex',
              alignItems: 'center',
              pl: 3,
              pr: 3,
              background: "#212529",
              color: "#83D100",
              ":hover": {
                background: "#283036",
              }
            }}
            startIcon={<AddIcon />}
            size="small"
            onClick={onCreate}
          >
            New Project
          </Button>
        </Tooltip>
      </Box>
    </Box >
  )
}


export default TopBar;
