import { useDispatch } from 'react-redux';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  ListItem,
  List,
  Link,
  Grid,
  Typography,
  Box
} from '@mui/material';
import { useState, useEffect } from 'react'
import {
  getPendingFileApprovalSessions,
  sendFileApprovalReminder,
  deleteFileApprovalSession
} from "../../util/api/fileApproval";

import { setSuccess } from '../../actions/notificationActions';
import { deleteChecklistApprovalSession, getPendingChecklistApprovalSessions, sendChecklistApprovalReminder } from '../../util/api/checklistApproval';
import { deleteProjectApprovalSession, getPendingProjectApprovalSessions, sendProjectApprovalReminder } from '../../util/api/projectApproval';

const PendingFileApprovalTile = ({ session, onUpdate }) => {
  const dispatch = useDispatch()

  const handleSendReminder = (e) => {
    sendFileApprovalReminder(session.id).then(() => {
      onUpdate();
      dispatch(setSuccess("Reminder successfully sent!"))
    })
  }

  const handleDelete = (e) => {
    deleteFileApprovalSession(session.id).then(() => {
      onUpdate();
    })
  }

  return (
    <Card sx={{
      marginBottom: 0,
      width: "100%",
      borderRadius: 0,
      boxShadow: "none",
      display: "flex",
      flexDirection: "column",
      background: "none",
      borderBottom: "1px solid",
      borderColor: "#212529",
      ":last-child": {
        border: "none"
      }
    }} >
      <CardHeader
        title={
          `File approval session for ${session.approver_email}`
        }
        sx={{ pl: 3, pt: 2 }}
        subheader={`Created on ${new Date(Date.parse(session?.created_at)).toDateString()}`}
        subheaderTypographyProps={{
          color: "#dddddd",
          variant: "body2",
          fontSize: "14px",
          flexGrow: "1",
          pb: 1
        }}
      />
      < CardContent sx={{ pl: 3, pt: 0, pb: 0, ":last-child": { pb: 1 } }}>
        <List subheader={
          <b>Files</b>
        }>
          {
            session.files?.map((f) => {
              return <ListItem><Link href={"/data/" + f.file_location} target="_blank">{f.title}</Link></ListItem>
            })
          }
        </List>

      </CardContent>

      <CardActions>
        <Box sx={{
          ml: 0,
          mr: "auto"
        }}>
          <Button onClick={handleDelete} sx={{ ml: "auto", mr: 0, mt: 2, }}>
            Remove
          </Button>
          <Button onClick={handleSendReminder} sx={{ ml: "auto", mr: 0, mt: 2, }}>
            Send Reminder
          </Button>
        </Box>
        <Typography sx={{ ml: "auto", mr: 0, color: "#dddddd" }} variant="caption">
          {`Last activity on ${new Date(Date.parse(session?.updated_at)).toDateString()}`}
        </Typography>
      </CardActions>
    </Card >
  )
}

const PendingProjectApprovalTile = ({ session, onUpdate }) => {
  const dispatch = useDispatch()

  const handleSendReminder = (e) => {
    sendProjectApprovalReminder(session.id).then(() => {
      onUpdate();
      dispatch(setSuccess("Reminder successfully sent!"))
    })
  }

  const handleDelete = (e) => {
    deleteProjectApprovalSession(session.id).then(() => {
      onUpdate();
    })
  }

  return (
    <Card sx={{
      marginBottom: 0,
      width: "100%",
      borderRadius: 0,
      boxShadow: "none",
      display: "flex",
      flexDirection: "column",
      background: "none",
      borderBottom: "1px solid",
      borderColor: "#212529",
      ":last-child": {
        border: "none"
      }
    }} >
      <CardHeader
        title={
          `Project approval session for ${session.approver_email}`
        }
        sx={{ pl: 3, pt: 2 }}
        subheader={`Created on ${new Date(Date.parse(session?.created_at)).toDateString()}`}
        subheaderTypographyProps={{
          color: "#dddddd",
          variant: "body2",
          fontSize: "14px",
          flexGrow: "1",
          pb: 1
        }}
      />
      <CardActions>
        <Box sx={{
          ml: 0,
          mr: "auto"
        }}>
          <Button onClick={handleDelete} sx={{ ml: "auto", mr: 0, mt: 2, }}>
            Remove
          </Button>
          <Button onClick={handleSendReminder} sx={{ ml: "auto", mr: 0, mt: 2, }}>
            Send Reminder
          </Button>
        </Box>
        <Typography sx={{ ml: "auto", mr: 0, color: "#dddddd" }} variant="caption">
          {`Last activity on ${new Date(Date.parse(session?.updated_at)).toDateString()}`}
        </Typography>
      </CardActions>
    </Card >
  )
}

const PendingChecklistApprovalTile = ({ session, onUpdate }) => {
  const dispatch = useDispatch()

  const handleSendReminder = (e) => {
    sendChecklistApprovalReminder(session.id).then(() => {
      onUpdate();
      dispatch(setSuccess("Reminder successfully sent!"))
    })
  }

  const handleDelete = (e) => {
    deleteChecklistApprovalSession(session.id).then(() => {
      onUpdate();
    })
  }

  return (
    <Card sx={{
      marginBottom: 0,
      width: "100%",
      borderRadius: 0,
      boxShadow: "none",
      display: "flex",
      flexDirection: "column",
      background: "none",
      borderBottom: "1px solid",
      borderColor: "#212529",
      ":last-child": {
        border: "none"
      }
    }} >
      <CardHeader
        title={
          `ITP approval session for ${session.approver_email}`
        }
        sx={{ pl: 3, pt: 2 }}
        subheader={`Created on ${new Date(Date.parse(session?.created_at)).toDateString()}`}
        subheaderTypographyProps={{
          color: "#dddddd",
          variant: "body2",
          fontSize: "14px",
          flexGrow: "1",
          pb: 1
        }}
      />
      <CardActions>
        <Box sx={{
          ml: 0,
          mr: "auto"
        }}>
          <Button onClick={handleDelete} sx={{ ml: "auto", mr: 0, mt: 2, }}>
            Remove
          </Button>
          <Button onClick={handleSendReminder} sx={{ ml: "auto", mr: 0, mt: 2, }}>
            Send Reminder
          </Button>
        </Box>
        <Typography sx={{ ml: "auto", mr: 0, color: "#dddddd" }} variant="caption">
          {`Last activity on ${new Date(Date.parse(session?.updated_at)).toDateString()}`}
        </Typography>
      </CardActions>
    </Card >
  )
}

const PendingApprovalTile = ({ session, onUpdate }) => {
  if (session.session_type === "FILE") {
    return <PendingFileApprovalTile session={session} onUpdate={onUpdate} />
  }

  if (session.session_type === "CHECKLIST") {
    return <PendingChecklistApprovalTile session={session} onUpdate={onUpdate} />
  }

  if (session.session_type === "PROJECT") {
    return <PendingProjectApprovalTile session={session} onUpdate={onUpdate} />
  }

  return <div>Unkown Session</div>
}

const ProjectApprovals = ({ projectID }) => {

  const [projectSessions, setProjectSessions] = useState([]);
  const [fileSessions, setFileSessions] = useState([]);
  const [checklistSessions, setChecklistSessions] = useState([]);

  const [loadingFiles, setLoadingFiles] = useState(true)
  const [loadingChecklists, setLoadingChecklists] = useState(true)
  const [loadingProjects, setLoadingProjects] = useState(true);

  const fetchFileSessions = () => {
    getPendingFileApprovalSessions(projectID).then((res) => {
      setFileSessions(res.data);
      setLoadingFiles(false)
    })
  }

  const fetchChecklistSessions = () => {
    getPendingChecklistApprovalSessions(projectID).then((res) => {
      setChecklistSessions(res.data);
      setLoadingChecklists(false)
    })
  }

  const fetchProjectSessions = () => {
    getPendingProjectApprovalSessions(projectID).then((res) => {
      setProjectSessions(res.data);
      setLoadingProjects(false)
    })
  }

  useEffect(() => {
    if (loadingFiles) {
      fetchFileSessions()
    }
  }, [loadingFiles])

  useEffect(() => {
    if (loadingChecklists) {
      fetchChecklistSessions();
    }
  }, [loadingChecklists])

  useEffect(() => {
    if (loadingProjects) {
      fetchProjectSessions();
    }
  }, [loadingProjects])

  const handleUpdate = () => {
    fetchFileSessions();
    fetchChecklistSessions();
    fetchProjectSessions();
  }

  const sessions = [...checklistSessions, ...fileSessions, ...projectSessions];

  return (
    <Grid container justifyContent="center" sx={{
      ml: 0,
      mt: 0,
      width: "100%",
      border: "1px solid",
      background: 'None',
      borderColor: '#212529',
      borderRadius: 1,
    }}>
      {sessions.map((f) => {
        return <PendingApprovalTile session={f} onUpdate={handleUpdate} />
      })}

      {sessions.length === 0 && <Box sx={{ pt: 10, pb: 10 }}>
        <Typography variant="h4">
          No pending approvals
        </Typography>
      </Box>}
    </Grid>
  )
}

export default ProjectApprovals;