import { useState } from "react";
import { useDispatch } from 'react-redux';
import PasswordStrengthBar from 'react-password-strength-bar';

import { Box, Button, Card, CardContent, Checkbox, FormControlLabel, Grid, IconButton, LinearProgress, Typography } from "@mui/material";

import { registerUser } from "../../util/api/userApi";

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { setError } from "../../actions/notificationActions";
import { StyledTextfield } from "../../components/StyledTextField";

import { Helmet } from 'react-helmet';

const AccountCreationPage = () => {
  const [username, setUsername] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [usernameError, setUsernameError] = useState(false);
  const [displaynameError, setDisplaynameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordConfirmError, setPasswordConfirmError] = useState(false);
  const [loading, setLoading] = useState(true)
  const [policyCheckError, setPolicyCheckError] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch()

  const handleLogin = () => {
    setLoading(true);
    setUsernameError(false);
    setDisplaynameError(false);
    setPasswordError(false);
    setPasswordConfirmError(false);


    if (username === "") {
      setUsernameError(true);
    }

    if (displayName === "") {
      setDisplaynameError(true);
    }

    if (password === "") {
      setPasswordError(true);
    }

    if (confirmPassword === "" || confirmPassword !== password) {
      setPasswordConfirmError(true);
    }


    if (username === ""
      || displayName === ""
      || password === ""
      || confirmPassword === ""
      || confirmPassword !== password
    ) {
      return;
    }

    registerUser(username, displayName, password, confirmPassword).then((res) => {
      window.location.href = "/registerSuccess?user=" + res.data.id
    }).catch((e) => {
      dispatch(setError(e.response?.data?.detail))
    })
  }


  return <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>

    <Helmet>
      <title>VIP | Create Account</title>
    </Helmet>

    <Grid container justifyContent={"center"} sx={{ padding: { md: 10, xs: 0 }, flex: 500 }}>
      <Grid item xs={12} md={6} lg={4} sx={{ mb: 4 }}>

        <Card sx={{
          borderRadius: { xs: 0, md: 4 },
          padding: { xs: 1, md: 4 },
          pt: { xs: 0, md: 4, },
          pb: { xs: 0, md: 4, },
          background: "#1a1a1a",
          justifyContent: "center",
          alignItems: 'center',
          display: "flex"
        }}>
          <CardContent sx={{ width: { xs: "100%", md: "100%", lg: "100%" } }}>
            <Box sx={{ width: "100%" }} display="flex" alignItems="center" justifyContent={"center"} flexDirection={"column"}>

              <Typography variant="h3">Create Account</Typography>
              <StyledTextfield
                error={usernameError}
                id="field1"
                label="Email"
                variant="outlined"
                required
                size="small"
                autoComplete="off"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                sx={{
                  mt: 3
                }}
              />

              <StyledTextfield
                error={displaynameError}
                id="field2"
                label="Display Name"
                variant="outlined"
                required
                size="small"
                autoComplete="off"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                sx={{
                  mt: 3
                }}
              />

              <StyledTextfield
                error={passwordError}
                label="Password"
                required
                variant="outlined"
                autoComplete="new-password"
                value={password}
                size="small"
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  mt: 3
                }}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment:
                    <IconButton color="primary" onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                }}
              />
              <PasswordStrengthBar style={{ width: "100%" }} password={password} />

              <StyledTextfield
                label="Confirm password"
                error={passwordConfirmError || confirmPassword !== password}
                required
                variant="outlined"
                autoComplete="off"
                value={confirmPassword}
                size="small"
                onChange={(e) => setConfirmPassword(e.target.value)}
                sx={{
                  mt: 3
                }}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment:
                    <IconButton color="primary" onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                }}
              />
              {password !== confirmPassword && <Typography variant="caption" color="error" sx={{ ml: "auto", mr: 0, textAlign: "right" }}>Passwords do not match</Typography>}


              <Button
                sx={{ mt: 3, width: "100%", alignItems: "center", justifyContent: "center" }}
                variant="contained" color="primary"
                onClick={handleLogin}
              >
                Create
              </Button>
              {loading && <LinearProgress />}
              <Box sx={{ mt: 3, width: "100%" }}>

                <Box sx={{ textAlign: "left", width: "100%", display: "flex", flexDirection: { md: "row" } }}>
                  <Typography sx={{ fontSize: { xs: 12, md: 15 }, ml: 0, mr: "auto" }}>
                    <a style={{ textAlign: "left", textDecoration: "underline", color: "white" }} href="/forgotPassword">Forgot password?</a>
                  </Typography>

                  <Typography sx={{ fontSize: { xs: 12, md: 15 }, mr: 0, ml: "auto" }}>
                    <a style={{ ml: 2, textAlign: "right", textDecoration: "underline", color: "white" }} href="/login">Already have an account?</a>
                  </Typography>

                </Box>


              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>


    </Grid >
  </div >
}

export default AccountCreationPage;