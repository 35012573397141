import { useState, useEffect } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Typography,
  Link,
  Divider,
  DialogActions,
  Button,
} from "@mui/material"

import { resolveProjectIssue } from '../../util/api/issue';


const FileIssueResolve = ({ open, onClose, issue }) => {

  const handleClose = (toUpdate) => {
    onClose(toUpdate);
  }

  const handleResubmit = () => {
    resolveProjectIssue(issue?.id).then(() => {
      handleClose(true)
    })
  }

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={() => { handleClose(false) }}>
      <DialogTitle>{issue?.file?.title}</DialogTitle>
      <Divider sx={{ ml: 2, mr: 2 }} />
      <DialogContent>
        <DialogContentText sx={{ color: "#dddddd" }}>
          <Typography variant="paragraph">

            <Link href={`mailto:${issue?.project_approval_session?.approver_email}`}>
              {issue?.project_approval_session?.approver_email + " "}
            </Link>

            had the following to say:

          </Typography>
          <Typography sx={{ mt: 1, mb: 0 }} variant="subtitle1">
            <i>“{issue?.content}”</i>
          </Typography>

        </DialogContentText>
      </DialogContent >
      <Divider sx={{ ml: 2, mr: 2 }} />

      <DialogActions>
        <Button onClick={() => handleClose(false)} >Cancel</Button>
        <Button onClick={handleResubmit}>Resubmit</Button>
      </DialogActions>
    </Dialog >
  )
}

export default FileIssueResolve;