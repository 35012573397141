import { Grid, Card, Button, CardContent, Box, Typography, CircularProgress, IconButton } from "@mui/material";

import { useState } from "react";

import { useParams } from "react-router-dom";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useDispatch } from "react-redux";
import { setError, setSuccess } from "../../actions/notificationActions";
import { resetPassword } from "../../util/api/userApi";
import { StyledTextfield } from "../../components/StyledTextField";

const ResetPassword = () => {

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { resetKey } = useParams();

  const dispatch = useDispatch();

  const handleSubmit = () => {
    setLoading(true)
    resetPassword(resetKey, password, confirmPassword).then(() => {
      setLoading(false)
      dispatch(setSuccess("Password succesfully reset!"))
      setInterval(function () { window.location.href = "/login" }, 1000);
    }).catch((e) => {
      setLoading(false)
      dispatch(setError(e.response?.data?.detail))
    })
  }

  return <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>

    <Grid container justifyContent={"center"} sx={{ padding: { md: 10, xs: 1 }, flex: 500, }}>
      <Grid item xs={12} md={6} lg={4} sx={{ mb: 4 }}>

        <Card sx={{
          borderRadius: { sx: 1, md: 4 },
          padding: { xs: 1, md: 4 },
          pt: { xs: 0, md: 4, },
          pb: { xs: 0, md: 4, },
          background: "#1a1a1a",
          justifyContent: "center",
          alignItems: 'center',
          display: "flex"
        }}>
          <CardContent sx={{ width: { xs: "100%", md: "100%", lg: "100%" } }}>
            <Box sx={{ width: "100%" }} display="flex" alignItems="center" justifyContent={"center"} flexDirection={"column"}>
              <Typography sx={{ mb: 3 }} variant="h6"><b>Password Reset</b></Typography>
              <Typography>Provide a new password for your account</Typography>
              <StyledTextfield
                id="field1"
                label="Password"
                variant="outlined"
                required
                size="small"
                autoComplete="off"
                placeholder="Please enter a new password"
                value={password}
                type={showPassword ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  mt: 3
                }}
                InputProps={{
                  endAdornment:
                    <IconButton color="primary" onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                }}
              />

              <StyledTextfield
                id="field1"
                label="Confirm Password"
                variant="outlined"
                required
                size="small"
                autoComplete="off"
                placeholder="Confirm your password"
                value={confirmPassword}
                type={showPassword ? "text" : "password"}
                onChange={(e) => setConfirmPassword(e.target.value)}
                sx={{
                  mt: 3
                }}
                InputProps={{
                  endAdornment:
                    <IconButton color="primary" onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                }}
              />

              <Button
                sx={{ mt: 3, width: "100%", alignItems: "center", justifyContent: "center" }}
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                disabled={password === "" || password !== confirmPassword}
              >
                {loading ? <CircularProgress size="23px" sx={{ color: "#000" }} /> : "Reset Password"}
              </Button>

              <Button
                sx={{ mt: 3, width: "100%", alignItems: "center", justifyContent: "center" }}
                color="primary"
                href="/login"
                startIcon={<ArrowBackIcon />}
              >
                Back to Login
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid >
  </div>
}

export default ResetPassword;