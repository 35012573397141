import axios from "axios";

const BASE_URL = '/api/checklist/'


export const getChecklist = (checklistID: string) => {
  return axios.get(BASE_URL + `${checklistID}/`)
}

export const updateChecklistInfo = (checklistID: string, title: string, description: string) => {

  const payload = {
    title: title,
    description: description
  }
  return axios.put(BASE_URL + `${checklistID}/`, payload)
}

export const deleteChecklist = (checklistID: string) => {
  return axios.delete(BASE_URL + `${checklistID}/`);
}

export const getChecklistSteps = async (checklistID: string) => {
  return axios.get(`/api/checklist/${checklistID}/`)
}

export const createChecklistStep = async (checklistID: string) => {
  const data = {
    title: "",
    description: ""
  }
  return axios.post(`/api/checklist/${checklistID}/steps/`, data);
}

export const deleteChecklistStep = (checklistID: string, stepID: string) => {
  return axios.delete(`/api/checklist/${checklistID}/steps/${stepID}/`)
}

export const updateChecklistStep = (checklistID: string, stepID: string, data: any) => {
  return axios.put(`/api/checklist/${checklistID}/steps/${stepID}/`, data)
}

export const completeChecklistStep = (checklistID: string, stepID: string) => {
  return axios.put(`/api/checklist/${checklistID}/steps/${stepID}/complete`)
}

export const getChecklistStepInput = (checklistID: string, stepID: string) => {
  return axios.get(`/api/checklist/${checklistID}/steps/${stepID}/`);
}

export const createChecklistStepInput = (checklistID: string, stepID: string) => {

  const payload = {
    title: "",
    type: 0
  }
  return axios.post(`/api/checklist/${checklistID}/steps/${stepID}/inputs/`, payload);
}

export const deleteChecklistStepInput = (checklistID: string, stepID: string, inputID: string) => {
  return axios.delete(`/api/checklist/${checklistID}/steps/${stepID}/inputs/${inputID}`)
}

export const updateChecklistStepInput = (checklistID: string, stepID: string, inputID: string, data: any) => {
  return axios.put(`/api/checklist/${checklistID}/steps/${stepID}/inputs/${inputID}`, data)
}

export const deleteChecklistFileInput = (checklistID: string, stepID: string, inputID: string, filename: string) => {
  return axios.delete(`/api/checklist/${checklistID}/steps/${stepID}/inputs/${inputID}/file/${filename}/`)
}

export const updateChecklistStepInputValue = (checklistID: string, stepID: string, inputID: string, data: any) => {
  return axios.put(`/api/checklist/${checklistID}/steps/${stepID}/inputs/${inputID}/value/`, data)
}

export const uploadFileAnswer = (checklistID: string, stepID: string, inputID: string, files: any, onProgress: () => void) => {
  let formData = new FormData();
  for (var i = 0; i < files.length; i++) {
    formData.append('file', files[i])
  }

  const config = {
    onUploadProgress: onProgress
  }

  return axios.post(`/api/checklist/${checklistID}/steps/${stepID}/inputs/${inputID}/file/`, formData, config);
}


export const uploadStepFile = (checklistID: string, stepID: string, files: any, onProgress: () => void) => {
  let formData = new FormData();
  for (var i = 0; i < files.length; i++) {
    formData.append('files', files[i])
  }

  const config = {
    onUploadProgress: onProgress
  }

  return axios.post(`/api/checklist/${checklistID}/steps/${stepID}/file`, formData, config);
}

export const deleteStepFile = (checklistID: string, stepID: string, fileID: string) => {
  return axios.delete(BASE_URL + `${checklistID}/steps/${stepID}/file/${fileID}`)
}

export const getChecklistStep = (checklistID: string, stepID: string) => {
  return axios.get(BASE_URL + `${checklistID}/steps/${stepID}/`)
}

export const rejectStep = (checklistID: string, stepID: string, description: string, files: any, onProgress: () => void) => {
  let formData = new FormData();
  for (var i = 0; i < files.length; i++) {
    formData.append('files', files[i])
  }
  formData.append('description', description)
  const config = {
    onUploadProgress: onProgress
  }

  return axios.post(BASE_URL + `${checklistID}/steps/${stepID}/reject`, formData, config)
}

export const addCommentToStep = (checklistID: string, stepID: string, description: string, files: any, onProgress: () => void) => {
  let formData = new FormData();
  for (var i = 0; i < files.length; i++) {
    formData.append('files', files[i])
  }
  formData.append('content', description)
  const config = {
    onUploadProgress: onProgress
  }

  return axios.post(BASE_URL + `${checklistID}/steps/${stepID}/comment`, formData, config)
}

export const getCommentsForStep = (checklistID: string, stepID: string) => {
  return axios.get(BASE_URL + `${checklistID}/steps/${stepID}/comment`)
}

export const deleteCommentForStep = (checklistID: string, stepID: string, commentID: string) => {
  return axios.delete(BASE_URL + `${checklistID}/steps/${stepID}/comment/${commentID}`)
}

export const postCommentInlcudeInReport = (checklistID: string, stepID: string, commentID: string, checked: boolean) => {
  const data = {
    checked: checked
  }
  return axios.post(BASE_URL + `${checklistID}/steps/${stepID}/comment/${commentID}/include`, data)
}

export const signChecklistStep = (checklistID: string, stepID: string, signName: string, signatureFile: any) => {
  let formData = new FormData();
  formData.append("signature_file", signatureFile)
  formData.append('signame', signName)

  return axios.post(BASE_URL + `${checklistID}/steps/${stepID}/sign`, formData)
}

export const updateChecklistStepHealth = (checklistID: string, stepID: string, healthStatus: number) => {

  const data = {
    health_status: healthStatus
  }
  return axios.put(BASE_URL + `${checklistID}/steps/${stepID}/health`, data)
}

export const setChecklistStepConclusion = (checklistID: string, stepID: string, conclusion: string) => {

  const data = {
    conclusion: conclusion
  }
  return axios.post(BASE_URL + `${checklistID}/steps/${stepID}/conclusion`, data)
}