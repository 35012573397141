import {
  Paper,
  InputBase,
  IconButton,
  Divider
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';

const SearchBar = ({ value, handleSearchChange, cancelSearch, placeholder }) => {
  return (
    <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search project' }}
        value={value}
        onChange={handleSearchChange}
      />
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

      <IconButton onClick={cancelSearch} type="button" sx={{ p: '10px' }} aria-label="search">
        {value === "" ? <SearchIcon /> : <SearchOffIcon />}
      </IconButton>

    </Paper>
  )
}

export default SearchBar;
