import { Grid, Box, Typography, Select, MenuItem, FormControl, InputLabel, Paper } from "@mui/material";
import { useEffect, useState } from "react";

import IssueTile from "../Issues/IssueTile";
import IssueResolve from "../Issues/IssueResolve";
import SearchBar from "../SearchBar";

import { getSessionIssues } from "../../util/api/session";

const ProjectIssues = ({ projectID, onUpdate }) => {

  const [issues, setIssues] = useState([])
  const [filteredIssues, setFilteredIssues] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [resolveWindowOpen, setResolveWindowOpen] = useState(false)
  const [resolveIssue, setResolveIssue] = useState(undefined);
  const [filter, setFilter] = useState("")

  useEffect(() => {
    handleSearch(searchValue)
  }, [searchValue])

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  }

  const handleSearch = (rawVal) => {
    const value = rawVal.toLowerCase()
    const newIssues = issues.filter((issue) => {

      const unfiltered = (
        (filter === "unresolved" && issue.resolver === null)
        || (filter === "resolved" && issue.resolver !== null)
        || (filter === "")
      )

      return ((value === ""
        || issue?.file?.title?.toLowerCase().includes(value)
        || issue?.file?.category?.title?.toLowerCase().includes(value)
        || issue?.file_approval_session?.approver_email?.toLowerCase().includes(value)
        || issue?.checklist_approval_session?.approver_email?.toLowerCase().includes(value)
        || issue?.session?.title?.toLowerCase().includes(value))
        && unfiltered
      )
    })

    setFilteredIssues(newIssues);
  }

  const cancelSearch = () => {
    setSearchValue('')
  }

  const fetchProjectIssues = () => {
    getSessionIssues(projectID).then((res) => {
      setIssues(res.data)
      setFilteredIssues(res.data)
      setFilter("unresolved")
    })
  }

  const handleResolve = (issue) => {
    setResolveIssue(issue)
    setResolveWindowOpen(true)
  }

  const handleUpdate = () => {
    fetchProjectIssues();
    onUpdate();
  }

  useEffect(() => {
    if (projectID !== undefined)
      fetchProjectIssues();
  }, [projectID])

  useEffect(() => {
    handleSearch(searchValue)

  }, [filter])

  return (
    <div>
      <IssueResolve
        open={resolveWindowOpen}
        onClose={(update) => { setResolveWindowOpen(false); update && handleUpdate() }}
        issue={resolveIssue}
      />
      <Box sx={{
        width: "100%",
        pt: 0,
        pb: 1,
        pl: 0,
        pr: 0,
        display: "flex"
      }}>
        <SearchBar
          value={searchValue}
          handleSearchChange={handleSearchChange}
          cancelSearch={cancelSearch}
          placeholder="Find an issue..."
        />

        <FormControl
          sx={{
            m: 1,
            minWidth: 120,
            border: "none",
            marginLeft: "auto",
            marginRight: "0",

          }}
          size="small"
        >
          <InputLabel id="filter-select-label">Filter</InputLabel>
          <Select
            inputProps={{
              sx: { borderColor: "red" }
            }}
            labelId="filter-select-label"
            id="filter-select"
            value={filter}
            label="Filter"
            onChange={(e) => setFilter(e.target.value)}
            placeholder="Filter"

          >
            <MenuItem value="">
              None
            </MenuItem>
            <MenuItem value={"resolved"}>Resolved</MenuItem>
            <MenuItem value={"unresolved"}>Unresolved</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Grid container justifyContent="center" sx={{
        ml: 0,
        mt: 0,
        width: "100%",
        border: "1px solid",
        background: 'None',
        borderColor: '#212529',
        borderRadius: 1,
      }}>
        {filteredIssues?.map((issue) => {
          return (<IssueTile
            issue={issue}
            key={issue?.id}
            updateSearchFilter={(value) => { setSearchValue(value); handleSearch(value) }}
            onClickResolve={() => handleResolve(issue)}
          />
          )
        })}

        {filteredIssues.length === 0 && <Box sx={{ pt: 10, pb: 10 }}>
          <Typography variant="h4">
            No issues found
          </Typography>
        </Box>}
      </Grid>
    </div >
  )
}

export default ProjectIssues;