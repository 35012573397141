import Dialog from '@mui/material/Dialog';


import ProjectFileOverview from "../ProjectFiles/ProjectFilesOverview";

const FileBrowserPopup = ({ open, onClose, projectID, readOnly }) => {
  return (<Dialog
    fullScreen
    open={open}
    onClose={onClose}
  >
    <ProjectFileOverview readOnly={readOnly} projectID={projectID} onClose={onClose} embedded />
  </Dialog>
  )
}


export const FileBrowserPopupExternal = ({ open, onClose, projectID }) => {
  return (<Dialog
    fullScreen
    open={open}
    onClose={onClose}
  >
    <ProjectFileOverview external readOnly projectID={projectID} onClose={onClose} embedded />
  </Dialog>
  )
}

export default FileBrowserPopup;
