import { useParams } from 'react-router';

import Grid from '@mui/material/Grid';

import ProjectFileOverview from '../../components/ProjectFiles/ProjectFilesOverview';

const ProjectFiles = () => {

  const {projectID} = useParams();

  return (
    <div>
      <Grid container justifyContent="center" spacing={3} padding={0} paddingTop={0}>
        <Grid item xs={12} lg={6} height={"100vh"}>
          <ProjectFileOverview projectID={projectID}/>
        </Grid>
      </Grid>
    </div>
  )
}

export default ProjectFiles;