import {
  Typography,
  List,
  ListItem,
  IconButton,
  Avatar,
  Box,
  Divider,
  TextField,
  Button,
  Tooltip,
  Chip,
  Menu,
  MenuItem,
  ListItemIcon,
  Checkbox,
} from "@mui/material";
import { styled } from '@mui/material/styles';


import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';

import { fToNow } from "../../utils/formatTime";
import { GREY } from "../../theme/palette";
import { useEffect, useState } from "react";
import { addCommentToStep, deleteCommentForStep, getCommentsForStep, postCommentInlcudeInReport } from "../../util/api/checklist";
import { useDispatch, useSelector } from 'react-redux';
import { setError } from "../../actions/notificationActions";

const Input = styled('input')({
  display: 'none',
});

const Comment = ({ comment, onDelete, onUpdate, checklistID, stepID }) => {

  const state = useSelector((state) => state)

  const Header = () => {

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleReportToggle = (e) => {
      postCommentInlcudeInReport(checklistID, stepID, comment?.id, e.target.checked).then(() => {
        onUpdate();
      })

    }

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleClick = (event) => {
      setAnchorEl(event.target);
    };

    return <Box sx={{
      display: "flex",
      flexDirection: "row",
      alignContent: "center",
      alignItems: "center",
      gap: 2,
      width: "100%"
    }}>
      <Avatar aria-label="profile-picture" sx={{ width: 36, height: 36 }} src={comment?.posted_by?.profile_picture_path}>
        {comment?.posted_by?.display_name?.[0]}
      </Avatar>
      <Typography fontWeight={600}>
        {comment?.posted_by?.display_name}
      </Typography>
      <Typography fontWeight={200}>
        {fToNow(comment?.created_at)}
      </Typography>

      {comment?.include_in_report && <Typography variant="subtitle2">
        Included in MRB
      </Typography>}
      <IconButton onClick={handleClick} id="menu-button" sx={{ marginLeft: "auto", mr: 0 }} aria-label="settings" >
        <MoreHorizIcon />
      </IconButton >
      <Menu
        id="comment-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'menu-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}

        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        <MenuItem>
          <Checkbox onClick={handleReportToggle} checked={comment.include_in_report} /> Include in MRB
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => { handleClose(); onDelete(); }} disabled={state?.token?.data?.id !== comment?.posted_by?.id}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
    </Box >
  }

  const Body = () => {
    return (<Box sx={{ pt: 2 }}>
      <Typography variant="body1">
        {comment?.content}
      </Typography>
    </Box>)
  }

  const Attachments = () => {

    const handleClick = (url) => {
      window.open("/data/" + url, '_blank').focus();
    }
    return (
      <Box sx={{
        pt: 2,
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        alignItems: "center",
        gap: 2,
        width: "100%",
        flexWrap: "wrap"
      }}>
        {comment?.attachments?.map((file) => {

          return <Chip clickable label={file.name} onClick={() => { handleClick(file.src) }} />
        })}
      </Box>
    )
  }

  return <ListItem>
    <Box sx={{
      width: "100%"
    }}>
      <Divider sx={{ mt: 0, mb: 2 }} />
      <Header />
      <Body />
      <Attachments />
    </Box>
  </ListItem>
}

const CommentField = ({ text, onTextChange, files, onFileChange, handleSubmit, onDeleteAttachement, disabled }) => {

  const AddFileButton = () => {
    return (

      <Tooltip title="Add attachments" placement="top">
        <label htmlFor={"add-file-button"}>
          <Input
            accept="image/*"
            id={"add-file-button"}
            type="file"
            onChange={
              (e) => {
                onFileChange([...(e.target.files), ...files])
              }
            }
          />
          <Button
            color="primary"
            aria-label="upload picture"
            component="span"
            startIcon={<AttachFileIcon />}

          >
            Add Attachment
          </Button>
        </label>
      </Tooltip>
    )
  }

  const Attachments = () => {
    return (
      <Box sx={{
        pt: 2,
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        alignItems: "center",
        gap: 2,
        width: "100%",
        flexWrap: "wrap"
      }}>
        {files.map((file) => {

          return <Chip onDelete={() => onDeleteAttachement(file)} label={file.name} />
        })}
      </Box>
    )
  }

  return (
    <List>
      <ListItem>
        <Box sx={{
          width: "100%",
          background: GREY[500_12],
          ":hover": {
            background: GREY[500_16],
          },
          ":focus-within": {
            background: GREY[500_24],
          },
          borderRadius: "8px"
        }}>
          <TextField sx={{
            width: "100%",
            p: 1,
          }}
            multiline
            variant="standard"
            minRows={3}
            value={text}
            onChange={(e) => { onTextChange(e.target.value) }}
            InputProps={{
              disableUnderline: true,
            }}
          />
          <Box sx={{ p: 1 }}>
            <Attachments />
          </Box>
          <Box
            sx={{
              width: "100%", display: "flex",
              flexDirection: "row", justifyContent: "right",
              p: 1,
              gap: 1,
            }}
          >
            <AddFileButton />
            <Button onClick={() => handleSubmit()} disabled={text === "" || disabled} variant="outlined" size="small" disableElevation >
              Publish
            </Button>
          </Box>

        </Box>
      </ListItem>
    </List>
  )
}



const ChecklistComments = ({ step, disabled }) => {
  const [value, setValue] = useState("")
  const [files, setFiles] = useState([])
  const [comments, setComments] = useState([])
  const [loading, setLoading] = useState(true)
  const [commentSubmitting, setCommentSubmitting] = useState(true)
  const dispatch = useDispatch();

  const fetchComments = () => {
    getCommentsForStep(step?.parent_id, step?.id).then((result) => {
      setComments(result.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    if (loading) {
      fetchComments();
    }
  }, [loading])

  const handleSubmit = () => {
    setCommentSubmitting(true);
    addCommentToStep(step?.parent_id, step?.id, value, files, () => { }).then(() => {
      setFiles([])
      setValue("")
      setLoading(true)
      setCommentSubmitting(false);
    }).catch((e) => {
      setCommentSubmitting(false);
      dispatch(setError(e.response.data.detail))
    })
  }

  const handleDeleteFile = (f) => {
    const newFiles = files.filter((file) => {
      return file !== f;
    })

    setFiles(newFiles)
  }

  const handleDeleteComment = (comment) => {
    deleteCommentForStep(step?.parent_id, step?.id, comment?.id).then(() => {
      setLoading(true)
    })
  }

  if (comments?.length !== 0) {
    return (
      <div>
        <List sx={{ p: 0, }}>
          <ListItem>
            <Typography marginTop={2} fontWeight={600}>Comments</Typography>
          </ListItem>
        </List>
        {disabled || <CommentField disabled={commentSubmitting} text={value} onTextChange={setValue} files={files} onFileChange={setFiles} handleSubmit={handleSubmit} onDeleteAttachement={(attachment) => handleDeleteFile(attachment)} />}
        <List>
          {comments.map((comment) => {
            return (
              <Comment stepID={step?.id} checklistID={step?.parent_id} key={comment?.id} comment={comment} onDelete={() => handleDeleteComment(comment)} onUpdate={() => fetchComments()} />
            )
          })}
        </List>
      </div>
    )
  }

  return <div>
    <List sx={{ pt: 0, pb: 0 }}>
      <ListItem sx={{ pt: 0, pb: 0 }}>
        <Typography marginTop={2} fontWeight={600}>Comments</Typography>
      </ListItem>
    </List>

    {disabled || <CommentField text={value} onTextChange={setValue} files={files} onFileChange={setFiles} handleSubmit={handleSubmit} onDeleteAttachement={(attachment) => handleDeleteFile(attachment)} />}
    <List sx={{ p: 0 }}>
      <ListItem>No comments available</ListItem>
    </List>
  </div>

}

export default ChecklistComments;