import "./Login.scss";

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import LoginForm from '../../components/LoginForm';
import VmatchLogo from '../../static/images/vmatch.svg';
import LoginBackground from '../../static/images/login_background.png';


const theme = createTheme(
  {
    palette: {
      common: {
        black: '#e2e8f0',
      },
      primary: {
        light: '#e2e8f0',
        main: '#e2e8f0',
        dark: '#e2e8f0',
        contrastText: '#000000',

      },
      secondary: {
        light: '#e2e8f0',
        main: 'rgb(218, 186, 0)',
        dark: 'rgb(203, 173, 0)',
        contrastText: '#000000',
      },
      text: {
        primary: "#e2e8f0"
      }

    },
  }
);

export default function SignIn() {

  return (
    <ThemeProvider theme={theme}>

      <Grid container justifyContent={"center"}>
        <Grid item xs={12} md={6} lg={6} padding={4}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            textAlign: "center",
          }}>
          <LoginForm />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
