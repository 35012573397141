
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Grid,
  IconButton,
  CircularProgress,
  Box,
  DialogContent,
  Dialog,
  DialogTitle,
  Typography,
  TextField,
  FormControl,
  Button,
  Link,
  Checkbox,
  Avatar,
  Paper,
  InputBase,
  ButtonGroup,
  OutlinedInput,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import PrintDisabledIcon from '@mui/icons-material/PrintDisabled';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';


import HttpsIcon from '@mui/icons-material/Https';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonIcon from '@mui/icons-material/Person';
import ContactMailIcon from '@mui/icons-material/ContactMail';

import {
  getAvailableStepApprovers,
  createNewStepApprover,
  addStepToApprovalSession
} from '../../util/api/stepApproval';
import { setError } from '../../actions/notificationActions';


const StepApprovalPopup = ({ open, onClose, stepID, onSignLocally }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(true);
  const [availableApprovers, setAvailableApprovers] = useState([]);

  const fetchApprovers = () => {
    getAvailableStepApprovers(stepID).then((response) => {
      setAvailableApprovers(response.data)
    })
  }

  const createNewApprover = () => {
    createNewStepApprover(stepID, email).then(() => {
      setAvailableApprovers([])
      setEmail("")
      setLoading(true)
      fetchApprovers();
    }).catch((e) => {
      dispatch(setError(e.response?.data?.detail))
    })
  }

  useEffect(() => {
    if (loading && open) {
      fetchApprovers();
    }
  }, [loading, open])

  const handleClose = () => {
    setAvailableApprovers([])
    setEmail("")
    setLoading(true)
    onClose(undefined);
  };

  const completed = () => {
    const emailRegex = /^[a-z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-z0-9]@[a-z0-9][-\.]{0,1}([a-z][-\.]{0,1})*[a-z0-9]\.[a-z0-9]{1,}([\.\-]{0,1}[a-z]){0,}[a-z0-9]{0,}$/
    return email.toLowerCase().match(emailRegex) !== null
  }


  const CloseButton = () => {
    return (
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8
        }}
      >
        <CloseIcon />
      </IconButton>)
  }

  const InfoContainer = ({ icon, text }) => {
    return (
      <Grid container
        sx={{
          mt: "30px"
        }}>
        <Grid item xs={3} sx={{ display: "flex", alignitems: "center", justifyContent: "center" }}>
          <Avatar sx={{ background: "none", padding: "30px" }} >
            {icon}
          </Avatar>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1">
            {text}
          </Typography>
        </Grid>
      </Grid >
    )
  }

  const Approvers = () => {
    return (
      <List
        sx={{ justifyContent: "center", mt: 2, maxHeight: "400px", overflow: "scroll" }}
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Available Approvers
          </ListSubheader>
        }

      >
        {availableApprovers.map((approverSession) => {
          return (
            <ListItemButton onClick={() => { addStepToApprovalSession(approverSession.id, stepID); handleClose(); }}>
              <ListItemIcon>
                <ContactMailIcon />
              </ListItemIcon>
              <ListItemText primary={approverSession.approver_email} />
            </ListItemButton>
          )
        })
        }
      </List >
    )
  }

  return (
    <Dialog
      onClose={handleClose}
      sx={{ zIndex: 199999999 }}
      fullWidth
      maxWidth={"md"}
      open={open}
      PaperProps={{
        elevation: 0,
        variant: "outlined",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
      }}>
      <CloseButton />
      <DialogContent>
        <Grid container spacing={5}>
          <Grid item xs={6} sx={{ mt: "50px", pb: "50px" }}>
            <InfoContainer
              icon={<HowToRegIcon fontSize="large" />}
              text={"Hold and Witness points need to be approved by a third party. This can be done remotely with just a few clicks."}
            />
            <InfoContainer
              icon={<AttachEmailIcon fontSize="large" />}
              text={"An email will be sent containing a link that directly leads to the testplan result to approve, without the need to log in or create an account."}
            />
            <InfoContainer
              icon={<GroupAddIcon fontSize="large" />}
              text={"You can select a previous approver from the list, or invite new people using the 'Add Approver' button."}
            />
          </Grid>
          <Grid item xs={6} sx={{ mt: "50px", pb: "50px", minHeight: "400px", display: "flex", flexDirection: "column" }}>
            <Box>
              <DialogTitle textAlign="center">Who would you like to notify? </DialogTitle>
              <Box display="flex" justifyContent={"center"}>
                <TextField
                  label="Email Address"
                  variant="outlined"
                  size="small"
                  value={email}
                  sx={{ flexGrow: 1 }}

                  onChange={(e) => setEmail(e.target.value)}
                />

                <Button onClick={() => { createNewApprover() }} disabled={!completed()} sx={{ ml: 2 }} variant="outlined">Add <AddIcon /></Button>

              </Box>
              <Box sx={{ p: 1 }} display="flex" justifyContent={"center"}>
                or
              </Box>
              <Box display="flex" justifyContent={"center"}>
                <Button onClick={() => onSignLocally()} sx={{ width: "100%" }} variant="outlined">Sign Locally</Button>
              </Box>
            </Box>
            <Approvers />
          </Grid>
        </Grid>
      </DialogContent>

    </Dialog>
  )
}

export default StepApprovalPopup;