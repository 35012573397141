import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Badge } from '@mui/material';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 0,
    top: 0,
    bottom: 0,
    border: `none`,
    background: "rgba(145, 158, 171, 0.24)",
    border: `2px solid #283036`,
    minWidth: "30px",
    verticalAlign: "center",
  },
}));

export const AntTabs = styled(Tabs)({
  borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
  '& .MuiTabs-indicator': {
    backgroundColor: '#83D100',
  },
});

export const AntTab = styled((props) => <Tab disableRipple {...props}

  label={<div>{props.label}<StyledBadge badgeContent={props.count} showZero><div style={{ width: 30 }}></div> </StyledBadge></div>}
/>)(({ theme }) => ({
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
}));
