import { useDispatch } from "react-redux";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Link,
  Tooltip,
  Button
} from "@mui/material"
import { styled } from '@mui/material/styles';
import { setError } from "../../../actions/notificationActions";
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ClearIcon from '@mui/icons-material/Clear';

import {
  uploadFileAnswer,
  deleteChecklistFileInput
} from '../../../util/api/checklist';

const Input = styled('input')({
  display: 'none',
});

const FotoList = ({ checklistID, stepID, inputID, data, onUpdate, disabled }) => {
  const dispatch = useDispatch();
  return (
    <List>
      {data?.map((name) => {
        return (
          <ListItem key={name} sx={{ mt: 0, pt: 0, pb: 0 }}>
            <ListItemText>
              {!disabled && <IconButton
                disabled={disabled}
                onClick={() => {
                  deleteChecklistFileInput(checklistID, stepID, inputID, name)
                    .then(() => {
                      onUpdate();
                    })
                    .catch((e) => {
                      dispatch(setError("Could not delete image"))
                    })
                }}
              >
                <ClearIcon />
              </IconButton>}
            </ListItemText>
            <ListItemText>
              <Link
                href={"/data/" + name}
                underline="hover"
                target="_blank"
                sx={{
                  fontFamily: "courier"
                }}
              >
                {name}
              </Link >
            </ListItemText>

          </ListItem>)
      })}
    </List>
  )
}

const ChecklistImageInput = ({ checklistID, stepID, input, onUpdate, disabled }) => {
  const dispatch = useDispatch();
  return (
    <div>
      <ListItemText
        primary={
          <FotoList disabled={disabled} onUpdate={onUpdate} checklistID={checklistID} stepID={stepID} inputID={input?.id} data={input?.json_data} />
        }
      />
      <ListItemText
        sx={{
          mr: 0,
          ml: "auto"
        }}
        primary={!disabled &&
          <Tooltip title="Take Picture">
            <label htmlFor={"icon-button-file" + input.id}>
              <Input
                accept="image/*"
                id={"icon-button-file" + input.id}
                type="file"
                disabled={disabled}

                onChange={
                  (e) => {
                    uploadFileAnswer(checklistID, stepID, input?.id, e.target.files, (_progressEvent) => { })
                      .then(() => {
                        onUpdate();
                      }).catch((e) => {
                        dispatch(setError(e.response.data.detail))
                      })
                  }
                }
              />
              <Button
                color="primary"
                aria-label="upload picture"
                component="span"
                disabled={disabled}
                endIcon={<PhotoCamera />}
              >
                Take Picture
              </Button>
            </label>
          </Tooltip>
        }>

      </ListItemText>
    </div>
  )
}

export default ChecklistImageInput;