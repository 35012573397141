import FileIssueResolve from "./FileIssueResolve"
import ChecklistIssueResolve from "./ChecklistIssueResolve"
import ProjectIssueResolve from "./ProjectIssueResolve"

const IssueResolve = ({ open, onClose, issue }) => {
  if (issue?.file_approval_session !== null) {
    return (
      <FileIssueResolve
        issue={issue}
        open={open}
        onClose={onClose}
      />
    )

  } else if (issue?.checklist_approval_session !== null) {
    return (
      <ChecklistIssueResolve
        issue={issue}
        open={open}
        onClose={onClose}
      />
    )
  } else if (issue?.project_approval_session !== null) {
    return (
      <ProjectIssueResolve
        issue={issue}
        open={open}
        onClose={onClose}
      />
    )
  }
  else {
    return (<div></div>)
  }
}

export default IssueResolve;