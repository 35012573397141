import "./checklistStyle.css"
import { useEffect, useState } from 'react';
import { Container, Draggable } from "react-smooth-dnd";
import { arrayMoveMutable } from 'array-move';

import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import ChecklistApprovalPopup from "../Approval/ChecklistApprovalPopup";

import {
  removeChecklistsFromProject,
  orderChecklistsOnProject
} from "../../util/api/session";


import {
  getSessionChecklistOverview,
} from "../../util/api";

import { CardActions, createStyles, IconButton, ListItemAvatar, ListItemSecondaryAction } from '@mui/material';
import AddChecklistDialog from './AddChecklistDialog';
import WarningDialog from "../WarningDialog";
import ChecklistTile from "../Session/ChecklistTile";
import ChecklistPopup from "../Session/ChecklistPopup";
import { useDispatch } from "react-redux";
import { setInfo } from "../../actions/notificationActions";
import PDFViewer from "../Approval/PDFViewer";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: 2,
    },
    paper: {
      padding: 2,
      boxSizing: 'border-box'
    },
    box: {
      flexGrow: 1
    },
    list: {
      height: "50vh",
      overflow: 'auto'
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      fontSize: '1.1rem',
      fontWeight: 700,
      color: '#2196f3',
      marginBottom: 10
    }
  }),
);

const ProjectChecklistSelect = ({ projectID, embedded, onUpdate }) => {

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true)
  const [disabled, setDisabled] = useState(false)
  const [awaitingApproval, setAwaitingApproval] = useState(false);
  const [checked, setChecked] = useState([])
  const [checklists, setChecklists] = useState([])
  const [checklistSelectDialogOpen, setChecklistSelectDialogOpen] = useState(false)
  const [approvalPopupOpen, setApprovalPopupOpen] = useState(false)
  const [warningDialogOpen, setWarningDialogOpen] = useState(false)
  const [activeChecklist, setActiveChecklist] = useState(undefined)
  const [checklistPopupOpen, setChecklistPopupOpen] = useState(false)
  const [session, setSession] = useState(undefined);

  const fetchChecklists = () => {
    setChecked([])
    getSessionChecklistOverview(projectID)
      .then((response) => {
        setSession(response?.data)
        setChecklists(response.data.checklists)
        setDisabled(response.data?.checklist_state !== "OPEN")
        setAwaitingApproval(response.data?.checklist_state !== "APPROVED")
        setLoading(false)
      })
  }

  useEffect(() => {
    if (loading && projectID !== undefined) {
      fetchChecklists()
    }
  }, [loading, projectID])

  const handleCloseApprovalPopup = () => {
    setApprovalPopupOpen(false)
    fetchChecklists();
    onUpdate && onUpdate()
  }

  const handleCloseChecklistSelect = () => {
    setLoading(true)
    setChecklistSelectDialogOpen(false)
  }

  const handleToggleChecklist = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const deleteChecked = () => {
    setWarningDialogOpen(false)
    removeChecklistsFromProject(projectID, checked).then(() => {
      setChecked([]);
      setLoading(true);
    })
  }

  const classes = useStyles();

  const updateList = (updatedIndexArr) => {
    const length = updatedIndexArr.length;

    return updatedIndexArr.map((elem, i) => ({
      ...elem,
      next_scenario: i === length - 1 ? null : updatedIndexArr[i + 1].id
    }))
  }

  const onSortEnd = ({ removedIndex, addedIndex }) => {
    const prevOrder = checklists;

    arrayMoveMutable(prevOrder, removedIndex, addedIndex);
    const updatedOrder = updateList(prevOrder);

    setChecklists(updatedOrder)
    orderChecklistsOnProject(projectID, updatedOrder.map((c) => c.id))
  }

  const ChecklistItem = ({ checklist, i }) => {
    return (
      <ListItem key={`${checklist?.id}-${i}`} button>
        <ListItemAvatar className="drag-handle" >
          <DragHandleIcon />
        </ListItemAvatar>
        <ListItemText
          id={checklist?.id}
          primary={`${i + 1}. ${checklist?.title}`}
          secondary={checklist?.description}
        />
        <ListItemAvatar>
          <IconButton onClick={() => { window.location.href = `/checklist/editor/${checklist?.id}` }}>
            <EditIcon />
          </IconButton>
        </ListItemAvatar>
        <ListItemSecondaryAction>
          <Checkbox
            classes={{ root: 'overview-checkbox-root' }}
            edge="end"
            onChange={handleToggleChecklist(checklist?.id)}
            checked={checked.indexOf(checklist?.id) !== -1}
            inputProps={{ 'aria-labelledby': checklist?.id }}
          />
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  const SelectedChecklists = () => {
    return (
      <List className={classes.list}>
        <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onSortEnd}>
          {checklists.map((checklist, i) => (
            <Draggable key={checklist.id}>
              <ChecklistItem key={checklist.id} i={i} checklist={checklist} />
            </Draggable>
          ))}
        </Container>
      </List>
    )
  }

  const renderChecklists = () => {
    if (checklists.length === 0) {
      return <div className={classes.list}><Typography variant="body" component="p">
        No checklists have been added yet, you can add new checklists from the library.
      </Typography></div>
    }

    return <SelectedChecklists onSortEnd={onSortEnd} useDragHandle />
  }

  const Loading = () => {
    return <Box sx={{
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      height: "100%"
    }}>
      <CircularProgress />
    </Box>
  }

  const ChecklistsOverview = () => {
    if (loading) {
      return (
        <div className={classes.list}>
          <Loading />
        </div>
      )
    }

    return renderChecklists();
  }

  const WarningContent = () => {
    return (
      <Box>
        <Box>
          <Typography variant="body" color="white">
            Deleting these checklists will remove all project specific edits made to the checklists.
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body" color="white" fontWeight={900}>
            This can not be undone!
          </Typography>
        </Box>
      </Box>
    )
  }

  const SelectionArea = () => {
    return (
      <div>
        <AddChecklistDialog open={checklistSelectDialogOpen} onClose={handleCloseChecklistSelect} projectID={projectID} />
        <WarningDialog
          open={warningDialogOpen}
          onCancel={() => { setWarningDialogOpen(false) }}
          onConfirm={deleteChecked}
          title={"Are you sure?"}
          content={<WarningContent />}
        />
        <CardContent>
          <ChecklistsOverview />
        </CardContent>
        <CardActions>
          <Grid container>
            <Button
              sx={{ marginTop: 0, mr: 2 }}
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setChecklistSelectDialogOpen(true)}>
              Add from Library
            </Button>

            <Button
              sx={{ marginTop: 0, }}
              color="secondary"
              startIcon={<DeleteIcon />}
              disabled={checked.length === 0}
              onClick={() => setWarningDialogOpen(true)}>
              Remove
            </Button>
          </Grid>
        </CardActions>
      </div>
    )
  }

  const handleChecklistSelect = (c) => {
    setActiveChecklist(c)
    setChecklistPopupOpen(true)
    dispatch(setInfo("You are viewing the checklist in read-only mode"))
  }

  const handleChecklistPopupClose = () => {
    setChecklistPopupOpen(false)
  }

  const ChecklistView = () => {
    return (
      <Grid container sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 3
      }}>
        {awaitingApproval && <Typography variant="h5">Testplan is awaiting approval</Typography>}
        {!awaitingApproval && <Typography variant="h5">{session?.is_health_check_project ? "Healthcheck has started" : "Testplan is approved"}</Typography>}

        {!awaitingApproval && <Button variant="outlined" sx={{ fontSize: "30px" }} href={`/session/${projectID}`}  >Join Session</Button>}
        {awaitingApproval && <PDFViewer
          pdfBoxSx={{ mt: 0, mb: 0, p: 0 }}
          title={"Test Plan"}
          filename={`/api/session/${projectID}/pdfitp`}
        />
        }
      </Grid>
    )
  }

  if (disabled && !embedded) {
    return <div>
      <ChecklistPopup disabled={awaitingApproval} open={checklistPopupOpen} checklist={activeChecklist} onClose={handleChecklistPopupClose} />
      <ChecklistView />
    </div>
  }

  // return smaller view for embedded mode
  if (embedded) {
    return <SelectionArea />
  } else {
    return (
      <Box style={{ position: "relative" }} sx={{
        pl: {
          xs: 1,
          lg: 0
        },
        pr: {
          xs: 1,
          lg: 0
        }
      }}>
        <ChecklistApprovalPopup isHealthCheck={session?.is_health_check_project} open={approvalPopupOpen} projectID={projectID} onClose={handleCloseApprovalPopup} />
        <Card sx={{ borderRadius: 0.5, }}>
          <CardHeader
            title="Testplan Selection"
            subheader={"Here you select and order the checklists that need to be completed for this project."}
            subheaderTypographyProps={{
              color: "#dddddd",
              variant: "body2",
              fontSize: "12px"
            }}
            action={!embedded &&
              <Button disabled={checklists.length === 0} variant="outlined" onClick={() => setApprovalPopupOpen(true)}>
                {session?.is_health_check_project ? "Begin Health Check" : "Request approval"}
              </Button>
            }

          />
          <Divider sx={{ margin: 2 }} />
          <SelectionArea />
        </Card>
      </Box>)
  }



}

export default ProjectChecklistSelect;
