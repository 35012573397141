import { useState, useEffect } from 'react';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import StepContent from '@mui/material/StepContent';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ClearIcon from '@mui/icons-material/Clear';

import { updateChecklistStepInputValue, uploadFileAnswer, deleteChecklistFileInput, completeChecklistStep } from '../../util/api/checklist';

import "./style.scss"

const Input = styled('input')({
  display: 'none',
});

export default function SessionChecklist({ checklist, onUpdate, onComplete, locked }) {
  const [activeStep, setActiveStep] = useState(0);
  const [currentChecklistID, setCurrentChecklistID] = useState(undefined);
  const [isEditing, setIsEditing] = useState(false);

  const [answerState, setAnswerState] = useState({})

  useEffect(() => {
    if (currentChecklistID !== checklist.id) {
      const stepToSet = caluclateCurrentStep();
      setActiveStep(stepToSet);
      setCurrentChecklistID(checklist.id);
    }

    if (!isEditing) {
      const newState = {}
      checklist.steps.forEach((step) => {
        const answers = {}
        step.inputs.forEach((input) => { answers[input.id] = input.value })
        newState[step.id] = answers
      })

      setAnswerState((prevState) => ({ ...prevState, ...newState }))
    }
  }, [checklist])


  // loop through current checklist steps to determine  which step we are at
  const caluclateCurrentStep = () => {
    var stepToSet = 0;
    let checklistComplete = checklist?.steps?.every((step, index) => {
      stepToSet = index;
      const complete = step?.inputs?.length === 0 || step?.completed_at !== null

      return complete
    })

    if (checklistComplete) {
      stepToSet = checklist?.steps?.length
    }



    return stepToSet
  }

  const handleNext = () => {
    // update backend
    completeChecklistStep(checklist.id, checklist?.steps[activeStep]?.id).then((response) => {
      if (response.data.checklist_completed) {
        onComplete()
      }
    })

    if (checklist?.steps[activeStep]?.action !== "hold" || checklist?.steps[activeStep]?.approved) {
      if ((activeStep + 1) <= checklist?.steps.length)
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const FotoList = ({ stepID, inputID, data }) => {
    return (
      <div>
        {data.map((name) => {
          return (
            <ListItem sx={{ mt: 0, pt: 0, pb: 0 }}>
              <ListItemText>
                {locked || <IconButton
                  disabled={locked}
                  onClick={() => {
                    deleteChecklistFileInput(checklist.id, stepID, inputID, name)
                  }}
                >
                  <ClearIcon />
                </IconButton>}
              </ListItemText>
              <ListItemText>
                <Link
                  href={"/data/" + name}
                  underline="hover"
                  target="_blank"
                  sx={{
                    fontFamily: "courier"
                  }}
                >
                  {name}
                </Link >
              </ListItemText>

            </ListItem>)
        })}
      </div>
    )
  }

  return (
    <Paper
      elevation={0}
      style={
        {
          width: "100%",
          padding: "20px"
        }
      }
    >
      <Typography variant='h3'>
        {checklist?.title}
      </Typography>
      <Typography>
        {checklist?.description}
      </Typography>
      <Divider style={{ marginTop: 10, marginBottom: 20 }} />
      <Stepper activeStep={activeStep} orientation="vertical" style={{
        maxHeight: "50vh",
        overflow: "scroll",
        scrollbarWidth: 0,
        "::WebkitScrollbar": {
          "display": "none"
        }
      }}>
        {checklist?.steps?.map((item) => {

          const approved = item.approver !== null;
          return (
            <Step key={item.id}>
              <StepLabel>
                <div>
                  {item.title}
                  <Typography variant={"subtitle2"}>
                    {item.action && item.action?.charAt(0).toUpperCase() + item.action?.slice(1)}
                    {item.approver && ` - Approved by ${item.approver?.email}`}
                  </Typography>
                  <Typography variant={"subtitle2"}>

                  </Typography>
                </div>
              </StepLabel>
              <StepContent>

                <Typography>{item.description}</Typography>
                {item.files.length !== 0 ?
                  <Typography marginTop={2} fontWeight={600}>Files</Typography>
                  : ""}
                {item.files?.length !== 0 && <List>
                  {item.files.map((file) => {
                    return (
                      <ListItem>                    <Link
                        href={"/data/" + file.file_location}
                        underline="hover"
                        target="_blank"
                      >
                        {file.title}
                      </Link>
                      </ListItem>
                    )
                  })}
                </List>}
                <List dense={false}>

                  {item.inputs.map((input) => {
                    return (
                      <div>
                        <Divider component="li" />
                        <ListItem>
                          <ListItemText
                            primary={input.title}
                            secondary={input.required ? "Required" : ""}
                          />

                          {input?.input_type === 2 && input?.json_data !== null && <ListItemText
                            primary={
                              <FotoList stepID={item.id} inputID={input.id} data={input?.json_data} />
                            }
                          />}


                          {input.input_type === 0 ? <Checkbox
                            classes={{ root: 'custom-checkbox-root' }}
                            checked={input.value === "True"}
                            disabled={approved || locked}
                            onChange={(e) => {
                              const payload = { "value": e.target.checked }
                              updateChecklistStepInputValue(checklist?.id, item?.id, input?.id, payload)
                                .then(() => {
                                  onUpdate();
                                })
                            }}
                          /> : ""}

                          {input.input_type === 2 &&
                            (<Tooltip title="Take Picture">
                              <label htmlFor={"icon-button-file" + input.id}>

                                <Input
                                  accept="image/*"
                                  id={"icon-button-file" + input.id}
                                  type="file"
                                  disabled={approved || locked}

                                  onChange={
                                    (e) => {
                                      uploadFileAnswer(checklist?.id, item?.id, input?.id, e.target.files, (progressEvent) => {
                                        // setProgress(Math.round( (progressEvent.loaded * 100) / progressEvent.total ))
                                      })
                                        .then(() => {
                                          onUpdate();
                                        })
                                    }
                                  }
                                />
                                <IconButton color="primary" aria-label="upload picture" component="span" disabled={approved || locked}>
                                  <PhotoCamera />
                                </IconButton>
                              </label>
                            </Tooltip>
                            )}

                          {input.input_type === 1 ?
                            (<TextField
                              label="input"
                              multiline
                              disabled={approved || locked}
                              value={answerState[item.id]?.[input.id]}
                              onFocus={() => { setIsEditing(true) }}
                              onChange={(e) => {
                                const newState = {
                                  [item.id]: {
                                    [input.id]: e.target.value
                                  }
                                }
                                setAnswerState((prevState) => ({ ...prevState, ...newState }))
                              }}
                              onBlur={(e) => {
                                setIsEditing(false);
                                const payload = { "value": e.target.value }
                                updateChecklistStepInputValue(checklist?.id, item?.id, input?.id, payload)
                                  .then(() => {
                                    onUpdate();
                                  })
                              }}
                              maxRows={10}
                              sx={{
                                width: {
                                  xs: "50%"
                                }
                              }}
                            />) : ""}
                        </ListItem>
                      </div>
                    )
                  })}
                </List>
              </StepContent>
            </Step>
          )
        })}
      </Stepper>
      <Box sx={{ mb: 2, marginTop: 2 }}>
        <div>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleNext}
            sx={{ mt: 1, mr: 1 }}
            disabled={activeStep >= checklist?.steps.length || (checklist?.steps[activeStep]?.action === "hold" && !checklist?.steps[activeStep]?.approver)}
          >
            {activeStep === checklist?.steps?.length - 1 ? 'Finish' : 'Continue'}
          </Button>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mt: 1, mr: 1 }}
          >
            Back
          </Button>
        </div>
      </Box>
    </Paper>
  )
}
