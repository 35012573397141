import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';


import { getSessionChecklistOverview } from '../../util/api';
import { updateSessionInfo } from '../../util/api/session';


const ProjectInfoSettings = ({ projectID }) => {

  const [clientName, setClientName] = useState("")
  const [machineNumber, setMachineNumber] = useState("")
  const [machineModel, setMachineModel] = useState("")
  const [serviceEngineer, setServiceEngineer] = useState("")
  const [projectDate, setProjectDate] = useState("")

  const fetchProjectInfo = () => {
    getSessionChecklistOverview(projectID).then((res) => {
      setClientName(res.data.client_name)
      setMachineNumber(res.data.machine_number)
      setMachineModel(res.data.machine_model)
      setServiceEngineer(res.data.service_engineer)
      setProjectDate(res.data.date)
    })
  }

  useEffect(() => {
    if (projectID !== null) {
      fetchProjectInfo();
    }
  }, [projectID])

  const handleUpdate = () => {
    const payload = {
      client_name: clientName,
      machine_number: machineNumber,
      machine_model: machineModel,
      service_engineer: serviceEngineer,
      date: projectDate,
    }

    updateSessionInfo(projectID, payload).then(() => {
      window.location.reload()
    })
  }

  return (
    <Card sx={{ borderRadius: 0.5, marginTop: 0 }}>

      <CardHeader
        title="Project Info"
        subheader={"Here you edit the information of your project."}
        subheaderTypographyProps={{
          color: "#dddddd",
          variant: "body2",
          fontSize: "12px"
        }}

        action={
          <Button variant="outlined" onClick={handleUpdate}>
            Update
          </Button>
        }
      />
      <Divider sx={{ margin: 2, marginBottom: 0 }} />
      <CardContent sx={{ alignItems: "left", display: "flex", flexDirection: "column" }}>
        <TextField sx={{ mt: 1, mb: 1 }} variant="standard" value={clientName} label="Client Name" onChange={(e) => setClientName(e.target.value)} />
        <TextField sx={{ mt: 1, mb: 1 }} variant="standard" value={machineModel} label="Machine Model" onChange={(e) => setMachineModel(e.target.value)} />
        <TextField sx={{ mt: 1, mb: 1 }} variant="standard" value={machineNumber} label="Machine Number" onChange={(e) => setMachineNumber(e.target.value)} />
        <TextField sx={{ mt: 1, mb: 1 }} variant="standard" value={serviceEngineer} label="Client" onChange={(e) => setServiceEngineer(e.target.value)} />
        <TextField sx={{ mt: 1, mb: 1 }} type="date" variant="standard" value={projectDate} label="Location" onChange={(e) => setProjectDate(e.target.value)} />
      </CardContent>

    </Card>
  )
}

export default ProjectInfoSettings;