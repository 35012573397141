import { Typography, Link, List, ListItem } from "@mui/material";

const ChecklistFiles = ({ step }) => {

  if (step?.files?.length !== 0) {
    return (
      <div>
        <List sx={{ p: 0, }}>
          <ListItem>
            <Typography marginTop={2} fontWeight={600}>Files</Typography>
          </ListItem>
        </List>
        <List>
          {step?.files.map((file) => {
            return (
              <ListItem key={file?.id}>
                <Link
                  href={"/data/" + file.file_location}
                  underline="hover"
                  target="_blank"
                >
                  {file.title}
                </Link>
              </ListItem>
            )
          })}
        </List>
      </div>
    )
  }

  return <div>
    <List sx={{ p: 0, }}>
      <ListItem>
        <Typography marginTop={2} fontWeight={600}>Files</Typography>
      </ListItem>
    </List>
    <List>
      <ListItem>No files available</ListItem>
    </List>
  </div>

}

export default ChecklistFiles;