import { TextField } from "@mui/material";
import { withStyles } from "@mui/styles";

export const StyledTextfield = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#212529',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#212529',
      },
      '&:hover fieldset': {
        borderColor: '#212529',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#212529',
      },
    },
    width: "100%",
  },
})(TextField);