import "./style.scss";
import { useDispatch } from 'react-redux';
import {
  useParams
} from "react-router-dom";
import { externalLogIn } from '../../actions/tokenActions'

import React from "react";
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import UseAnimations from 'react-useanimations';
import lock from 'react-useanimations/lib/lock'

import SessionJoinForm from '../../components/SessionJoin/SessionJoinForm';

const theme = createTheme(
  {
    palette: {
      common: {
        black: '#e2e8f0',
      },
      primary: {
        light: '#e2e8f0',
        main: '#e2e8f0',
        dark: '#e2e8f0',
        contrastText: '#000000',

      },
      secondary: {
        light: '#e2e8f0',
        main: 'rgb(218, 186, 0)',
        dark: 'rgb(203, 173, 0)',
        contrastText: '#000000',
      },
      text: {
        primary: "#e2e8f0"
      }

    },
  }
);

export default function SessionJoin() {
  const [checked, setChecked] = React.useState(false);

  const dispatch = useDispatch()
  const { sessionID } = useParams();

  const handleSubmit = (event, passcode) => {
    event.preventDefault();
    dispatch(externalLogIn(sessionID, passcode));
    // setChecked(true);
  };


  function CenterLock() {
    return (
      <div
        className={checked ? "lockAnimation" : ""}
        style={{
          display: "flex",
          color: "white",
          background: "#83D100",
          borderRadius: "100%",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          alignContent: "center",
          border: "3px solid #252525",
          position: "relative",
          left: "calc(50% - 105px)",
          padding: 10,
        }}>
        <UseAnimations
          reverse={checked}
          strokeColor={"#252525"}
          onClick={(e) => { e.preventDefault() }}
          size={200}
          color="white"
          animation={lock}
        />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>

      <Grid container>
        <Grid item xs={12} md={6} lg={6} className={checked ? "blobAnimation" : ""}
          sx={
            {
              height: {
                xs: "30vh",
                md: "100vh",
                lg: "100vh"
              },
              background: {
                xs: "radial-gradient(circle at 50% -200%, rgba(131,209,0,1) 90%, rgba(82,82,82,1) 90.1%)",
                md: "radial-gradient(circle at left -150% top 50%, rgba(131,209,0,1) 90%, rgba(82,82,82,1) 90.1%)",
                lg: "radial-gradient(circle at left -65% top 50%, rgba(131,209,0,1) 90%, rgba(82,82,82,1) 90.1%)",
              },
            }
          }
        >
          <div style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            alignItems: "center",
            alignContent: "center",
            textAlign: "center",
            justifyContent: "center",
          }}>
            <CenterLock />
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={6} padding={4}
          className={checked ? "formAnimation" : ""}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            textAlign: "center",
            background: "linear-gradient(159.02deg, #212529 14.25%, # 56.45%, #81D100 86.14%) transparent"
          }}>
          <SessionJoinForm handleSubmit={handleSubmit} />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
