import React from "react";
import { Navigate, BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector } from 'react-redux';

import Portal from "./pages/Portal/";
import ChecklistOverview from "./pages/ChecklistOverview";
import ChecklistEditor from "./pages/ChecklistEditor";
import Session from "./pages/Session/index";
import Login from "./pages/Login";
import SessionJoin from "./pages/SessionJoin";
import ProjectOverview from "./pages/ProjectOverview";
import ProjectSettings from "./pages/ProjectSettings";
import ProjectFiles from "./pages/ProjectFiles";
import Logout from "./pages/Logout";
import FileApproval from "./pages/Approval/FileApproval";
import ChecklistApproval from "./pages/Approval/ChecklistApproval";
import ProjectApproval from "./pages/Approval/ProjectApproval";
import StepApproval from "./pages/Approval/StepApproval";
import AccountPage from "./pages/AccountSettings";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import AccountCreationPage from "./pages/CreateAccount";
import RegisterSuccessPage from "./pages/RegisterSuccess";
import EmailConfirmPage from "./pages/ConfirmEmail";

const AppRouter: React.FC = () => {
  // TODO: routes should be protected in the future
  const state = useSelector((state: any) => state)

  const hasToken = () => state.token !== null && state.token !== undefined && state.token.raw !== undefined;
  const external = state?.token?.data?.type === "external"

  return (
    <BrowserRouter>
      {hasToken() ?
        <Routes>
          {!external && <Route path="/:path" element={<Portal />} />}
          {!external && <Route path="/checklist/" element={<ChecklistOverview />} />}
          {!external && <Route path="/checklist/editor/:checklistID" element={<ChecklistEditor />} />}
          <Route path="/session/:sessionID" element={<Session />} />
          {!external && <Route path="/projectSettings/:projectID/:path" element={<ProjectSettings />} />}
          <Route path="/projectFiles/:projectID" element={<ProjectFiles />} />
          {!external && <Route path="*" element={<Portal />} />}
          {external && <Route path="*" element={<Logout />} />}
          <Route path="/approval/files/:sessionID" element={<FileApproval />} />
          <Route path="/approval/testplan/:sessionID" element={<ChecklistApproval />} />
          <Route path="/approval/project/:sessionID" element={<ProjectApproval />} />
          <Route path="/approval/step/:sessionID" element={<StepApproval />} />
          <Route path="/account" element={<AccountPage />} />


          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/resetPassword/:resetKey" element={<ResetPassword />} />
          <Route path="/registerSuccess" element={<RegisterSuccessPage />} />
          <Route path="/register" element={<AccountCreationPage />} />
        </Routes>
        :
        <Routes>
          <Route path="/session/:sessionID" element={<SessionJoin />} />
          <Route path="/approval/files/:sessionID" element={<FileApproval />} />
          <Route path="/approval/testplan/:sessionID" element={<ChecklistApproval />} />
          <Route path="/approval/project/:sessionID" element={<ProjectApproval />} />
          <Route path="/approval/step/:sessionID" element={<StepApproval />} />

          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/resetPassword/:resetKey" element={<ResetPassword />} />
          <Route path="/registerSuccess" element={<RegisterSuccessPage />} />
          <Route path="/register" element={<AccountCreationPage />} />
          <Route path="/confirmEmail" element={<EmailConfirmPage />} />


          <Route path="*" element={<Login />} />
        </Routes>
      }
    </BrowserRouter>
  );
};

export default AppRouter;
