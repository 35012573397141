import { useState } from "react"
import { useDispatch } from "react-redux";
import { setInfo, setError } from "../../../actions/notificationActions";

import {
  updateChecklistStepInputValue,
} from '../../../util/api/checklist';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import dayjs, { Dayjs } from 'dayjs';

const CheckfieldDateInput = ({ input, checklistID, stepID, disabled, onUpdate }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(dayjs(input?.value));
  const [isEditing, setIsEditing] = useState(false);

  const handleChange = (newValue) => {
    const payload = { "value": newValue }
    updateChecklistStepInputValue(checklistID, stepID, input?.id, payload)
      .then(() => {
        onUpdate();
      }).catch((e) => {
        dispatch(setError("Could not update value"))
      })
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>

      <DatePicker
        label="date"
        disabled={disabled}
        value={value}
        onChange={(newValue) => { setValue(newValue); handleChange(newValue); }}
        onFocus={() => { setIsEditing(true) }}
        sx={{ width: "50%" }}

      />

    </LocalizationProvider>

  )
}

export default CheckfieldDateInput;