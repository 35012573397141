import { TextField } from "@mui/material"
import { useState } from "react"
import { useDispatch } from "react-redux";
import { setInfo, setError } from "../../../actions/notificationActions";
import {
  updateChecklistStepInputValue,
} from '../../../util/api/checklist';

const ChecklistTextfieldInput = ({ input, checklistID, stepID, disabled, onUpdate }) => {
  const dispatch = useDispatch();
  const [text, setText] = useState(input?.value);
  const [isEditing, setIsEditing] = useState(false);

  return (
    <TextField
      label="input"
      multiline
      disabled={disabled}
      value={text}
      onFocus={() => { setIsEditing(true) }}
      onChange={(e) => {
        setText(e.target.value)
      }}
      onBlur={(e) => {
        setIsEditing(false);
        const payload = { "value": e.target.value }
        updateChecklistStepInputValue(checklistID, stepID, input?.id, payload)
          .then(() => {
            onUpdate();
          }).catch((e) => {
            dispatch(setError("Could not update value"))
          })
      }}
      maxRows={10}
      sx={{ width: { xs: "50%" } }}
    />)
}

export default ChecklistTextfieldInput;