import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSuccess } from '../../actions/notificationActions';
import {
  Dialog,
  Grid,
  IconButton,
  CardHeader,
  Box,
  CardContent,
  Slide,
  DialogContent
} from "@mui/material"

import CloseIcon from '@mui/icons-material/Close';
import ChecklistStep from "../ChecklistStep/ChecklistStep";
import Subheader from '../Subheader';
import { getChecklist } from '../../util/api/checklist';
import Scrollbar from '../Scrollbar';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChecklistPopup = ({ checklist, open, onClose, disabled, onSignLocally }) => {
  const dispatch = useDispatch();

  const [checklistComplete, setChecklistComplete] = React.useState(checklist?.completed);

  const checkChecklistComplete = () => {
    getChecklist(checklist?.id).then((res) => {
      setChecklistComplete(res.data.completed)
    })
  }

  const CloseButton = () => {
    return (
      <IconButton
        onClick={onClose}
      >
        <CloseIcon sx={{ color: "white" }} />
      </IconButton>
    )
  }

  useEffect(() => {
    if (checklist?.completed && !disabled) {
      dispatch(setSuccess("Checklist Done! You can now close this window."))
    }
  }, [checklist])

  useEffect(() => {
    if (checklistComplete && !disabled) {
      dispatch(setSuccess("Checklist Done! You can now close this window."))
    }
  }, [checklistComplete])

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{
        backgroundColour: "#525252 !important",
        maxHeight: "100vh"
      }}
      PaperProps={{
        elevation: 0,
        variant: "outlined",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
        backgroundColour: "#525252 !important",
        maxHeight: "100vh"
      }}
    >
      <Box sx={{
        background: "#525252",
        backgroundColour: "#525252 !important",
        maxHeight: "calc(100vh - 60px)",
      }}>

        <Subheader leftAction={<CloseButton />} title={checklist?.title} />
        <Scrollbar sx={{ maxHeight: "100vh", background: "#525252" }}>
          <Grid container sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#525252",
            maxHeight: "calc(100vh - 55px)",
          }}>

            <Grid xs={12} md={12} lg={10} xl={8} container justifyContent="center" sx={{

              display: "flex",
              p: 0,
              pt: 0,
              background: "#525252"
            }}>

              {checklist?.steps?.map((step) => {
                return <ChecklistStep onSignLocally={() => onSignLocally()} disabled={disabled} onUpdate={checkChecklistComplete} stepID={step.id} checklistID={checklist.id} />
              })}

            </Grid>
          </Grid>
        </Scrollbar>
      </Box>
    </Dialog >
  )
}

export default ChecklistPopup;