import { Grid, Typography } from "@mui/material"

import VAnimation from "./VAnimation"

const Done = () => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={10} md={6} lg={4} >

        <VAnimation />
        <Typography variant="h4" textAlign="center">
          All done, you can now close this webpage!
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Done