import { useState } from 'react';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';

import VpnKeyIcon from '@mui/icons-material/VpnKey';



const SessionJoinForm = ({ handleSubmit }) => {

  const [passcode, setPasscode] = useState("");

  return (
    <div>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box component="form" onSubmit={(e) => handleSubmit(e, passcode)} noValidate sx={{ mt: 1 }}>
          <Typography fontWeight={100} color="text.primary" variant="h5" style={{ marginBottom: 30 }}>
            You're trying to enter a private session, did you receive an access key?
          </Typography>
          <TextField
            color="primary"
            margin="normal"
            fullWidth
            required
            value={passcode}
            onChange={(e) => setPasscode(e.target.value)}
            name="access_key"
            label="Access Key"
            type="text"
            id="access_key"
            InputLabelProps={{
              style: {
                color: "#e2e8f0",
                borderColor: "#e2e8f0"
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" color="text.primary">
                  <VpnKeyIcon style={{ color: "#e2e8f0" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            onClick={(e) => handleSubmit(e, passcode)}
            variant="outlined">
            Enter
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default SessionJoinForm;