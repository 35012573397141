import axios from "axios";

const BASE_URL = '/api/approval/project/'

export const createProjectApprovalSession = (sessionID: string, email: String) => {
  const payload = {
    email: email,
    session_id: sessionID
  }

  return axios.post(BASE_URL, payload)
}

export const getProjectApprovalSession = (approvalSessionID: string, key: string) => {
  const uninterceptedAxiosInstance = axios.create();
  let config = {
    headers: {
      'key': key
    }
  }

  return uninterceptedAxiosInstance.get(BASE_URL + approvalSessionID, config)
}


export const approveProjectSession = (approvalSessionID: string, key: string, signName: string, signatureFile: any) => {
  const uninterceptedAxiosInstance = axios.create();
  let config = {
    headers: {
      'key': key
    }
  }

  let formData = new FormData();
  formData.append("signature_file", signatureFile)
  formData.append('signame', signName)

  return uninterceptedAxiosInstance.post(BASE_URL + approvalSessionID + "/approve", formData, config)
}

export const postProjectIssue = (approvalSessionID: string, key: string, value: string) => {
  const uninterceptedAxiosInstance = axios.create();

  let config = {
    headers: {
      'key': key
    }
  }

  let payload = {
    content: value
  }
  return uninterceptedAxiosInstance.post(
    BASE_URL + approvalSessionID + "/issue",
    payload,
    config
  )
}

export const getPendingProjectApprovalSessions = (sessionID: string) => {
  return axios.get(BASE_URL + `/pending/${sessionID}`);
}

export const deleteProjectApprovalSession = (approvalSessionID: string) => {
  return axios.delete(BASE_URL + `/${approvalSessionID}`);
}

export const sendProjectApprovalReminder = (approvalSessionID: string) => {
  return axios.post(BASE_URL + `/${approvalSessionID}/reminder`)
}