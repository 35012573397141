import { useParams } from 'react-router';
import { useState, useEffect } from 'react';

import { Grid, Badge } from '@mui/material';

import FolderIcon from '@mui/icons-material/Folder';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import HowToRegIcon from '@mui/icons-material/HowToReg';


import { getSessionChecklistOverview } from '../../util/api';

import TitleBar from '../../components/TitleBar';
import PageSelector from '../../components/PageSelector';
import ProjectFileSettings from '../../components/ProjectSettings/ProjectFileSettings';
import ProjectChecklistSelect from '../../components/ProjectSettings/ProjectChecklistSelect';
import ProjectInfoSettings from '../../components/ProjectSettings/ProjectInfoSettings';
import ProjectIssues from '../../components/ProjectSettings/ProjectIssues';
import ProjectApprovals from '../../components/ProjectSettings/ProjectApprovals';


const ProjectSettings = () => {
  const { projectID, path } = useParams();

  const [active, setActive] = useState(path)
  const [project, setProject] = useState(undefined)

  const fetchProjectInfo = () => {
    getSessionChecklistOverview(projectID).then((res) => {
      setProject(res.data)
    })
  }

  useEffect(() => {
    if (path !== undefined)
      fetchProjectInfo();
  }, [projectID])

  useEffect(() => {
    window.history.pushState({}, null, "/projectSettings/" + projectID + "/" + active)
  }, [active])

  const paths = {
    "": "",
    "files": <ProjectFileSettings projectID={projectID} />,
    "testplans": <ProjectChecklistSelect projectID={projectID} />,
    "punchlist": <ProjectIssues projectID={projectID} onUpdate={() => { fetchProjectInfo() }} />,
    "settings": <ProjectInfoSettings projectID={projectID} />,
    "approvals": <ProjectApprovals projectID={projectID} />
  }

  const pages = [
    {
      title: "Files",
      value: "files",
      icon: <FolderIcon />
    },
    {
      title: "Test Plans",
      value: "testplans",
      icon: <ListAltIcon />
    },
    {
      title: "Punchlist",
      value: "punchlist",
      icon: <Badge color="error" badgeContent={project?.issues?.filter((issue) => {
        return issue.resolver === null
      }).length}>
        <AssignmentLateIcon />
      </Badge>
    },
    {
      title: "Pending Approvals",
      value: "approvals",
      icon: <Badge color="error" badgeContent={project?.pending_approval_count}>
        <HowToRegIcon />
      </Badge>
    },
    {
      title: "Settings",
      value: "settings",
      icon: <SettingsIcon />
    },
  ]

  return (
    <div>
      <TitleBar title={`${project ? project.client_name + " " + project.machine_model + " " + project.machine_number : ""} `} />
      <PageSelector pages={pages} page={active} onSelect={(value) => { setActive(value) }} />
      <Grid container sx={{
        paddingTop: 1,
        justifyContent: "center"
      }}>
        <Grid xs={12} md={10} lg={6} style={{
          maxHeight: "calc(100vh - 160px)",
          overflow: "scroll",
          marginLeft: 0,
          marginTop: 0,
          width: "100%",
        }}>
          {paths[active] ? paths[active] : <div>Not found</div>}
        </Grid>
      </Grid>
    </div>
  )
}

export default ProjectSettings;
