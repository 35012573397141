import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  IconButton,
  TextField,
  Box,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  ImageList,
  ImageListItem,
} from "@mui/material"

import { styled } from '@mui/material/styles';

import { useState } from 'react';
import ImageView from '../ImageView';
import { rejectStep } from '../../util/api/checklist';

const Input = styled('input')({
  display: 'none',
});

const StepRejectionPanel = ({ open, onClose, checklistID, stepID }) => {

  const [text, setText] = useState("")
  const [files, setFiles] = useState([])
  const [imageDisplayOpen, setImageDisplayOpen] = useState(false)
  const [imageURL, setImageURL] = useState("")

  const handleClose = () => {
    setFiles([])
    onClose();
  }

  const handleImageClick = (image) => {
    setImageURL(URL.createObjectURL(image))
    setImageDisplayOpen(true)
  }

  const handleDeleteImage = (f) => {
    const newFiles = files.filter((file) => {
      return file !== f;
    })

    setFiles(newFiles)
  }

  const handleSubmit = () => {
    rejectStep(checklistID, stepID, text, files, () => { }).then(() => {
      handleClose();
    })
  }

  const CloseButton = () => {
    return (
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8
        }}
      >
        <CloseIcon />
      </IconButton>)
  }

  const AddFileButton = () => {
    return (
      <Box sx={{
        ml: 0,
        mr: "auto"
      }}>
        <Tooltip title="Adding photos can help explain the issue" placement="top">
          <label htmlFor={"add-file-button"}>
            <Input
              accept="image/*"
              id={"add-file-button"}
              type="file"

              onChange={
                (e) => {
                  setFiles([...(e.target.files), ...files])
                }
              }
            />
            <Button
              color="primary"
              aria-label="upload picture"
              component="span"
              startIcon={<AddIcon />}

            >
              Add Photos
            </Button>
          </label>
        </Tooltip>
      </Box>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={"md"}
      PaperProps={{
        elevation: 0,
        variant: "outlined",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <ImageView open={imageDisplayOpen} onClose={() => setImageDisplayOpen(false)} imageURL={imageURL} />
      <DialogContent>
        <CloseButton />
        <CardHeader
          title="Add step to punchlist"
        />
        <CardContent>
          Please provide extra information about the rejection of the current step. More detailed information can help with resolving the issue.

          <Divider sx={{ mt: 1, mb: 1 }} />
          {files.length !== 0 && <Box sx={{ mb: 1 }}>
            <CardHeader
              sx={{ ml: 0, pl: 0 }}
              title="Images"
            />

            <ImageList sx={{
              maxHeight: 200,
              overflow: "scroll",
            }}>
              {files.map((f) => {
                return (
                  <Box sx={{
                    position: "relative"
                  }}>
                    <Tooltip title="Remove Image">
                      <IconButton sx={{
                        position: "absolute",
                        right: 4,
                        top: 4,
                        zIndex: 999999,
                        color: "#83D100",
                        a: "rgba(82, 82, 82, 0.5)"
                      }}
                        onClick={() => handleDeleteImage(f)}
                      >
                        <CloseIcon sx={{
                          filter: "drop-shadow(1px 2px 3px #525252)"
                        }}
                          color='inherit' fontSize='large' />
                      </IconButton>
                    </Tooltip>

                    <ImageListItem onClick={() => handleImageClick(f)}>
                      <img src={URL.createObjectURL(f)} alt={f.title} />
                    </ImageListItem>
                  </Box>
                )
              })}
            </ImageList>
          </Box>}
          <FormControl sx={{ width: '100%' }} variant="outlined">
            <TextField
              value={text}
              label="Provide a description of the issue"
              minRows={5}
              onChange={(e) => setText(e.target.value)}
              multiline
              width="100%"
            />
          </FormControl>

        </CardContent>
        <CardActions>
          <AddFileButton />
          <Button
            disabled={text.length === 0}
            onClick={handleSubmit}
            sx={{
              ml: "auto",
              mr: 0
            }}
          >
            Submit
          </Button>
        </CardActions>
      </DialogContent >

    </Dialog >
  )
}

export default StepRejectionPanel;