import { Checkbox } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setError } from "../../../actions/notificationActions";
import { updateChecklistStepInputValue } from '../../../util/api/checklist';

const ChecklistCheckboxInput = ({ input, checklistID, stepID, onUpdate, disabled }) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(input?.value === "true")
  return (
    <Checkbox
      classes={{ root: 'custom-checkbox-root' }}
      checked={checked}
      disabled={disabled}
      onChange={(e) => {
        setChecked(!checked);
        const payload = { "value": e.target.checked }
        updateChecklistStepInputValue(checklistID, stepID, input?.id, payload)
          .then(() => {
            onUpdate();
          })
          .catch(() => {
            dispatch(setError("Could not update value"))
          })
      }}
    />
  )
}

export default ChecklistCheckboxInput;