import { Box, Card, CardActionArea, CardContent, CardHeader, Chip, Grid, Tooltip, Typography, TableContainer, Table, TableHead, TableCell, TableRow, TableBody, Paper, Accordion, AccordionDetails, AccordionSummary, Checkbox, IconButton, Dialog, Avatar, Select, Menu, MenuItem } from "@mui/material"
import { styled } from '@mui/material/styles';

import CheckIcon from '@mui/icons-material/Check';
import ExpandMore from "@mui/icons-material/ExpandMore";
import GestureIcon from '@mui/icons-material/Gesture';
import DrawIcon from '@mui/icons-material/Draw';
import ChecklistStep from "../../ChecklistStep/ChecklistStep";
import { useEffect, useState } from "react";
import { getChecklist } from "../../../util/api/checklist";
import HealthCheckChecklistStep from "./HealthCheckChecklistStep";
// import ClientSignDialog from "./ClientSignDialog";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ColorLookup = {
  "H": "#ff4842",
  "W": "#ffc107",
  "R": "#83D100"
}

const HealthCheckChecklistTile = ({ checklistID, onClick, defaultClosed }) => {

  const [selectedStep, setSelectedStep] = useState(undefined)
  const [checklistComplete, setChecklistComplete] = useState(false);
  const [checklist, setChecklist] = useState(undefined);
  const [signStep, setSignStep] = useState(undefined);

  const completedSteps = checklist?.steps?.filter((step) => {
    return step.completed
  }).length

  const approvedSteps = checklist?.steps?.filter((step) => {
    return step.approved
  }).length

  const progressColor = checklist?.completed ? "success" : "warning"
  const approvedProgressColor = checklist?.approved ? "success" : "warning"

  const openStep = (step) => {
    setSelectedStep(step)
  }

  const onCloseDialog = () => {
    setSelectedStep(undefined);
    fetchChecklist();
  }

  const fetchChecklist = () => {
    getChecklist(checklistID).then((res) => {
      setChecklist(res.data)
      setChecklistComplete(res.data.completed)
    })
  }

  const checkChecklistComplete = () => {

  }

  useEffect(() => {
    fetchChecklist()
  }, [checklistID])

  return (

    <Card sx={{
      mt: 0,
      width: "100%",
      boxShadow: "none",
      display: "flex",
      flexDirection: "column",
      borderBottom: "1px solid",
      borderColor: "#212529",
      borderRadius: 1,
      p: 0,
      mb: 0,
      mt: 1,
      pt: 0,
      // border: "1px solid white"
    }}>
      <Dialog fullWidth maxWidth="md" open={selectedStep !== undefined} onClose={onCloseDialog}>
        <HealthCheckChecklistStep onSignLocally={() => { setSignStep(selectedStep); setSelectedStep(undefined) }} disabled={false} onUpdate={checkChecklistComplete} stepID={selectedStep?.id} checklistID={checklist?.id} />
      </Dialog>

      <Accordion defaultExpanded={!defaultClosed}>
        <AccordionSummary expandIcon={<ExpandMore sx={{ color: "white" }} />}>

          <CardHeader
            sx={{ pl: 2, pt: 2, }}
            title={checklist?.title}
            action={
              <Box sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1
              }}>
                <Tooltip title="Checklist Status">
                  <Chip
                    color={progressColor}
                    sx={{ ml: 4 }}
                    label={
                      <Box sx={{
                        padding: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex"
                      }}>
                        {`${completedSteps} of ${checklist?.steps?.length} steps completed`}
                        {checklist?.completed && <CheckIcon sx={{ ml: 1 }} />}
                      </Box>
                    }
                  />
                </Tooltip>

                {/* <Tooltip title="Checklist Status">
                  <Chip
                    color={approvedProgressColor}
                    sx={{ ml: 4 }}
                    label={
                      <Box sx={{
                        padding: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex"
                      }}>
                        {`${approvedSteps} of ${checklist?.steps?.length} steps approved`}
                        {checklist?.approved && <CheckIcon sx={{ ml: 1 }} />}
                      </Box>
                    }
                  />
                </Tooltip> */}
              </Box>
            }
          />
        </AccordionSummary>
        <CardContent sx={{ p: 0, p: "0 !important" }}>

          <AccordionDetails>
            <Grid container sx={{
              width: "100%",
              // background: "#83D100"
            }}>
              {/* <Grid sx={{ pl: 1, pt: 1, pb: 1 }} item xs={10}>
            {checklist?.description}
          </Grid> */}
              <TableContainer component={Paper} sx={{ borderRadius: 0, p: 0, border: " 1px solid white", borderRadius: 1 }}>
                <Table sx={{ p: 0, minWidth: 650 }} aria-label="simple table">
                  <TableHead sx={{ p: 0, pt: "0 !imporant", mt: 1, borderRadius: 0 }}>
                    <TableRow>
                      <TableCell sx={{ width: "75%" }}>Inspecties</TableCell>
                      <TableCell align="center">Check</TableCell>
                      <TableCell align="center">Status</TableCell>
                    </TableRow>

                  </TableHead>
                  <TableBody sx={{ pb: 0 }}>
                    {checklist?.steps?.map((step) => (

                      <StyledTableRow
                        key={step.title}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, p: 0 }}
                      >
                        <TableCell component="th" scope="row">
                          {step.title}
                        </TableCell>
                        <TableCell sx={{ p: 0 }} align="center">
                          {step?.completed ||
                            <Tooltip title="Complete Step">
                              <div>
                                <IconButton onClick={() => { openStep(step) }}><DrawIcon /></IconButton>
                              </div>
                            </Tooltip>
                          }
                          {step?.completed && <Tooltip title="Completed">
                            <div>
                              <IconButton onClick={() => { openStep(step) }}><CheckIcon sx={{ color: "#83D100" }} /></IconButton>
                            </div>
                          </Tooltip>}
                        </TableCell>
                        {/* <TableCell align="right">{step.fat}</TableCell> */}
                        <TableCell align="center" >
                          <Box
                            sx={{
                              display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 1
                            }}
                          >
                            {(0 === step?.health_status || null === step?.health_status) && <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                              <Box sx={{ borderRadius: "50%", width: "20px", height: "20px", background: "#000" }}></Box>None</Box>}

                            {1 === step?.health_status && <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                              <Box sx={{ borderRadius: "50%", width: "20px", height: "20px", background: "#DD1D21" }}></Box>Safety Critical</Box>}

                            {2 === step?.health_status && <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                              <Box sx={{ borderRadius: "50%", width: "20px", height: "20px", background: "#FFC000" }}></Box>Advisory</Box>}

                            {3 === step?.health_status && <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                              <Box sx={{ borderRadius: "50%", width: "20px", height: "20px", background: "#94D500" }}></Box>Satisfactory</Box>}

                          </Box>
                        </TableCell>
                      </StyledTableRow>


                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </AccordionDetails>

        </CardContent>
      </Accordion>

      {/* </CardActionArea> */}
    </Card >
  )
}

export default HealthCheckChecklistTile;