import { Grid, Card, Button, CardContent, Box, Typography, CircularProgress } from "@mui/material";

import { useState } from "react";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch } from "react-redux";
import { setError, setSuccess } from "../../actions/notificationActions";
import { requestPasswordReset } from "../../util/api/userApi";
import { StyledTextfield } from "../../components/StyledTextField";
import { Helmet } from "react-helmet";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [emailValue, setEmailValue] = useState("");

  const dispatch = useDispatch()

  const handleSubmit = () => {
    setLoading(true)
    requestPasswordReset(emailValue).then(() => {
      setEmailValue("")
      setLoading(false)
      dispatch(setSuccess("Request received, please check your inbox."))
    }).catch((e) => {
      setLoading(false)
      dispatch(setError(e.response?.data?.detail))
    })
  }

  return <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
    <Helmet>
      <title>VIP | Reset Password</title>
    </Helmet>
    <Grid container justifyContent={"center"} sx={{ padding: { md: 10, xs: 0 }, flex: 500, }}>
      <Grid item xs={12} md={6} lg={4} sx={{ mb: 4 }}>

        <Card sx={{
          borderRadius: { xs: 0, md: 4 },
          padding: { xs: 1, md: 4 },
          pt: { xs: 0, md: 4, },
          pb: { xs: 0, md: 4, },
          background: "#1a1a1a",
          justifyContent: "center",
          alignItems: 'center',
          display: "flex"
        }}>
          <CardContent sx={{ width: { xs: "100%", md: "100%", lg: "100%" } }}>
            <Box sx={{ width: "100%" }} display="flex" alignItems="center" justifyContent={"center"} flexDirection={"column"}>
              <Typography sx={{ mb: 3 }} variant="h6"><b>Forgot your password?</b></Typography>
              <Typography>No worries, we'll send you reset instructions.</Typography>
              <StyledTextfield
                id="field1"
                label="Email"
                variant="outlined"
                required
                size="small"
                autoComplete="off"
                placeholder="Please enter your email"
                value={emailValue}
                onChange={(e) => setEmailValue(e.target.value)}
                sx={{
                  mt: 3
                }}
              />

              <Button
                sx={{ mt: 3, width: "100%", alignItems: "center", justifyContent: "center" }}
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                disabled={emailValue === ""}
              >
                {loading ? <CircularProgress size="23px" sx={{ color: "#000" }} /> : "Reset Password"}
              </Button>

              <Button
                sx={{ mt: 3, width: "100%", alignItems: "center", justifyContent: "center" }}
                color="primary"
                href="/login"
                startIcon={<ArrowBackIcon />}
              >
                Back to Login
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid >
  </div>
}

export default ForgotPassword;