import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import SignatureCanvas from 'react-signature-canvas'

import './signature.scss'
import { approveFileSession } from "../../../util/api/checklistApproval";
import { setError } from "../../../actions/notificationActions";
import { useDispatch } from "react-redux";
// import { signChecklistStep } from "../../util/api/checklist";

const ACTION_TABLE = {
  "A": "Approve",
  "M": "Monitor",
  "R": "Review",
  "H": "Hold",
  "W": "Witness",
  None: ""
}

const FileSignDialog = ({ open, sessionID, fileID, onClose, onSignOff, title, signKey }) => {
  const [clientName, setClientName] = useState("")

  const sigCanvas = useRef({});

  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
  }

  const handleSign = () => {
    const trimmedSig = trimmed()

    trimmedSig.toBlob((blob) => {
      const temp = new File([blob], "signature.png")
      // signChecklistStep(selectedStep?.parent_id, selectedStep?.id, clientName, temp).then((e) => {
      //   onSignoff();
      // })
      approveFileSession(sessionID, fileID, signKey, clientName, temp).then(() => {

        onSignOff();
      }).catch((e) => {
        console.log(e)
        dispatch(setError(e.response?.data?.detail))
      })
    }, "image/png");
  }

  const clear = () => sigCanvas.current.clear();

  const trimmed = () => sigCanvas.current.getTrimmedCanvas()

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogContent>
        <DialogTitle sx={{ p: 0 }}>{title} -  Sign Off</DialogTitle>
        <Box display={"flex"} sx={{ color: "rgba(255, 255, 255, 0.6)" }}>

        </Box>
        <Box sx={{ background: "white", borderRadius: "10px", p: 0, border: "2px solid black", mt: 2 }}>
          <SignatureCanvas ref={sigCanvas}
            penColor="#000"
            // backgroundColor="#fff"
            canvasProps={{
              className: "signatureCanvas"
            }} />
        </Box>
      </DialogContent>
      <DialogActions sx={{ alignItems: "center", display: "flex" }}>
        <TextField
          id="standard-basic"
          label="Name"
          variant="standard"
          value={clientName}
          onChange={(e) => { setClientName(e.target.value) }}

          sx={{ float: "right", ml: 2, mr: "auto" }}
        />
        <Button onClick={clear}>Clear</Button>
        <Button onClick={handleClose}>Close</Button>
        <Button disabled={clientName === ""} onClick={handleSign}>Sign</Button>
      </DialogActions>
    </Dialog>
  )
}

export default FileSignDialog;