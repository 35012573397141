import { useEffect, useState } from 'react';

import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';
import PrintDisabledIcon from '@mui/icons-material/PrintDisabled';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import HttpsIcon from '@mui/icons-material/Https';

import {
  Grid,
  IconButton,
  CircularProgress,
  Box,
  DialogContent,
  Dialog,
  DialogTitle,
  Typography,
  TextField,
  FormControl,
  Button,
  Link,
  Checkbox
} from '@mui/material';

import { getSessionFiles } from '../../util/api';
import { createFileApprovalSession } from '../../util/api/fileApproval';

const FileApprovalPopup = ({ onClose, open, projectID }) => {

  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("")
  const [activeStep, setActiveStep] = useState(0)
  const [files, setFiles] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])

  useEffect(() => {
    if (activeStep === 1) {
      getSessionFiles(projectID).then((res) => {
        const filtered = res.data.filter((a) => {
          return a.approval_session_id === null && a.approved_at === null
        })
        setFiles(filtered)
        setLoading(false)
      })
    } else {
      setLoading(true)
    }
  }, [activeStep])

  const handleClose = () => {
    onClose(undefined);
    setActiveStep(0)
    setSelectedFiles([])
    setFiles([])
    setEmail("")
    setLoading(true);
  };

  const handleNext = () => {
    if (activeStep + 1 <= 1) {
      setActiveStep(activeStep + 1)
    }

    if (activeStep === 1 && completed()) {
      createFileApprovalSession(projectID, email, selectedFiles).then(() => {
        handleClose()
      })
    }
  }

  const handleBack = () => {
    if (activeStep - 1 >= 0) {
      setActiveStep(activeStep - 1)
    }
  }

  const handleToggle = (value) => {
    const currentIndex = selectedFiles.indexOf(value);
    const newChecked = [...selectedFiles];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedFiles(newChecked);
  };

  const completed = () => {
    if (activeStep === 0) {
      const emailRegex = /^[a-z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-z0-9]@[a-z0-9][-\.]{0,1}([a-z][-\.]{0,1})*[a-z0-9]\.[a-z0-9]{1,}([\.\-]{0,1}[a-z]){0,}[a-z0-9]{0,}$/
      return email.toLowerCase().match(emailRegex) !== null
    }

    if (activeStep === 1) {
      return selectedFiles.length !== 0
    }
  }

  const LoadingIcon = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  const CloseButton = () => {
    return (
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8
        }}
      >
        <CloseIcon />
      </IconButton>)
  }

  const EmailEntry = () => {
    return (
      <Box>
        <DialogTitle textAlign="center">Who would you like to notify? </DialogTitle>
        <FormControl sx={{ m: 1, minWidth: 400 }}>
          <TextField autoFocus label="Email Address" variant="outlined" size="small" value={email} onChange={(e) => setEmail(e.target.value)} />
        </FormControl>
      </Box>
    )
  }

  const FileEntry = () => {
    return (
      <Box>
        <DialogTitle textAlign="center">Please select the files for approval </DialogTitle>
        <List>
          {loading && <LoadingIcon />}
          {!loading && files.map((file) => {
            return (
              <ListItem key={file.id}>
                <Checkbox
                  checked={selectedFiles.indexOf(file.id) !== -1}
                  onClick={() => {
                    handleToggle(file.id)
                  }} />
                <Link
                  href={"/data/" + file.file_location}
                  underline="hover"
                  target="_blank"
                >
                  <ListItemText
                    primary={file.title}
                    secondary={file.description}
                  />
                </Link >
                <ListItemText
                  primary={""}
                />
                {file.approved ? "Approved" : ""}
              </ListItem>
            )
          })}
        </List>
      </Box>
    )
  }

  const steps = [
    <EmailEntry />,
    <FileEntry />
  ]

  const InfoContainer = ({ icon, text }) => {
    return (
      <Grid container
        sx={{
          mt: "30px"
        }}>
        <Grid item xs={3} sx={{ display: "flex", alignitems: "center", justifyContent: "center" }}>
          <Avatar sx={{ background: "none", padding: "30px" }} >
            {icon}
          </Avatar>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1">
            {text}
          </Typography>
        </Grid>
      </Grid >
    )
  }

  return (
    <Dialog
      onClose={handleClose}
      sx={{ zIndex: 199999999 }}
      fullWidth
      maxWidth={"md"}
      open={open}
      PaperProps={{
        elevation: 0,
        variant: "outlined",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
      }}>
      <CloseButton />

      <DialogContent>
        <Grid container spacing={5}>
          <Grid item xs={6} sx={{ mt: "50px", pb: "50px" }}>
            <InfoContainer
              icon={<PrintDisabledIcon fontSize="large" />}
              text={"Approvers can approve a document in minutes, without needing to print or scan documents."}
            />
            <InfoContainer
              icon={<AttachEmailIcon fontSize="large" />}
              text={"An email will be sent containing a link that directly leads to the files to approve, without the need to log in or create an account."}
            />
            <InfoContainer
              icon={<HttpsIcon fontSize="large" />}
              text={"Documents receive a digital signature once approved, ensuring they cannot be tampered with."}
            />
          </Grid>

          <Grid item xs={6} sx={{ mt: "50px", pb: "50px", minHeight: "400px", display: "flex", flexDirection: "column" }}>
            {steps[activeStep]}
            <Box sx={{ flexGrow: 1 }}></Box>
            <Box sx={{}}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Button onClick={handleNext} sx={{ float: "right" }} disabled={!completed()}>
                {activeStep === 1 ? 'Finish' : 'Next'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default FileApprovalPopup;