import { useState, useEffect } from 'react'
import { useSearchParams, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';

import PDFViewer from "../../../components/Approval/PDFViewer";
import RejectionPopup from '../../../components/Approval/RejectionPopup';

import { getFileApprovalSession, approveFile } from '../../../util/api/fileApproval';
import { createIssueForFile } from '../../../util/api/issue';

import { setError } from '../../../actions/notificationActions';
import {
  Button,
  Paper,
  Typography,
  Grid, Box,
} from '@mui/material';

import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import VAnimation from '../../../components/Approval/VAnimation';

const FileApproval = () => {
  const dispatch = useDispatch()
  const { sessionID } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [session, setSession] = useState(null)
  const [fileIndex, setFileIndex] = useState(0)
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);

  useEffect(() => {
    if (sessionID !== null) {
      getFileApprovalSession(sessionID, searchParams.get("key")).then((res) => {
        setSession(res.data)
      }).catch((e) => {
        dispatch(setError(e.response?.data?.detail))
      })
    }
  }, [sessionID])

  const handleApprove = () => {
    approveFile(sessionID, searchParams.get("key"), session?.files[fileIndex]?.id).then(() => {
      setFileIndex(fileIndex + 1)
    })
  }

  const handleReject = () => {
    setRejectDialogOpen(true)
  }

  const onRejectSubmit = (value) => {
    createIssueForFile(session.session_id, session?.files[fileIndex].id, value).then((_res) => {
      setFileIndex(fileIndex + 1)
      handleRejectDialogClose();
    })

  }

  const handleRejectDialogClose = () => {
    setRejectDialogOpen(false);
  }

  const ActionButtons = () => {
    return (
      <Box sx={{
        position: "absolute",
        bottom: 50,
        zIndex: 999999999,
        width: "100vw"
      }}>
        <Grid container>
          <Grid item xs={1} md={4} lg={5}>

          </Grid>
          <Grid item xs={10} md={4} lg={2}>
            <Paper sx={{ padding: "10px", display: "flex", justifyContent: "center", }}>
              <Button
                onClick={handleReject}
                startIcon={<ThumbDownIcon />}
                variant="outlined"
                sx={{ width: "120px" }}
              >
                Reject
              </Button>

              <Typography variant="h6" sx={{ width: "100px" }} textAlign="center" alignItems="center">
                {fileIndex + 1} / {session.files.length}
              </Typography>


              <Button
                sx={{
                  width: "120px"
                }}
                onClick={handleApprove}
                endIcon={<ThumbUpIcon />}
                variant="outlined"
              >
                Approve
              </Button>
            </Paper>
          </Grid>

          <Grid item xs={5}>

          </Grid>
        </Grid>

      </Box>
    )

  }

  const VmatchAnimation = () => {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={10} md={6} lg={4} >

          <VAnimation />
          <Typography variant="h4" textAlign="center">
            All done, you can now close this webpage!
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <div>

      <RejectionPopup
        open={rejectDialogOpen}
        onClose={handleRejectDialogClose}
        onSubmit={onRejectSubmit}
      />
      {fileIndex < session?.files.length && <ActionButtons />}

      <Grid>
        {fileIndex >= session?.files.length && <VmatchAnimation />}
        {fileIndex < session?.files.length && <PDFViewer
          filename={session?.files[fileIndex] ? `/data/${session?.files[fileIndex]?.file_location}` : ""}
          title={session?.files[fileIndex]?.title}
        />}

      </Grid>
    </div>
  )
}

export default FileApproval;